// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* .calendar {\n    table-layout:fixed;\n    width: 200px;\n} */\n\n.PreviewCalendar_shading__-h4GD {\n  text-align: center;\n  width: 26px;\n  color: white;\n  background-color: #173e6c;\n}\n\n.PreviewCalendar_day__1Va9s {\n  text-align: center;\n  width: 26px;\n}\n\n.PreviewCalendar_bookShading__29H28 {\n  text-align: center;\n  width: 26px;\n  color: white;\n  background-color: #285078bf;\n}\n\n.PreviewCalendar_weekday__2PgEH {\n  text-align: center;\n  width: 26px;\n}\n\n.PreviewCalendar_calendarContainer__15ulN {\n  width: 200px;\n  position: relative;\n  margin: 10px;\n}\n\n.PreviewCalendar_card__yT0jP {\n  position: absolute;\n  width: 180px;\n  height: 100px;\n  left: 50%;\n  margin-left: -85px;\n  margin-top: 60px;\n}\n\n.PreviewCalendar_month__2Zx4o {\n  margin: auto;\n  text-align: center;\n}\n\ntable {\n  table-layout: fixed;\n  width: 200px;\n}\n\nth,\ntd {\n  width: 26px;\n  text-align: center;\n  overflow: hidden;\n}\n", ""]);
// Exports
exports.locals = {
	"shading": "PreviewCalendar_shading__-h4GD",
	"day": "PreviewCalendar_day__1Va9s",
	"bookShading": "PreviewCalendar_bookShading__29H28",
	"weekday": "PreviewCalendar_weekday__2PgEH",
	"calendarContainer": "PreviewCalendar_calendarContainer__15ulN",
	"card": "PreviewCalendar_card__yT0jP",
	"month": "PreviewCalendar_month__2Zx4o"
};
module.exports = exports;
