import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  secondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  categoryIcon: {},
  AccordionSummary: {
    marginBottom: 0,
  },
}));
export default function BudgetCategorySection(props) {
  const classes = useStyles();
  const { sectionName } = props;
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        classes={{ expanded: classes.AccordionSummary }}
        expandIcon={<ExpandMoreIcon color="secondary" />}
        aria-controls={sectionName + "content"}
        id={sectionName + "-header"}
      >
        <Typography variant="h5">{sectionName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          <Grid item container spacing={2} justify="space-evenly">
            {props.children}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
