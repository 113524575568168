import React, { useState, useEffect, useRef } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { addYears } from "date-fns";
import Grid from "@material-ui/core/Grid";

export default function PlanDates(props) {
  const { planStartDate, planEndDate, changePlanDates } = props;

  const [planDates, setPlanDates] = useState({
    start: planStartDate,
    end: planEndDate,
  });

  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    changePlanDates(planDates);
    //eslint-disable-next-line
  }, [planDates]);

  const handleStartDateChange = (e) => {
    let start = new Date(e);
    let end = addYears(start, 1);
    setPlanDates({ start, end });
  };
  const handleEndDateChange = (e) => {
    let { start } = planDates;
    let newEndDate = new Date(e);
    if (newEndDate > start) setPlanDates({ ...planDates, end: newEndDate });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid item xs={4}>
        <DatePicker
          label="Start Date"
          value={planDates.start}
          onChange={handleStartDateChange}
          leftArrowIcon={<ChevronLeft />}
          rightArrowIcon={<ChevronRight />}
          id="startDate"
          name="startDate"
          format="DD/MM/YYYY"
        />
      </Grid>
      <Grid item xs={4}>
        <DatePicker
          label="End Date"
          value={planDates.end}
          onChange={handleEndDateChange}
          leftArrowIcon={<ChevronLeft />}
          rightArrowIcon={<ChevronRight />}
          id="endDate"
          name="endDate"
          format="DD/MM/YYYY"
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
