import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  Fab,
  Box,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { differenceInMinutes, setMonth } from "date-fns";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import api from "../../api";
import { LocalStorageKeys } from "../../common/constants";
import { DARK_BLUE, LIGHT_BLUE } from "../../common/theme";
import HcpBudgetCategoryCard from "../../DoughnutChart/HcpBudgetCategoryCard";
import DoughnutBody from "../../DoughnutChart/hcp_DoughnutBody";
import withStyles from "@material-ui/core/styles/withStyles";
import SupportItemDialog, {
  SUPPORT_ITEMS_VIEW,
  PLAN_ITEM_GROUPS_VIEW,
  MANAGEMENT_EDIT,
} from "./hcp_SupportItemDialog";

import "react-calendar/dist/Calendar.css";
import TwelveMonthCalendar from "./hcp_TwelveMonthCalendar";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import PlanItemCalendarDialog from "./hcp_PlanItemCalendarDialog";
import PlanItemDeleteDialog from "./PlanItemDeleteDialog";
import PlanItemEditView from "./hcp_PlanItemEditView";
import styles from "./BudgetDashboard.module.css";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import HcpPlanDetails from "./hcp_PlanDetails";
import DetailSummaryTbl from "./hcp_DetailSummaryTbl";
import EditIcon from "@material-ui/icons/Edit";
import PrintDetailedSummary from "./PrintDetailedSummary";
import NavigationIcon from "@material-ui/icons/NavigationRounded";

const PLAN_CATEGORIES = "hcpPlanCategories";
export const CATEGORY_Care_Manage_ID = 1;
export const CATEGORY_Package_Manage_ID = 2;
export const CATEGORY_LEVEL_ID = 11;
export const Group_LEVEL_ID = 43;
export const Other_CATEGORY_ID = 7;
export const EQUIPMENT_ITEM_CATEGORY_ID = 12;
export const Other_Group_ID = 48;

const paperStyles = (theme) => ({
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: 8,
  },
  buttonMargin: {
    marginTop: 10,
  },
  mainAccordianStyles: {
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
    },
  },
});

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
  };
}
export const formatMoney = (money) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(money);
};
export function calculatePlanItemCost(hcpPlanItem) {
  const amount = hcpPlanItem.allDay
    ? Math.round(hcpPlanItem.priceActual * 100) / 100
    : Math.round(
        ((hcpPlanItem.priceActual *
          differenceInMinutes(
            new Date(hcpPlanItem.endDate),
            new Date(hcpPlanItem.startDate)
          )) /
          60) *
          100
      ) / 100;
  return amount;
}

export function calculateTotalCost(hcpPlanItemGroup) {
  let allocated = 0;
  _.forEach(hcpPlanItemGroup.hcpPlanItems, (hcpPlanItem) => {
    const amount = calculatePlanItemCost(hcpPlanItem);

    allocated += amount;
  });
  return Math.round(allocated * 100) / 100;
}

export function calculateAllocated(hcpPlanItemGroups) {
  let allocated = 0;
  _.forEach(hcpPlanItemGroups, (hcpPlanItemGroup) => {
    allocated += calculateTotalCost(hcpPlanItemGroup);
  });

  return Math.round(allocated * 100) / 100;
}

export function planItemGroupToEvents(hcpPlanItemGroup) {
  const events =
    hcpPlanItemGroup.hcpPlanItems &&
    hcpPlanItemGroup.hcpPlanItems.map((hcpPlanItem) => {
      const { allDay, endDate, name, startDate } = hcpPlanItem;
      return {
        allDay,
        start: new Date(startDate),
        end: new Date(endDate),
        title: name,
        hcpPlanItem,
      };
    });
  return events;
}

const PLAN_ITEM_DIALOG_PAGES = {
  preview: 0,
  delete: 1,
  edit: 2,
};

function dateToUTCString(date) {
  return moment(date).format();
}
function dateToString(date) {
  return moment(date).format("YYYY-MM-DD");
}

class hcp_BudgetDashboard extends React.Component {
  updateStateInterval;
  state = {
    hcpSupportGroups: [],
    hcpSupportItems: [],
    activeItems: [],
    planDates: {},
    planName: null,
    planNumber: null,
    hcpPlanCategories: {},
    oldPlanCategories: {},
    hcpPlanItemGroups: {},
    hcpPlanItems: {},
    levelItemGroup: {},
    openSupports: false,
    // Cname:Calendar.name,
    activeCategory: null,
    birthYear: 1990,
    postcode: 3000,
    planId: null,
    level: 1,
    isEditable: false,
    PlanlevelItemGroup: {},
    activeItemName: null,
    // testlevel: [24.46,43.03,93.63,141.94],
    testlevel: {},
    hcpRegistrationGroupId: null,
    hcpRegistrationGroups: [],
    dialogPage: PLAN_ITEM_GROUPS_VIEW,
    showMonthView: false,
    monthViewDate: setMonth(new Date(), 1),
    planItemDialogPage: -1,
    selectedPlanItem: null,
    flag: false,
    total: 0,
    showDetailedSummary: false,
    dailIncome: 0,
    candrag: true,
    categoryExpanded: false,
    groupExpanded: false,
    currentPlanLevel: 0,
    allocated: 0,
    tablenav: null,
  };

  //load support data
  async componentDidMount() {
    // call backend to load all plan groups and corresponding categories

    console.log("Dashboard147 尝试get supportGroup");
    // load SupportGroups
    api.Hcp_SupportGroups.all()
      .then((response) => {
        this.setState({ hcpSupportGroups: [...response.data] }, this.loadState);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // render if local plan changes
  componentDidUpdate(prevProps, prevState, snapShot) {
    if (
      this.props.currentUser === null &&
      this.state.hcpPlanCategories !== prevState.hcpPlanCategories
    ) {
      localStorage.setItem(
        PLAN_CATEGORIES,
        JSON.stringify(this.state.hcpPlanCategories)
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.updateStateInterval);
  }

  // get daily income according to itemGroup level
  calculateDailyIncome() {
    let alldaily = 0;
    _.map(this.state.levelItemGroup, (price, index) => {
      alldaily = alldaily + parseFloat(price);
    });
    return alldaily.toFixed(2);
  }

  // load plan categories, birthYear and postcode either from backend if logged in else from local storage
  loadState = async () => {
    let hcpPlanCategories = {};
    let planDates = {};
    let planName = null;
    let planNumber = null;
    let hcpSupportItems = {};
    let flag = false;
    let total = 0;
    let level = 1;
    let levelItemGroup = {};
    let testlevel = {};
    let onceOffItems = [];

    // TODO: update for hcpPlanItemGroups
    console.log("loading data");
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      console.log("Dashboard191 尝试load HcpPlans");
      await api.Hcp_Plans.list().then(async (response) => {
        console.log(response.data);
        if (response.data.length === 0) {
          window.location.href = "/hcp/budget/edit";
        } else {
          const plans = response.data;
          console.log("plans", plans);
          if (this.props.location.state.planId === undefined) {
            var i = 0;
            this.state.planId = plans[i].id;
            for (var ind = 0; ind < plans.length; ind++) {
              planDates[plans[ind].id] = {
                startDate: plans[ind].startDate,
                endDate: plans[ind].endDate,
              };
            }
            planName = plans[i].name;
            localStorage.setItem("startDate", plans[i].startDate);
            localStorage.setItem("endDate", plans[i].endDate);

            console.log("Dashboard211 尝试load Hcp_PlanItemGroups");
            _.map(plans[i].hcpPlanCategories, async (hcpPlanCategory) => {
              api.Hcp_PlanItemGroups.list(
                hcpPlanCategory.plan,
                hcpPlanCategory.id
              ).then((responsePlanItemGroup) => {
                let hcpPlanItemGroups = [];
                if (responsePlanItemGroup.data.length !== 0) {
                  hcpPlanItemGroups = responsePlanItemGroup.data.map(
                    (hcpPlanItemGroup, index) => {
                      if (
                        hcpPlanCategory.id === hcpPlanItemGroup.hcpPlanCategory
                      ) {
                        console.log(
                          "Dashboard221 尝试load Hcp_PlanItems PlanCateid:" +
                            hcpPlanCategory.id
                        );
                        api.Hcp_PlanItems.list(
                          hcpPlanCategory.plan,
                          hcpPlanCategory.id,
                          hcpPlanItemGroup.id
                        ).then((responsePlanItem) => {
                          let hcpPlanItems = [];
                          if (responsePlanItem.data.length !== 0) {
                            hcpPlanItems = responsePlanItem.data.map(
                              (hcpPlanItem, index) => {
                                if (
                                  hcpPlanItemGroup.id ===
                                  hcpPlanItem.planItemGroup
                                ) {
                                  hcpPlanItems[index] = {
                                    ...hcpPlanItem,
                                  };
                                  return {
                                    ...hcpPlanItem,
                                  };
                                }
                                return {};
                              }
                            );
                            if (
                              hcpPlanCategory.hcpSupportCategory ===
                              CATEGORY_LEVEL_ID
                            ) {
                              let nlevel = 1;
                              console.log(hcpPlanItems);
                              let levelItemGroup = this.state.levelItemGroup;
                              console.log(levelItemGroup);
                              _.map(hcpPlanItems, (PI, index) => {
                                if (PI.name === "level") {
                                  nlevel = parseInt(PI.priceActual);
                                  level = nlevel;
                                  levelItemGroup["level"] = testlevel[nlevel];
                                } else {
                                  levelItemGroup[PI.name] = PI.priceActual;
                                }
                              });
                              this.setState({
                                levelItemGroup: levelItemGroup,
                                level: nlevel,
                              });
                            }
                            hcpPlanItemGroups[index] = {
                              ...hcpPlanItemGroup,
                              hcpPlanItems: hcpPlanItems || [],
                            };
                          }
                        });
                        return {
                          ...hcpPlanItemGroup,
                        };
                      }
                      return {};
                    }
                  );
                  hcpPlanCategories[hcpPlanCategory.id] = {
                    ...hcpPlanCategory,
                    hcpPlanItemGroups: hcpPlanItemGroups || [],
                  };
                }
              });
              hcpPlanCategories[hcpPlanCategory.id] = {
                ...hcpPlanCategory,
                hcpPlanItemGroups: [],
              };
            });
          } else {
            await Promise.all(
              _.map(plans, async (plan) => {
                planDates[plan.id] = {
                  startDate: plan.startDate,
                  endDate: plan.endDate,
                };
                // find corresponding plan
                if (this.props.location.state.planId === plan.id) {
                  this.state.planId = plan.id;
                  planName = plan.name;
                  planNumber = plan.hcpNumber;
                  // for each planCategory
                  _.map(plan.hcpPlanCategories, async (hcpPlanCategory) => {
                    console.log(
                      "Dashboard287 尝试load PlanItemGroups else PlanCategoryid：" +
                        hcpPlanCategory.id
                    );
                    api.Hcp_PlanItemGroups.list(
                      hcpPlanCategory.plan,
                      hcpPlanCategory.id
                    ).then((responsePlanItemGroup) => {
                      let hcpPlanItemGroups = [];
                      if (responsePlanItemGroup.data.length !== 0) {
                        hcpPlanItemGroups = responsePlanItemGroup.data.map(
                          (hcpPlanItemGroup, index) => {
                            if (
                              hcpPlanCategory.id ===
                              hcpPlanItemGroup.hcpPlanCategory
                            ) {
                              console.log(
                                "Dashboard302 尝试load PlanItems else PC_id:" +
                                  hcpPlanCategory.id
                              );
                              api.Hcp_PlanItems.list(
                                hcpPlanCategory.plan,
                                hcpPlanCategory.id,
                                hcpPlanItemGroup.id
                              ).then((responsePlanItem) => {
                                let hcpPlanItems = [];
                                if (responsePlanItem.data.length !== 0) {
                                  hcpPlanItems = responsePlanItem.data.map(
                                    (hcpPlanItem, index) => {
                                      if (
                                        hcpPlanItemGroup.id ===
                                        hcpPlanItem.planItemGroup
                                      ) {
                                        hcpPlanItems[index] = {
                                          ...hcpPlanItem,
                                        };
                                        return {
                                          ...hcpPlanItem,
                                        };
                                      }
                                      return {};
                                    }
                                  );
                                  if (
                                    hcpPlanCategory.hcpSupportCategory ===
                                    CATEGORY_LEVEL_ID
                                  ) {
                                    console.log(hcpPlanItems);
                                    let nlevel = 1;
                                    let levelItemGroup = this.state
                                      .levelItemGroup;
                                    console.log(levelItemGroup);

                                    _.map(hcpPlanItems, (PI, index) => {
                                      if (PI.name === "level") {
                                        nlevel = parseInt(PI.priceActual);
                                        level = nlevel;
                                        levelItemGroup["level"] =
                                          testlevel[nlevel];
                                      } else {
                                        levelItemGroup[PI.name] =
                                          PI.priceActual;
                                      }
                                    });

                                    if (
                                      this.props.location.state
                                        .levelItemGroup !== undefined
                                    ) {
                                      levelItemGroup = this.props.location.state
                                        .levelItemGroup;
                                    }

                                    this.setState({
                                      levelItemGroup: levelItemGroup,
                                      level: nlevel,
                                    });
                                  }
                                  if (
                                    hcpPlanCategory.hcpSupportCategory ===
                                    EQUIPMENT_ITEM_CATEGORY_ID
                                  ) {
                                    console.log(
                                      "for once off items",
                                      hcpPlanItems
                                    );
                                    hcpPlanItems.map((item) =>
                                      onceOffItems.push({
                                        id: item.id,
                                        name: item.name.split(" | ")[0],
                                        description: item.name.split(" | ")[1],
                                        value: item.priceActual,
                                        date: item.startDate,
                                      })
                                    );
                                  }
                                  hcpPlanItemGroups[index] = {
                                    ...hcpPlanItemGroup,
                                    hcpPlanItems: hcpPlanItems || [],
                                  };
                                }
                              });
                              return {
                                ...hcpPlanItemGroup,
                              };
                            }
                            return {};
                          }
                        );
                        if (plan.id === hcpPlanCategory.plan) {
                          hcpPlanCategories[hcpPlanCategory.id] = {
                            ...hcpPlanCategory,
                            hcpPlanItemGroups: hcpPlanItemGroups || [],
                          };
                        }
                      }
                    });
                    hcpPlanCategories[hcpPlanCategory.id] = {
                      ...hcpPlanCategory,
                      hcpPlanItemGroups: [],
                    };
                  });
                }
              })
            );
          }
        }
      });
      flag = true;
      _.forEach(this.state.hcpSupportGroups, (hcpSupportGroup) => {
        _.forEach(
          hcpSupportGroup.hcpSupportCategories,
          (hcpSupportCategory) => {
            console.log(
              "Dashboard  Ln 398 尝试Load SupportItem SupportCate:" +
                hcpSupportCategory.id
            );
            api.Hcp_SupportItems.get({
              hcpsupportCategoryID: hcpSupportCategory.id,
            }).then((response) => {
              hcpSupportItems[hcpSupportCategory.id] = response.data;
              if (hcpSupportCategory.id === CATEGORY_LEVEL_ID) {
                _.map(response.data, (item, index) => {
                  if (item.unit === "Income&Level") {
                    testlevel[item.name.substr(-1)] = parseFloat(
                      item.price
                    ).toFixed(0);
                  }

                  if (item.hcpSupportItemGroup === Group_LEVEL_ID) {
                    if (item.unit !== "Income&Level") {
                      if (this.state.levelItemGroup[item.name] === undefined) {
                        levelItemGroup[item.name] = 0;
                      }
                    } else if (
                      this.state.levelItemGroup["level"] === undefined
                    ) {
                      levelItemGroup["level"] = testlevel[1];
                    }
                  }
                });
              }
            });
          }
        );
      });

      total =
        this.calculateDailyIncome() *
        (differenceInMinutes(
          new Date(planDates[this.state.planId].endDate),
          new Date(planDates[this.state.planId].startDate)
        ) /
          60 /
          24);
      _.map(hcpPlanCategories, (hcpPlanCategory) => {
        if (
          hcpPlanCategory.budget !== 0 &&
          Number(hcpPlanCategory.budget).toString() !== "NaN"
        ) {
          total += parseFloat(hcpPlanCategory.budget);
        }
      });
      total = total.toFixed(2);
      this.setState({
        planDates,
        planName,
        planNumber,
        hcpPlanCategories,
        levelItemGroup,
        hcpSupportItems,
        total,
        testlevel,
        flag,
        level,
        onceOffItems,
      });
    } else {
      let cachedhcpPlanCategories = localStorage.getItem(PLAN_CATEGORIES);
      const cachedName = localStorage.getItem("name");
      const cachedNumber = localStorage.getItem("hcpNumber");
      const cachedStartDate = localStorage.getItem("startDate");
      const cachedEndDate = localStorage.getItem("endDate");
      if (
        cachedStartDate !== null &&
        cachedEndDate !== null &&
        cachedhcpPlanCategories !== null
      ) {
        hcpPlanCategories = JSON.parse(cachedhcpPlanCategories);
        planDates[0] = {
          startDate: dateToString(cachedStartDate),
          endDate: dateToString(cachedEndDate),
        };
        planName = cachedName;
        planNumber = cachedNumber;
        flag = true;
        _.forEach(this.state.hcpSupportGroups, (hcpSupportGroup) => {
          _.forEach(
            hcpSupportGroup.hcpSupportCategories,
            (hcpSupportCategory) => {
              console.log(
                "Dashboard  Ln 398 尝试Load SupportItem SupportCate:" +
                  hcpSupportCategory.id
              );
              api.Hcp_SupportItems.get({
                hcpsupportCategoryID: hcpSupportCategory.id,
              }).then((response) => {
                hcpSupportItems[hcpSupportCategory.id] = response.data;
                if (hcpSupportCategory.id === CATEGORY_LEVEL_ID) {
                  _.map(response.data, (item, index) => {
                    if (item.unit === "Income&Level") {
                      testlevel[item.name.substr(-1)] = parseFloat(
                        item.price
                      ).toFixed(0);
                    }
                    if (item.hcpSupportItemGroup === Group_LEVEL_ID) {
                      _.map(hcpPlanCategories, (hcpPC, index) => {
                        if (hcpPC.hcpSupportCategory === CATEGORY_LEVEL_ID) {
                          console.log(hcpPC);
                          try {
                            if (
                              hcpPC.hcpPlanItemGroups !== undefined &&
                              hcpPC.hcpPlanItemGroups[0] !== undefined &&
                              hcpPC.hcpPlanItemGroups[0].hcpPlanItems !==
                                undefined
                            ) {
                              _.map(
                                hcpPC.hcpPlanItemGroups[0].hcpPlanItems,
                                (PI, index) => {
                                  if (PI.name === "level") {
                                    level = parseInt(PI.priceActual);
                                    levelItemGroup["level"] = testlevel[level];
                                  } else {
                                    levelItemGroup[PI.name] = PI.priceActual;
                                  }
                                }
                              );
                              this.setState({
                                levelItemGroup: levelItemGroup,
                                level: level,
                              });
                            } else {
                              if (item.unit !== "Income&Level") {
                                if (
                                  this.state.levelItemGroup[item.name] ===
                                  undefined
                                ) {
                                  levelItemGroup[item.name] = 0;
                                }
                              } else if (
                                this.state.levelItemGroup["level"] === undefined
                              ) {
                                levelItemGroup["level"] = testlevel[1];
                              }
                            }
                          } catch (e) {}
                        }
                      });
                    }
                  });
                }
              });
            }
          );
        });
        const equipmentPlanCategory =
          hcpPlanCategories[EQUIPMENT_ITEM_CATEGORY_ID];
        if (equipmentPlanCategory.hcpPlanItemGroups.length) {
          equipmentPlanCategory.hcpPlanItemGroups[0].hcpPlanItems.map((item) =>
            onceOffItems.push({
              id: item.id,
              name: item.name.split(" | ")[0],
              description: item.name.split(" | ")[1],
              value: item.priceActual,
              date: item.startDate,
            })
          );
        }
        total =
          this.calculateDailyIncome() *
          (differenceInMinutes(
            new Date(localStorage.getItem("endDate")),
            new Date(localStorage.getItem("startDate"))
          ) /
            60 /
            24);
        _.map(hcpPlanCategories, (hcpPlanCategory) => {
          if (
            hcpPlanCategory.budget !== 0 &&
            Number(hcpPlanCategory.budget).toString() !== "NaN"
          ) {
            total += parseFloat(hcpPlanCategory.budget);
          }
        });
        total = total.toFixed(2);
        this.setState({
          hcpPlanCategories,
          hcpSupportItems,
          planDates,
          levelItemGroup,
          planName,
          planNumber,
          testlevel,
          flag,
          total,
          level,
          onceOffItems,
        });
      } else {
        this.props.history.push("/hcp/budget/edit");
      }
    }

    this.updateState();
  };

  // check state every 2s
  updateState() {
    this.updateStateInterval = setInterval(() => {
      this.setState({ hcpPlanCategories: this.state.hcpPlanCategories });
    }, 2000);
  }

  // find SupportCategory through activeCategoryid
  findhcpSupportCategory = () => {
    let result = null;
    _.forEach(this.state.hcpSupportGroups, (hcpSupportGroup) => {
      _.forEach(hcpSupportGroup.hcpSupportCategories, (hcpSupportCategory) => {
        if (
          hcpSupportCategory.id ===
          this.state.hcpPlanCategories[this.state.activeCategory]
            .hcpSupportCategory
        ) {
          result = hcpSupportCategory;
        }
      });
    });
    return result;
  };

  // find SupportCategory through SupportCategoryid
  findhcpSupportCategoryById = (SCId) => {
    let result = null;
    _.forEach(this.state.hcpSupportGroups, (hcpSupportGroup) => {
      _.forEach(hcpSupportGroup.hcpSupportCategories, (hcpSupportCategory) => {
        if (hcpSupportCategory.id === SCId) {
          result = hcpSupportCategory;
        }
      });
    });
    return result;
  };

  // should open support SupportItemDialog
  handleOpenSupports = (categoryId) => {
    console.log("Open Support " + categoryId);
    this.setState(
      { activeCategory: categoryId, dialogPage: PLAN_ITEM_GROUPS_VIEW },
      () => {
        this.setState({ openSupports: true });
      }
    );
  };

  // not to render support SupportItemDialog
  handleCloseSupports = () => {
    this.setState({ openSupports: false } /* , () => this.loadState() */);
  };

  // update planItemGroups
  handleEditPlanItemGroups = (hcpPlanCategoryid, hcpPlanItemGroups) => {
    console.log("Try edit plan Item Groups PCID" + hcpPlanCategoryid);
    console.log(hcpPlanItemGroups);
    this.setState(
      {
        hcpPlanCategories: {
          ...this.state.hcpPlanCategories,
          [hcpPlanCategoryid]: {
            ...this.state.hcpPlanCategories[hcpPlanCategoryid],
            hcpPlanItemGroups: hcpPlanItemGroups,
          },
        },
      },
      () => {
        this.setState({
          selectedPlanItem: null,
          planItemDialogPage: -1,
          candrag: true,
        });
      }
    );
    if (
      hcpPlanItemGroups?.length !== 0 ||
      hcpPlanItemGroups?.length !== undefined
    ) {
      for (let i = 0; i < hcpPlanItemGroups?.length; i++) {
        if (
          hcpPlanItemGroups[i].hcpPlanCategory !== undefined &&
          hcpPlanItemGroups[i].id !== undefined
        ) {
          console.log("can go");
          let planId = this.state.planId;
          let planCategoryId = hcpPlanItemGroups[i].hcpPlanCategory;
          let planItemGroupId = hcpPlanItemGroups[i].id;

          console.log(hcpPlanItemGroups[i].hcpPlanItems);
          if (
            hcpPlanItemGroups[i].hcpPlanItems &&
            (hcpPlanItemGroups[i].hcpPlanItems.length !== 0 ||
              hcpPlanItemGroups[i].hcpPlanItems.length !== undefined)
          ) {
            console.log("have items");
            for (let j = 0; j < hcpPlanItemGroups[i].hcpPlanItems.length; j++) {
              let planItemId = hcpPlanItemGroups[i].hcpPlanItems[j].id;
              const planItemData = {
                name: hcpPlanItemGroups[i].hcpPlanItems[j].name,
                priceActual: hcpPlanItemGroups[i].hcpPlanItems[j].priceActual,
                startDate: dateToUTCString(
                  hcpPlanItemGroups[i].hcpPlanItems[j].startDate
                ),
                endDate: dateToUTCString(
                  hcpPlanItemGroups[i].hcpPlanItems[j].endDate
                ),
                allDay: hcpPlanItemGroups[i].hcpPlanItems[j].allDay,
                frequency: hcpPlanItemGroups[i].hcpPlanItems[j].frequency,
                isPercentage:
                  hcpPlanItemGroups[i].hcpPlanItems[j].isPercentage || false,
              };
              console.log("Dashboard 510 尝试Update PlanItem");
              console.log(planItemData);
              api.Hcp_PlanItems.update(
                planId,
                planCategoryId,
                planItemGroupId,
                planItemId,
                planItemData
              ).then((response) => {});
            }
          }
        }
      }
      this.setState({ showMonthView: false });
      setTimeout(() => {
        this.loadState();
      }, 1000);
    }
  };

  //delete Plan Category
  handleDeletePlanCategory = (PCID) => {
    let newPlanCategories = {};
    console.log("Try delete PlanCategory: " + PCID);
    _.map(this.state.hcpPlanCategories, (PC, index) => {
      if (PC.id !== PCID) {
        newPlanCategories[index] = PC;
      }
    });
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      api.Hcp_PlanCategory.delete(this.state.planId, PCID);
    }
    delete newPlanCategories[PCID];
    console.log("After delete ");
    console.log(newPlanCategories);
    this.setState({
      hcpPlanCategories: newPlanCategories,
    });
  };

  //delete Plan Item Group
  handleDeleteItemGroup = (Cid, IGId) => {
    let newPlanItemGroups = [];
    let newPlanCategory = this.state.hcpPlanCategories[Cid];
    _.map(newPlanCategory.hcpPlanItemGroups, (hcpPIG) => {
      if (hcpPIG.id !== IGId) {
        newPlanItemGroups.push(hcpPIG);
      }
    });
    newPlanCategory.hcpPlanItemGroups = newPlanItemGroups;
    this.setState({
      hcpPlanCategories: {
        ...this.state.hcpPlanCategories,
        [Cid]: newPlanCategory,
      },
    });
    api.Hcp_PlanItemGroups.delete(this.state.planId, Cid, IGId);
  };

  // delete planItem
  handleDeletePlanItem = () => {
    let hcpPlanItem = this.state.selectedPlanItem;
    let editedPlanItemGroups;
    //eslint-disable-next-line
    let hcpSupportCategory;
    let hcpPlanItemGroup;
    let Cid = 0;
    let found = false;
    console.log(hcpPlanItem);
    for (const [key, value] of Object.entries(this.state.hcpPlanCategories)) {
      if (found) {
        break;
      }
      console.log(value);
      for (let i = 0; i < value.hcpPlanItemGroups.length; i++) {
        if (found) {
          break;
        }
        console.log("PC" + key + "PIG" + i);
        if (value.hcpPlanItemGroups[i].hcpPlanItems.includes(hcpPlanItem)) {
          for (
            let j = 0;
            j < value.hcpPlanItemGroups[i].hcpPlanItems.length;
            j++
          ) {
            let PI = value.hcpPlanItemGroups[i].hcpPlanItems[j];
            console.log(PI);
            if (PI.id === hcpPlanItem.id) {
              hcpPlanItemGroup = value.hcpPlanItemGroups[i];
              Cid = value.id;
              const editedPlanItemGroup = {
                ...hcpPlanItemGroup,
                hcpPlanItems: _.difference(hcpPlanItemGroup.hcpPlanItems, [
                  hcpPlanItem,
                ]),
              };
              editedPlanItemGroups = _.difference(value.hcpPlanItemGroups, [
                hcpPlanItemGroup,
              ]);
              editedPlanItemGroups.push(editedPlanItemGroup);
              hcpSupportCategory = key;
              found = true;
              break;
            }
          }
        }
      }
    }
    console.log(hcpPlanItemGroup);
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      api.Hcp_PlanItems.delete(
        this.state.planId,
        Cid,
        hcpPlanItemGroup.id,
        this.state.selectedPlanItem.id
      );
    }
    console.log({ Cid, editedPlanItemGroups });
    this.handleEditPlanItemGroups(Cid, editedPlanItemGroups);
  };

  // update planItem
  handleEditPlanItem = (editedPlanItem) => {
    const hcpPlanItem = this.state.selectedPlanItem;
    console.log("updata PlanItem");
    console.log(editedPlanItem);
    console.log(hcpPlanItem);
    let editedPlanItemGroups;
    let hcpSupportCategory;
    for (const [key, value] of Object.entries(this.state.hcpPlanCategories)) {
      for (let i = 0; i < value.hcpPlanItemGroups.length; i++) {
        console.log("in nested for loop");
        if (value.hcpPlanItemGroups[i].hcpPlanItems.includes(hcpPlanItem)) {
          console.log("in if");
          const hcpPlanItemGroup = value.hcpPlanItemGroups[i];

          const editedPlanItemGroup = {
            ...hcpPlanItemGroup,
            hcpPlanItems: hcpPlanItemGroup.hcpPlanItems.map((pI) => {
              if (pI === hcpPlanItem) {
                return editedPlanItem;
              } else {
                return pI;
              }
            }),
          };
          console.log("editedPlanItemGroup", editedPlanItemGroup);
          editedPlanItemGroups = _.difference(value.hcpPlanItemGroups, [
            hcpPlanItemGroup,
          ]);
          editedPlanItemGroups.push(editedPlanItemGroup);
          hcpSupportCategory = key;

          break;
        }
      }
      if (hcpSupportCategory) break;
    }
    console.log({ hcpSupportCategory, editedPlanItemGroups });
    this.handleEditPlanItemGroups(hcpSupportCategory, editedPlanItemGroups);
  };

  // decide which month to show
  handleSetMonthView = (date) => {
    this.setState({ monthViewDate: date }, () => {
      console.log("show month view");
      console.log(this.state.monthViewDate);
      this.setState({ showMonthView: true });
    });
  };

  setShowDetailSummary = (showDetailedSummary) => {
    this.setState({ showDetailedSummary: showDetailedSummary });
  };
  // no render dialog
  handleCloseDialog = () => {
    this.setState({ planItemDialogPage: -1 });
  };

  // when a item is selected, record it
  handleSelectEvent = (info) => {
    console.log("select Item");
    console.log(info.event.extendedProps);
    this.setState({
      selectedPlanItem: info.event.extendedProps.hcpPlanItem,
      planItemDialogPage: PLAN_ITEM_DIALOG_PAGES.preview,
    });
  };

  // allow render delete page
  handleClickDelete = () => {
    this.setState({ planItemDialogPage: PLAN_ITEM_DIALOG_PAGES.delete });
  };

  // allow render edit page
  handleClickEdit = () => {
    this.setState({ planItemDialogPage: PLAN_ITEM_DIALOG_PAGES.edit });
  };

  // when a item is dragged in calendar, update its data in state
  handleItemDrag = (eventDropInfo) => {
    this.setState(
      {
        candrag: false,
      },
      () => {
        let PI = eventDropInfo.event._def.extendedProps.hcpPlanItem;
        let newPIGs = [];
        PI["startDate"] = new Date(eventDropInfo.event.start);
        console.log(eventDropInfo.event);
        PI["endDate"] = new Date(eventDropInfo.event.end);
        if (localStorage.getItem(LocalStorageKeys.ACCESS) == null) {
          console.log("No access");
          let PIs = PI.id.split("_");
          let PCid = PIs[1];
          let PIGid = PIs[3];
          let hcpPlanItemGroups = this.state.hcpPlanCategories[PCid]
            .hcpPlanItemGroups;
          let hcpPlanItems = hcpPlanItemGroups[PIGid - 1].hcpPlanItems;
          hcpPlanItems[PIs[5] - 1] = PI;
          hcpPlanItemGroups[PIGid - 1] = {
            ...hcpPlanItemGroups[PIGid - 1],
            hcpPlanItems: hcpPlanItems,
          };
          let newPC = {
            ...this.state.hcpPlanCategories,
            [PCid]: {
              ...this.state.hcpPlanCategories[PCid],
              hcpPlanItemGroups: hcpPlanItemGroups,
            },
          };

          console.log(newPC);
          this.setState(
            {
              hcpPlanCategories: newPC,
            },
            () => {
              this.setState({ candrag: true });
            }
          );
        } else {
          let Cid = 0;
          console.log(PI);
          _.map(this.state.hcpPlanCategories, (hcpPlanCategory, id) => {
            _.map(
              hcpPlanCategory.hcpPlanItemGroups,
              (hcpPlanItemGroup, PIGindex) => {
                if (hcpPlanItemGroup.id === PI.planItemGroup) {
                  console.log("find IGs dragged item in");
                  newPIGs = hcpPlanCategory.hcpPlanItemGroups;
                  console.log("newPIG");
                  _.map(hcpPlanItemGroup.hcpPlanItems, (planitem, index) => {
                    if (planitem.id === PI.id) {
                      newPIGs[PIGindex].hcpPlanItems[index] = PI;
                      console.log(newPIGs);
                      Cid = newPIGs[PIGindex].hcpPlanCategory;
                      console.log(Cid);
                      this.handleEditPlanItemGroups(Cid, newPIGs);
                    }
                  });
                }
              }
            );
          });
        }
      }
    );
  };
  setCategoryExpanded = (category) => {
    this.setState({
      categoryExpanded: category,
    });
  };
  setTableNav = (obj) => {
    if (obj) {
      console.log(obj);
      this.setState({
        tablenav: { nav: true, ...obj },
      });
    } else {
      this.setState({
        tablenav: null,
      });
    }
  };
  // render doughuntChart
  renderSummary = () => {
    let total = 0;
    let allocated = 0;
    let events = [];
    let budgets = [];
    let categories = [];
    let startDate, endDate;

    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      startDate = this.state.planDates[this.props.location.state.planId]
        .startDate;
      endDate = this.state.planDates[this.props.location.state.planId].endDate;
    } else {
      startDate = localStorage.getItem("startDate");
      endDate = localStorage.getItem("endDate");
    }
    Object.values(this.state.hcpPlanCategories).forEach((hcpPlanCategories) => {
      hcpPlanCategories.hcpPlanItemGroups.forEach((hcpPlanItemGroup) => {
        events = events.concat(planItemGroupToEvents(hcpPlanItemGroup));
      });
    });
    let dayindex = this.state.planId ? this.state.planId : 0;
    let days =
      differenceInMinutes(
        new Date(this.state.planDates[dayindex].endDate),
        new Date(this.state.planDates[dayindex].startDate)
      ) /
      60 /
      24;
    total = this.calculateDailyIncome() * days;
    _.map(this.state.hcpPlanCategories, (hcpPlanCategory) => {
      if (
        hcpPlanCategory.budget !== 0 &&
        Number(hcpPlanCategory.budget).toString() !== "NaN"
      ) {
        total += parseFloat(hcpPlanCategory.budget);
      }
      if (hcpPlanCategory.hcpSupportCategory !== CATEGORY_LEVEL_ID) {
        let money = calculateAllocated(hcpPlanCategory.hcpPlanItemGroups);
        if (money > 0) {
          budgets.push(money);

          categories.push(hcpPlanCategory.name);
          let index = categories.findIndex((i) => i[0] === "Service Category");
          allocated += money;
        }
      }
    });
    total = total.toFixed(2);
    this.allocated = allocated;
    this.total = total;

    return (
      <div>
        {this.state.selectedPlanItem !== null &&
          ((this.state.planItemDialogPage ===
            PLAN_ITEM_DIALOG_PAGES.preview && (
            <PlanItemCalendarDialog
              open={
                this.state.planItemDialogPage ===
                  PLAN_ITEM_DIALOG_PAGES.preview &&
                this.state.selectedPlanItem !== null
              }
              hcpPlanItem={this.state.selectedPlanItem}
              onClose={this.handleCloseDialog}
              onDelete={this.handleClickDelete}
              onEdit={this.handleClickEdit}
            />
          )) ||
            (this.state.planItemDialogPage ===
              PLAN_ITEM_DIALOG_PAGES.delete && (
              <PlanItemDeleteDialog
                open={
                  this.state.planItemDialogPage ===
                    PLAN_ITEM_DIALOG_PAGES.delete &&
                  this.state.selectedPlanItem !== null
                }
                onClose={this.handleCloseDialog}
                onDelete={this.handleDeletePlanItem}
              />
            )) ||
            (this.state.planItemDialogPage === PLAN_ITEM_DIALOG_PAGES.edit && (
              <Dialog
                open={
                  this.state.planItemDialogPage ===
                    PLAN_ITEM_DIALOG_PAGES.edit &&
                  this.state.selectedPlanItem !== null
                }
              >
                <PlanItemEditView
                  hcpPlanItem={this.state.selectedPlanItem}
                  onSave={this.handleEditPlanItem}
                  back={() => {
                    this.setState({
                      selectedPlanItem: null,
                    });
                  }}
                />
              </Dialog>
            )))}
        {this.state.showMonthView === true && (
          <Dialog
            open={this.state.showMonthView === true}
            onClose={() => this.setState({ showMonthView: false })}
          >
            <DialogTitle classes={{ root: styles.dialogTitle }}>
              <IconButton
                aria-label="close"
                className={styles.closeButton}
                onClick={() => this.setState({ showMonthView: false })}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                eventDrop={this.handleItemDrag}
                editable={this.state.candrag}
                dayMaxEventRows={2}
                initialDate={this.state.monthViewDate}
                fixedWeekCount={false}
                height={"auto"}
                initialEvents={this.events()}
                eventClick={this.handleSelectEvent}
              />
            </DialogContent>
          </Dialog>
        )}
        {}
        {this.state.showDetailedSummary === true && (
          <PrintDetailedSummary
            setShowDetailSummary={this.setShowDetailSummary}
            showDetailedSummary={this.state.showDetailedSummary}
            hcpPlanCategories={this.state.hcpPlanCategories}
            startdate={new Date(startDate)}
            enddate={new Date(endDate)}
            testlevel={this.state.testlevel}
          />
        )}

        <Card>
          <CardHeader
            title={
              <Typography gutterBottom variant="h5">
                Budget Summary
              </Typography>
            }
          />
          <CardContent>
            <Grid item>
              <DetailSummaryTbl
                plan={{
                  id: this.props.location.state?.planId ?? 0,
                  name: this.state.planName,
                  hcpNumber: this.state.planNumber,
                  startDate: this.state.planDates[
                    this.props.location.state?.planId ?? 0
                  ].startDate,
                  endDate: this.state.planDates[
                    this.props.location.state?.planId ?? 0
                  ].endDate,
                  hcpPlanCategories: this.state.hcpPlanCategories,
                }}
                level={this.state.level}
                categories={categories}
                allocated={allocated}
                budgets={budgets}
                hcpPlanCategories={this.state.hcpPlanCategories}
                // handleEditPlanItemGroups={this.handleEditPlanItemGroups}
                hcpSupportGroups={this.state.hcpSupportGroups}
                onLevelSave={() => this.onLevelSave()}
                setCategoryExpanded={this.setCategoryExpanded}
                handleManagement={this.handleManagement}
                handleOpenSupports={this.handleOpenSupports}
                setTableNav={this.setTableNav}
                total={total}
                monthViewDate={this.state.monthViewDate}
                showMonthView={this.state.showMonthView}
                eventhandleItem={this.handleItemDrag}
                eventeditable={this.state.candrag}
                eventinitialDate={this.state.monthViewDate}
                eventinitial={this.events()}
                eventonclick={this.handleSelectEvent}
                showmonthview={this.handleSetMonthView}
                available={this.getAvailableMoney()}
                hcpSupportItems={this.state.hcpSupportItems}
                groupExpanded={this.state.groupExpanded}
                categoryExpanded={this.state.categoryExpanded}
                handleCategoryExpand={this.handleCategoryExpand}
                findhcpSupportCategoryById={this.findhcpSupportCategoryById}
                handleDeletePlanCategory={this.handleDeletePlanCategory}
                handleDeleteItemGroup={this.handleDeleteItemGroup}
                handleAddSubCategory={this.handleAddSubCategory}
                handleGroupExpand={this.handleGroupExpand}
                handleCategoryNameSave={this.handleCategoryNameSave}
                handleItemGroupNameSave={this.handleItemGroupNameSave}
                handleAdd={this.handleAdd}
                onceOffItems={this.state.onceOffItems}
                onAddItem={this.handleAddNewItem}
                onSaveEqip={this.handleItemSave}
                onChangeItemField={this.handleItemFieldChange}
                onDeleteEqip={(itemId) => this.handleItemDelete(itemId)}
                handleBudgetSave={this.handleBudgetSave}
              />
            </Grid>
            <Grid
              item
              // xs={Object.keys(this.state.hcpPlanCategories).length || 7}
            >
              <DoughnutBody
                allocated={allocated}
                categories={categories}
                budgets={budgets}
                total={total}
              />
            </Grid>
            <Grid item></Grid>
          </CardContent>
          <CardActions disableSpacing>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  onClick={() => this.setState({ showDetailedSummary: true })}
                  size="small"
                >
                  12 Month Budget
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    );
  };

  handleItemFieldChange = (e, field, index) => {
    let onceOffItems = this.state.onceOffItems;
    onceOffItems[index] = {
      ...onceOffItems[index],
      [field]: field === "date" ? dateToString(new Date(e)) : e.target.value,
    };
    this.setState({
      onceOffItems,
    });
  };
  handleItemDelete = (itemId) => {
    let onceOffItems = this.state.onceOffItems;
    let itemsAfterDelete = onceOffItems.filter((item) => item.id !== itemId);
    this.setState({ onceOffItems: itemsAfterDelete }, () => {
      this.handleItemSave();
    });
  };
  handleItemSave = async () => {
    const planCategory = Object.values(this.state.hcpPlanCategories).filter(
      (pc) => pc.hcpSupportCategory === EQUIPMENT_ITEM_CATEGORY_ID
    )[0];
    let onceOffItems = this.state.onceOffItems;

    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      let planId = this.state.planId;
      let hcpPlanCategoryId = planCategory ? planCategory.id : 0;
      if (!planCategory) {
        let newCategory = {
          id: 0,
          hcpSupportCategory: EQUIPMENT_ITEM_CATEGORY_ID,
          budget: 0,
          name: "Equipment / Once Off Items",
          hcpPlanItemGroups: [],
        };
        await api.Hcp_PlanCategory.create(
          0,
          planId,
          EQUIPMENT_ITEM_CATEGORY_ID,
          "Equipment / Once Off Items"
        ).then((response) => {
          console.log(response.data);
          newCategory.id = response.data.id;
          hcpPlanCategoryId = response.data.id;
          newCategory.plan = planId;
          this.setState({
            hcpPlanCategories: {
              ...this.state.hcpPlanCategories,
              [response.data.id]: newCategory,
            },
          });
        });
      }
      const supportItem = this.state.hcpSupportItems[
        EQUIPMENT_ITEM_CATEGORY_ID
      ][0];

      let hcpPlanItemGroupData = {
        hcpPlanCategory: hcpPlanCategoryId,
        hcpSupportItemGroup: supportItem.hcpSupportItemGroup,
        name: planCategory ? planCategory.name : "Equipment / Once Off Items",
        nickname: planCategory
          ? planCategory.name
          : "Equipment / Once Off Items",
        scheduleStartDate: planCategory
          ? planCategory.scheduleStartDate || dateToUTCString(new Date())
          : dateToUTCString(new Date()),
        scheduleEndDate: planCategory
          ? planCategory.scheduleEndDate || dateToUTCString(new Date())
          : dateToUTCString(new Date()),
      };

      api.Hcp_PlanItemGroups.list(planId, hcpPlanCategoryId).then(
        (responsePlanItemGroup) => {
          console.log(responsePlanItemGroup.data);
          if (responsePlanItemGroup.data.length !== 0) {
            _.map(responsePlanItemGroup.data, (PlanItemGroup) => {
              api.Hcp_PlanItemGroups.delete(
                planId,
                hcpPlanCategoryId,
                PlanItemGroup.id
              );
            });
          }
          api.Hcp_PlanItemGroups.create(
            planId,
            hcpPlanCategoryId,
            hcpPlanItemGroupData
          ).then((response) => {
            let hcpPlanItemGroupId = response.data.id;
            onceOffItems.forEach((item) => {
              const hcpPlanItemData = {
                planItemGroup: hcpPlanItemGroupId,
                name: `${item.name} | ${item.description}`,
                priceActual: item.value,
                startDate: dateToUTCString(item.date),
                endDate: dateToUTCString(item.date),
                allDay: true,
                frequency: "YEARLY",
                isPercentage: item.isPercentage || false,
              };
              api.Hcp_PlanItems.create(
                planId,
                hcpPlanCategoryId,
                hcpPlanItemGroupId,
                hcpPlanItemData
              ).then((response) => {});
            });
            this.loadState();
          });
        }
      );
    } else {
      console.log(planCategory);
      planCategory.hcpPlanItemGroups = [
        {
          hcpPlanItems: [],
          hcpSupportItemGroup: 49,
          name: "Equipment / Once Off Items",
          nickname: "Equipment / Once Off Items",
        },
      ];
      onceOffItems.forEach((item) => {
        const hcpPlanItem = {
          id: parseInt(Math.random() * 1000),
          name: `${item.name} | ${item.description}`,
          priceActual: item.value,
          startDate: dateToString(item.date),
          endDate: dateToString(item.date),
          allDay: true,
        };
        planCategory.hcpPlanItemGroups[0].hcpPlanItems.push(hcpPlanItem);
      });
      let newOnceOffItems = [];
      planCategory.hcpPlanItemGroups[0].hcpPlanItems.map((item) =>
        newOnceOffItems.push({
          id: item.id,
          name: item.name.split(" | ")[0],
          description: item.name.split(" | ")[1],
          value: item.priceActual,
          date: item.startDate,
        })
      );
      let hcpPlanCategories = {
        ...this.state.hcpPlanCategories,
        [EQUIPMENT_ITEM_CATEGORY_ID]: planCategory,
      };
      this.setState({ hcpPlanCategories, onceOffItems: newOnceOffItems });
    }
  };

  //add a item after click + new item
  handleAddNewItem = () => {
    let onceOffItems = this.state.onceOffItems;
    onceOffItems.push({
      name: "",
      description: "",
      value: "",
      date: dateToString(new Date()),
    });
    this.setState({ onceOffItems });
  };

  // render Accordion for categories
  renderhcpPlanCategories = () => {
    const { classes } = this.props;
    let total = 0;
    let allocated = 0;
    let events = [];
    let categories = [];

    const findBudget = (name) => {
      for (const i of categories) {
        if (i[0] === name) {
          return i[1];
        }
      }
      return 0;
    };

    Object.values(this.state.hcpPlanCategories).forEach((hcpPlanCategories) => {
      hcpPlanCategories.hcpPlanItemGroups.forEach((hcpPlanItemGroup) => {
        events = events.concat(planItemGroupToEvents(hcpPlanItemGroup));
      });
    });
    let dayindex = this.state.planId ? this.state.planId : 0;
    let days =
      differenceInMinutes(
        new Date(this.state.planDates[dayindex].endDate),
        new Date(this.state.planDates[dayindex].startDate)
      ) /
      60 /
      24;
    total = this.calculateDailyIncome() * days;
    _.map(this.state.hcpPlanCategories, (hcpPlanCategory) => {
      if (
        hcpPlanCategory.budget !== 0 &&
        Number(hcpPlanCategory.budget).toString() !== "NaN"
      ) {
        total += parseFloat(hcpPlanCategory.budget);
      }
      if (hcpPlanCategory.hcpSupportCategory !== CATEGORY_LEVEL_ID) {
        let money = calculateAllocated(hcpPlanCategory.hcpPlanItemGroups);
        if (money > 0) {
          if (hcpPlanCategory.name === "Equipment / Once Off Items") {
            categories.push([hcpPlanCategory.name, money]);
          } else if (
            hcpPlanCategory.name === "Care Management" ||
            hcpPlanCategory.name === "Package Management"
          ) {
            categories.push([hcpPlanCategory.name, money]);
          } else {
            let index = categories.findIndex(
              (i) => i[0] === "Service Category"
            );
            if (index === -1) {
              categories.push(["Service Category", money]);
            } else {
              categories.splice(index, 1, [
                "Service Category",
                findBudget("Service Category") + money,
              ]);
            }
          }
          allocated += money;
        }
      }
    });
    total = total.toFixed(2);
    this.allocated = allocated;
    this.total = total;
    return _.map(this.state.hcpSupportGroups, (hcpSupportGroup, index) => {
      let supportCategories = {};
      _.map(hcpSupportGroup.hcpSupportCategories, (hcpSC) => {
        supportCategories[hcpSC.id] = 1;
      });
      return (
        <>
          {index !== 0 ? (
            hcpSupportGroup.id < 3 ? (
              <>
                {_.map(
                  this.state.hcpPlanCategories,
                  (hcpPlanCategory, PCId) => {
                    if (
                      supportCategories[hcpPlanCategory.hcpSupportCategory] !==
                      undefined
                    ) {
                      if (
                        hcpPlanCategory.hcpSupportCategory !==
                          Other_CATEGORY_ID &&
                        hcpSupportGroup.id < 3
                      ) {
                        let hcpSupportCategory = this.findhcpSupportCategoryById(
                          hcpPlanCategory.hcpSupportCategory
                        );
                        return (
                          <HcpBudgetCategoryCard
                            key={PCId}
                            {...{
                              supportcategory: hcpSupportCategory,
                              plancategory: hcpPlanCategory,
                              totalColor: LIGHT_BLUE,
                              isEditable: this.state.isEditable,
                              allocatedColor: DARK_BLUE,
                              supportitems: this.state.hcpSupportItems,
                              hcpRegistrationGroup: null,
                              expanded: this.state.groupExpanded,
                              eid: hcpSupportCategory.id,
                            }}
                            openSupports={() =>
                              this.handleOpenSupports(hcpPlanCategory.id)
                            }
                            deleteCategory={this.handleDeletePlanCategory}
                            deleteItemGroup={this.handleDeleteItemGroup}
                            handleAddSubCategory={this.handleAddSubCategory}
                            handleExpanded={this.handleGroupExpand}
                            saveCategoryName={this.handleCategoryNameSave}
                            saveItemGroupName={this.handleItemGroupNameSave}
                            handleadd={this.handleAdd}
                            handleManagement={this.handleManagement}
                            handleCategoryExpand={this.handleCategoryExpand}
                            categoryExpanded={this.state.categoryExpanded}
                          />
                        );
                      }
                    }
                  }
                )}
              </>
            ) : null
          ) : null}
        </>
      );
    });
  };

  // to save a category name if click yes
  handleCategoryNameSave = (id, name) => {
    let hcpPlanCategories = this.state.hcpPlanCategories;
    console.log("try edit PC " + id + " name to:" + name);
    if (hcpPlanCategories[id]) {
      console.log("try edit PC " + id + " name to:" + name);
      let plancategoryid = hcpPlanCategories[id].id;
      if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
        console.log("Try Update " + plancategoryid + " name:" + name);
        api.Hcp_PlanCategory.update(
          this.state.planId,
          plancategoryid,
          name
        ).then((response) => {
          console.log("Update " + plancategoryid + " name:" + name);
        });
      }
    }
    this.setState({
      hcpPlanCategories: {
        ...hcpPlanCategories,
        [id]: {
          ...hcpPlanCategories[id],
          name: name,
        },
      },
    });
  };

  handleBudgetSave = (budget) => {
    let hcpPlanCategories = this.state.hcpPlanCategories;
    let id = null;
    _.forEach(this.state.hcpPlanCategories, (value) => {
      if (value.hcpSupportCategory === 11) {
        id = value.id;
      }
    });
    budget = Number(budget).toFixed(2);
    if (id) {
      console.log("try edit PC " + id + " budget to:" + budget);
      if (hcpPlanCategories[id]) {
        console.log("try edit PC " + id + " budget to:" + budget);
        let plancategoryid = hcpPlanCategories[id].id;
        if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
          console.log("Try Update " + plancategoryid + " budget:" + budget);
          api.Hcp_PlanCategory.updateBudget(
            this.state.planId,
            plancategoryid,
            budget
          ).then((response) => {
            console.log("Update " + plancategoryid + " budget:" + budget);
          });
        }
      }
    }
  };

  // to save a ItemGroupname if click yes
  handleItemGroupNameSave = (categoryid, index, name) => {
    console.log("Cid " + categoryid + " IID" + index + " Name:" + name);
    let hcpPlanCategories = this.state.hcpPlanCategories;
    let hcpPlanItemGroups = hcpPlanCategories[categoryid].hcpPlanItemGroups;
    hcpPlanItemGroups[index].name = name;
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      api.Hcp_PlanItemGroups.update(
        this.state.planId,
        hcpPlanCategories[categoryid].id,
        hcpPlanItemGroups[index].id,
        { name }
      ).then((response) => {
        console.log("update plan item group name");
      });
    }

    let hcpPlanCategpry = hcpPlanCategories[categoryid];

    this.setState({
      hcpPlanCategories: {
        ...hcpPlanCategories,
        [categoryid]: {
          ...hcpPlanCategpry,
          hcpPlanItemGroups: hcpPlanItemGroups,
        },
      },
    });
  };

  // unused function
  handleAdd = (id, itemgroups) => {
    if (itemgroups.length === 0) {
      console.log(itemgroups);
      return;
    }
    console.log("handle add");
    let itemnames = {};
    let planitemgroups = this.state.hcpPlanCategories[id]["hcpPlanItemGroups"];
    for (let i = 0; i < itemgroups.length; i++) {
      for (let j = 0; j < planitemgroups.length; j++) {
        if (
          planitemgroups[j].hcpSupportItemGroup ===
          itemgroups[i].hcpSupportItemGroup
        ) {
          itemnames[i] = planitemgroups[j].name;
          break;
        }
      }
    }
    console.log("Active ID: " + id);
    this.setState(
      {
        activeCategory: id,
        activeItems: itemgroups,
        activeItemName: itemnames,
        dialogPage: SUPPORT_ITEMS_VIEW,
      },
      () => {
        this.setState({ openSupports: true });
      }
    );
  };

  // allow render page for handling package management
  handleManagement = (id) => {
    console.log("handle management");
    let dialogPage = this.state.hcpPlanCategories[id].hcpPlanItemGroups.length
      ? PLAN_ITEM_GROUPS_VIEW
      : MANAGEMENT_EDIT;
    this.setState(
      {
        activeCategory: id,
        dialogPage,
      },
      () => {
        this.setState({ openSupports: true });
      }
    );
  };

  // when expland a category panel, close other panels explanded
  handleCategoryExpand = (panel) => (event, isExpanded) => {
    this.setState({ categoryExpanded: isExpanded ? panel : false });
  };

  // when expland a itemgroup panel, close other panels explanded
  handleGroupExpand = (panel) => (event, isExpanded) => {
    let dayindex = this.state.planId ? this.state.planId : 0;
    let days =
      differenceInMinutes(
        new Date(this.state.planDates[dayindex].endDate),
        new Date(this.state.planDates[dayindex].startDate)
      ) /
      60 /
      24;
    let total = this.calculateDailyIncome() * days;
    _.map(this.state.hcpPlanCategories, (hcpPlanCategory) => {
      if (
        hcpPlanCategory.budget !== 0 &&
        Number(hcpPlanCategory.budget).toString() !== "NaN"
      ) {
        total += parseFloat(hcpPlanCategory.budget);
      }
    });
    total = total.toFixed(2);
    this.setState({ total });
    console.log("Group Expand " + this.state.groupExpanded);
    this.setState({ groupExpanded: isExpanded ? panel : false });
  };

  // add a itemgroup after click add new
  handleAddSubCategory = (Cid, ItemsId, PIGname) => {
    console.log("Add new subcategory; Cid:" + Cid + " ItemsId: " + ItemsId);
    let items = [];
    let categoryItems = this.state.hcpSupportItems[
      this.state.hcpPlanCategories[Cid].hcpSupportCategory
    ];
    if (ItemsId.length > 0) {
      for (let i = 0; i < ItemsId.length; i++) {
        if (ItemsId[i] > 0) {
          const arrFind = (item) => item.id === ItemsId[i];
          items.push(categoryItems.find(arrFind));
        }
      }
      this.setState(
        {
          activeCategory: Cid,
          activeItems: items,
          activeItemName: PIGname,
          dialogPage: SUPPORT_ITEMS_VIEW,
        },
        () => {
          this.setState({ openSupports: true });
        }
      );
    }
  };

  // add a category group after click + new category
  // addNewCategory = () => {
  //   console.log("try add new Category");
  //   let newCategory = {
  //     id: 0,
  //     hcpSupportCategory: Other_CATEGORY_ID,
  //     budget: 0,
  //     name: "New Category",
  //     hcpPlanItemGroups: [],
  //   };
  //   if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
  //     api.Hcp_PlanCategory.create(
  //       0,
  //       this.state.planId,
  //       Other_CATEGORY_ID,
  //       "New Category"
  //     ).then((response) => {
  //       console.log(response.data);
  //       newCategory["id"] = response.data.id;
  //       newCategory["plan"] = this.state.planId;
  //       this.setState({
  //         hcpPlanCategories: {
  //           ...this.state.hcpPlanCategories,
  //           [response.data.id]: newCategory,
  //         },
  //       });
  //     });
  //   } else {
  //     let id = 0;
  //     _.map(this.state.hcpPlanCategories, (hcpPC, index) => {
  //       if (parseInt(index) > id) {
  //         id = parseInt(index);
  //       }
  //     });
  //     newCategory["id"] = id + 1;
  //     this.setState({
  //       hcpPlanCategories: {
  //         ...this.state.hcpPlanCategories,
  //         [id + 1]: newCategory,
  //       },
  //     });
  //   }
  // };

  // transform all items into events for calendar
  events = () => {
    const events = [];
    for (const hcpPlanCategory of Object.values(this.state.hcpPlanCategories)) {
      if (hcpPlanCategory.hcpSupportCategory !== CATEGORY_LEVEL_ID) {
        hcpPlanCategory.hcpPlanItemGroups.forEach((hcpPlanItemGroup) => {
          const toAdd = planItemGroupToEvents(hcpPlanItemGroup);
          toAdd && events.push(...toAdd);
        });
      }
    }

    return events;
  };

  onLevelSave = () => this.loadState();
  getTotalMoney = () => this.total;
  getAvailableMoney = () =>
    Math.round(100 * (this.total - this.allocated)) / 100;
  render() {
    const {
      planDates,
      hcpPlanCategories,
      hcpSupportGroups,
      total,
      flag,
    } = this.state;
    return (
      <div className="root">
        {flag ? (
          <Grid container justify="center">
            <Grid container justify="center">
              {this.state.hcpPlanCategories !== {} && (
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <Card style={{ marginBottom: "1rem" }}>
                    <CardHeader
                      title={
                        <Typography variant="h4" style={{ width: "100%" }}>
                          {this.state.planName}
                        </Typography>
                      }
                    />
                    {/* <CardContent>
                      <HcpPlanDetails
                        plan={{
                          id: this.props.location.state?.planId ?? 0,
                          name: this.state.planName,
                          hcpNumber: this.state.planNumber,
                          startDate: this.state.planDates[
                            this.props.location.state?.planId ?? 0
                          ].startDate,
                          endDate: this.state.planDates[
                            this.props.location.state?.planId ?? 0
                          ].endDate,
                          hcpPlanCategories: this.state.hcpPlanCategories,
                        }}
                        show='onlyPersonalDetails'
                        hcpSupportGroups={this.state.hcpSupportGroups}
                        onLevelSave={() => this.onLevelSave()}
                      />
                    </CardContent> */}
                  </Card>

                  {this.renderSummary()}
                  {/* {this.renderhcpPlanCategories()} */}
                </Grid>
              )}
            </Grid>
            {this.state.openSupports && (
              <SupportItemDialog
                editedItems={this.state.activeItems}
                itemName={this.state.activeItemName}
                open={this.state.openSupports}
                planid={this.state.planId}
                planStartDate={
                  planDates[this.props.location.state?.planId]?.startDate ??
                  dateToString(localStorage.getItem("startDate"))
                }
                planEndDate={
                  planDates[this.props.location.state?.planId]?.endDate ??
                  dateToString(localStorage.getItem("endDate"))
                }
                planName={this.state.planName}
                planNumber={this.state.planNumber}
                planHcpCategories={this.state.planHcpCategories}
                hcpSupportCategory={this.findhcpSupportCategory()}
                onClose={this.handleCloseSupports}
                onhandleItemDrag={this.handleItemDrag}
                hcpPlanCategory={
                  this.state.hcpPlanCategories[this.state.activeCategory]
                }
                total={this.getTotalMoney()}
                available={this.getAvailableMoney()}
                activeCategory={this.state.activeCategory}
                onEditHcpPlanItemGroups={this.handleEditPlanItemGroups}
                onhandleDeleteItemGroup={this.handleDeleteItemGroup}
                openAddSupports={this.state.openAddSupports}
                registrationGroups={this.state.registrationGroups}
                page={this.state.dialogPage}
                setPage={(page) => this.setState({ dialogPage: page })}
                tablenav={this.state.tablenav}
                setTableNav={this.setTableNav}
              />
            )}
          </Grid>
        ) : (
          <></>
        )}
        <Fab
          variant="extended"
          color="primary"
          aria-label="add"
          style={{
            position: "fixed",
            bottom: 16,
            right: 16,
            backgroundColor: this.getAvailableMoney() > 0 ? "green" : "red",
          }}
          onClick={() => window.scrollTo(0, 70)}
        >
          <NavigationIcon sx={{ mr: 1 }} />
          {"$ " + this.getAvailableMoney()}
        </Fab>
      </div>
    );
  }
}

export default connect(mapStateToProps)(
  withStyles(paperStyles)(hcp_BudgetDashboard)
);
