// import React from "react";
// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from "@fullcalendar/daygrid";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";

// export default function CustomCalendar(props) {
//   const { newEvents } = props;

//   return (
//     <FullCalendar
//       defaultView="dayGridMonth"
//       plugins={[dayGridPlugin]}
//       events={newEvents}
//       height={"auto"}
//       fixedWeekCount={false}
//     />
//   );
// }
import React from "react";
// import { Calendar } from '@fullcalendar/core';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";
// import interactionPlugin from "@fullcalendar/interaction";
// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";

import "./main1.css";
import "./main2.css";

export default function CustomCalendar(props) {
  const { newEvents } = props;
  console.log(newEvents);
  function handleEventClick() {
    console.log("click");
  }

  return (
    <FullCalendar
      initialView="dayGridMonth"
      plugins={[dayGridPlugin]}
      events={newEvents}
      height={"auto"}
      editable={true}
      selectMirror={true}
      selectable={true}
      fixedWeekCount={false}
      eventClick={handleEventClick}
    />
  );
}
