import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { CATEGORY_LEVEL_ID } from "./hcp_BudgetDashboard";
import _, { sum } from "lodash";
import { Container } from "@material-ui/core";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tablecell: {
    background: "#FF7F50",
  },
  tableContainer: {
    width: "98%",
    padding: "10px",
    // boxShadow: "0px 0px 1px 0px"
  },
});

const DetailedSummary = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { hcpPlanCategories, startdate, enddate, testlevel } = props;

  const formatMoney = (money) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      // style:'decimal'
    })
      .format(money)
      .replace("$", "");
  };

  let monthname = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let startmonth = startdate.getMonth();
  let startyear = startdate.getFullYear();

  console.log(enddate);
  let head_date =
    new Date(startyear, startmonth + 1, 0) < enddate
      ? new Date(startyear, startmonth + 1, 0)
      : enddate;
  let sum_cost_Per_M = [];
  let Categories_service = [];
  let leveldaily = 0;
  let supdaily = 0.0;
  let Surplus = [];
  let detailedForm = {
    Level: [],
    Supplement: [],
  };

  _.map(hcpPlanCategories, (PC) => {
    if (
      PC.hcpSupportCategory === CATEGORY_LEVEL_ID &&
      PC.hcpPlanItemGroups.length > 0
    ) {
      _.map(PC.hcpPlanItemGroups[0].hcpPlanItems, (PI) => {
        if (PI.name === "level") {
          leveldaily = parseFloat(testlevel[parseInt(PI.priceActual) || 1]);
        } else {
          supdaily = supdaily + parseFloat(PI.priceActual);
        }
      });
    } else {
      detailedForm[PC.name] = [];
      Categories_service.push(PC);
    }
  });

  const daysEachMonth = [];
  daysEachMonth.push({
    month: startmonth,
    year: startyear,
    days: head_date.getDate() - startdate.getDate(),
  });
  console.log(new Date(startyear, startmonth, 0));
  for (let i = 0; i < 11; i++) {
    if (new Date(startyear, startmonth + i + 1, 0) >= enddate) {
      break;
    }
    let newDate = new Date(startyear, startmonth + i + 2, 0);
    daysEachMonth.push({
      month: newDate.getMonth(),
      year: newDate.getFullYear(),
      days: newDate.getDate(),
    });
  }
  let monthgap =
    (enddate.getFullYear() - startdate.getFullYear()) * 12 -
    startdate.getMonth() +
    enddate.getMonth();
  if (monthgap > 0 && monthgap < 13) {
    daysEachMonth.push({
      month: enddate.getMonth(),
      year: enddate.getFullYear(),
      days: enddate.getDate(),
    });
  }

  _.map(daysEachMonth, (item, i) => {
    console.log(item.days);
    detailedForm["Level"].push(leveldaily * item.days);
    detailedForm["Supplement"].push(supdaily * item.days);
    let sum_Per_M = 0.0;
    _.map(Categories_service, (PC) => {
      if (PC.name !== "Level" && PC.name !== "Supplement") {
        let cost = 0.0;
        _.map(PC.hcpPlanItemGroups, (PIG) => {
          _.map(PIG.hcpPlanItems, (PI) => {
            let itemstartdate = new Date(PI.startDate);
            if (
              itemstartdate >= new Date(item.year, item.month, 0) &&
              itemstartdate < new Date(item.year, item.month + 1, 0)
            ) {
              cost = cost + parseFloat(PI.priceActual);
            }
          });
        });
        detailedForm[PC.name].push(cost);
        sum_Per_M = sum_Per_M + cost;
      }
    });
    sum_cost_Per_M.push(sum_Per_M);
    Surplus.push(
      detailedForm["Supplement"][i] + detailedForm["Level"][i] - sum_Per_M
    );
  });

  let unspentFunds = 0;
  _.map(hcpPlanCategories, (hcpPlanCategory) => {
    if (
      hcpPlanCategory.budget !== 0 &&
      Number(hcpPlanCategory.budget).toString() !== "NaN"
    ) {
      unspentFunds += parseFloat(hcpPlanCategory.budget);
    }
  });
  detailedForm["Level"][0] += unspentFunds;

  console.log(detailedForm);
  console.log(daysEachMonth);
  console.log(Categories_service);

  return (
    <Container ref={ref} style={{ padding: "10px", width: "98%" }}>
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          size="medium"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  width: "10%",
                  fontWeight: "1000",
                  fontStyle: "italic",
                }}
              >
                INCOME
              </TableCell>
              {_.map(daysEachMonth, (item, index) => {
                return (
                  <TableCell key={index}>
                    {monthname[item.month] +
                      "-" +
                      (item.year + "").substring((item.year + "").length - 2)}
                  </TableCell>
                );
              })}
              <TableCell style={{ fontWeight: "bold" }}> Total </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Level Income
              </TableCell>
              {_.map(detailedForm["Level"], (item, index) => {
                return <TableCell key={index}>{formatMoney(item)}</TableCell>;
              })}
              <TableCell style={{ fontWeight: "bold" }}>
                {formatMoney(sum(detailedForm["Level"]))}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Supplement
              </TableCell>
              {_.map(detailedForm["Supplement"], (item, index) => {
                return (
                  <TableCell key={index}>
                    {item > 0 ? formatMoney(item) : "-"}
                  </TableCell>
                );
              })}
              <TableCell style={{ fontWeight: "bold" }}>
                {sum(detailedForm["Supplement"]) > 0
                  ? formatMoney(sum(detailedForm["Supplement"]))
                  : "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row"></TableCell>
              {_.map(detailedForm["Supplement"], (item, i) => {
                return (
                  <TableCell key={i}>
                    {formatMoney(
                      detailedForm["Supplement"][i] + detailedForm["Level"][i]
                    )}
                  </TableCell>
                );
              })}
              <TableCell style={{ fontWeight: "bold" }}>
                {formatMoney(
                  sum(detailedForm["Supplement"]) + sum(detailedForm["Level"])
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: "1000", fontStyle: "italic" }}>
                EXPENDITURE
              </TableCell>
              {_.map(daysEachMonth, (item, index) => {
                return <TableCell key={index}> </TableCell>;
              })}
              <TableCell style={{ fontWeight: "bold" }}> </TableCell>
            </TableRow>
            {_.map(detailedForm, (item, name) => {
              if (name !== "Level" && name !== "Supplement") {
                return sum(item) ? (
                  <TableRow key={name}>
                    <TableCell component="th" scope="row">
                      {name}
                    </TableCell>
                    {_.map(item, (itemPerM, index) => {
                      return (
                        <TableCell key={index}>
                          {itemPerM > 0 ? formatMoney(itemPerM) : "-"}
                        </TableCell>
                      );
                    })}
                    <TableCell style={{ fontWeight: "bold" }}>
                      {sum(item) > 0 ? formatMoney(sum(item)) : "-"}
                    </TableCell>
                  </TableRow>
                ) : null;
              }
            })}

            <TableRow>
              <TableCell component="th" scope="row"></TableCell>
              {_.map(sum_cost_Per_M, (itemPerM, index) => {
                return (
                  <TableCell key={index}>
                    {itemPerM > 0 ? formatMoney(itemPerM) : "-"}
                  </TableCell>
                );
              })}
              <TableCell style={{ fontWeight: "bold" }}>
                {sum(sum_cost_Per_M) > 0
                  ? formatMoney(sum(sum_cost_Per_M))
                  : "-"}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontWeight: "1000", fontStyle: "italic" }}>
                Surplus /(Deficit)
              </TableCell>
              {_.map(Surplus, (item, i) => {
                return (
                  <TableCell
                    className={item < 0 ? classes.tablecell : ""}
                    key={i}
                  >
                    {formatMoney(item)}{" "}
                  </TableCell>
                );
              })}
              <TableCell
                style={{ fontWeight: "bold" }}
                className={sum(Surplus) < 0 ? classes.tablecell : ""}
              >
                {" "}
                {formatMoney(sum(Surplus))}{" "}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Balance</TableCell>
              {_.map(Surplus, (item, i) => {
                return (
                  <TableCell
                    className={
                      sum(Surplus.slice(0, i + 1)) < 0 ? classes.tablecell : ""
                    }
                    key={i}
                  >
                    {formatMoney(sum(Surplus.slice(0, i + 1)))}
                  </TableCell>
                );
              })}
              <TableCell style={{ fontWeight: "bold" }}> </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
});
export default DetailedSummary;
