import React from "react";
import { message } from "antd";
import { Typography } from "@material-ui/core";

export default class ChangeInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInput: false,
      valueCon: "",
    };
  }

  componentDidMount() {
    this.setState({
      valueCon: this.props.value,
    });
  }

  render() {
    const { value, fontSize, showSize, totalamount } = this.props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {!this.state.showInput ? (
          <div
            className="change_input"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span className="change_input_name" style={{ fontSize: fontSize }}>
              {showSize && value.length > showSize ? (
                <span>{value.slice(0, showSize) + "..."}</span>
              ) : (
                value
              )}
            </span>
          </div>
        ) : null}
        {totalamount && totalamount !== "0" ? (
          <Typography style={{ fontSize: fontSize }}>${totalamount}</Typography>
        ) : null}
      </div>
    );
  }
}
