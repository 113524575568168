import React from "react";
import Accordion from "@material-ui/core/Accordion/index";
import AccordionSummary from "@material-ui/core/AccordionSummary/index";
import AccordionDetails from "@material-ui/core/AccordionDetails/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid/index";
import ValidatedTextField from "../../common/ValidatedTextField";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography/index";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
import api from "../../api";
import { LocalStorageKeys } from "../../common/constants";
import moment from "moment";
import Button from "@material-ui/core/Button/index";
import Paper from "@material-ui/core/Paper/index";
import withStyles from "@material-ui/core/styles/withStyles";

export const CATEGORY_LEVEL_ID = 11;
export const Group_LEVEL_ID = 43;

const paperStyles = {
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: 8,
  },
  buttonMargin: {
    marginTop: 10,
  },
};

function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}
function dateToString(date) {
  return moment(date).format();
}
function dateToYMDString(date) {
  return moment(date).format("YYYY-MM-DD");
}
function getNextYearFromDate(date) {
  const nextYear = new Date(date);
  nextYear.setFullYear(date.getFullYear() + 1);
  return nextYear;
}

let nameRegex = new RegExp(/^[a-zA-Z ]+$/);
let HCPNumberRegex = new RegExp(/^\d{0,9}$/);

class PlanDetails extends React.Component {
  state = {
    hcpSupportItems: [],
    currentPlanLevel: 1,
    levelItemGroup: {},
    testlevel: {},
    planName: "",
    planNumber: "",
    planDates: {
      startDate: "",
      endDate: "",
    },
    hcpPlanCategories: {},
  };

  async componentDidMount() {
    this.loadState();
    this.getPlanLevel();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.plan, prevProps.plan)) this.loadState();
  }

  loadState = () => {
    const { plan } = this.props;
    let hcpPlanCategories = {};
    api.Hcp_SupportItems.get({
      hcpsupportCategoryID: CATEGORY_LEVEL_ID,
    }).then((response) => {
      //eslint-disable-next-line
      this.state.hcpSupportItems[CATEGORY_LEVEL_ID] = response.data;

      _.map(response.data, (item, index) => {
        if (item.unit === "Income&Level") {
          //eslint-disable-next-line
          this.state.testlevel[item.name.substr(-1)] = parseFloat(
            item.price
          ).toFixed(0);
        }
      });
    });
    if (Array.isArray(plan.hcpPlanCategories)) {
      _.map(plan.hcpPlanCategories, (planCategory) => {
        hcpPlanCategories[planCategory.hcpSupportCategory] = {
          ...planCategory,
        };
      });
    } else hcpPlanCategories = plan.hcpPlanCategories;
    this.setState({
      planName: plan.name,
      planNumber: plan.hcpNumber,
      planDates: {
        startDate: plan.startDate,
        endDate: plan.endDate,
      },
      hcpPlanCategories,
    });
  };

  handleNameChange = (e) => {
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      if (e.target.value === "" || nameRegex.test(e.target.value)) {
        let planName = e.target.value;
        this.setState({ planName });
      }
    } else {
      localStorage.setItem("name", e.target.value);
    }
  };
  handleHCPNumberChange = (e) => {
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      if (e.target.value === "" || HCPNumberRegex.test(e.target.value)) {
        let planNumber = e.target.value;
        this.setState({ planNumber });
      }
    } else {
      localStorage.setItem("hcpNumber", e.target.value);
    }
  };
  handleStartDateChange = (e) => {
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      let planDates = { ...this.state.planDates };
      planDates.startDate = dateToString(new Date(e));
      planDates.endDate = dateToString(getNextYearFromDate(new Date(e)));
      this.setState({ planDates });
    } else {
      localStorage.setItem("startDate", new Date(e));
      localStorage.setItem("endDate", getNextYearFromDate(new Date(e)));
    }
  };
  handleEndDateChange = (e) => {
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      let planDates = { ...this.state.planDates };
      let date = new Date(e);
      if (date < new Date(planDates.startDate)) {
        console.log("invalid end date");
        return;
      }
      planDates.endDate = dateToString(date);
      this.setState({ planDates });
    } else {
      localStorage.setItem("endDate", new Date(e));
    }
  };

  // if a supplement is ticked or level changed, store it in levelitemgroup
  handleSupClick = (index, supitem) => {
    let level = this.state.currentPlanLevel;
    let money = supitem;
    let SupportIG_level = parseInt(level) + 42;
    if (index === "level") {
      money = this.state.testlevel[supitem];
      let levelItemGroup = this.state.levelItemGroup;
      _.map(this.state.hcpSupportItems[CATEGORY_LEVEL_ID], (item) => {
        if (item.hcpSupportItemGroup === SupportIG_level) {
          if (item.unit !== "Income&Level") {
            if (levelItemGroup[item.name > 0]) {
              levelItemGroup[item.name] = item.price;
            }
          } else {
            levelItemGroup["level"] = money;
          }
        }
      });
      this.setState({
        levelItemGroup: levelItemGroup,
        currentPlanLevel: supitem,
      });
    } else {
      console.log(index + " " + supitem);
      if (
        this.state.levelItemGroup[index] !== undefined &&
        this.state.levelItemGroup[index] > 0
      ) {
        money = 0;
      }
      this.setState({
        levelItemGroup: {
          ...this.state.levelItemGroup,
          [index]: money,
        },
      });
    }
  };

  // get daily income according to itemGroup level
  calculateDailyIncome() {
    let alldaily = 0;
    _.map(this.state.levelItemGroup, (price, index) => {
      alldaily = alldaily + parseFloat(price);
    });
    return alldaily.toFixed(2);
  }

  renderPersonalDetailsForm = () => {
    const { planName, planNumber, planDates } = this.state;
    let name, number, start, end;
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      name = planName;
      number = planNumber;
      start = planDates.startDate;
      end = planDates.endDate;
    } else {
      name = localStorage.getItem("name");
      number = localStorage.getItem("hcpNumber");
      start = localStorage.getItem("startDate");
      end = localStorage.getItem("endDate");
    }
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Personal Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ValidatedTextField
                required
                label="Name"
                onChange={this.handleNameChange}
                value={name}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatedTextField
                required
                label="HCP #"
                onChange={this.handleHCPNumberChange}
                value={number}
                helperText={"Used to determine HCP Number"}
                type="number"
              />
            </Grid>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid item xs={12}>
                <DatePicker
                  label="Budget Start Date"
                  value={start}
                  onChange={this.handleStartDateChange}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  required
                  id="startDate"
                  name="startDate"
                  format="D MMMM Y"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  margin="normal"
                  label="Budget End Date"
                  value={end}
                  onChange={this.handleEndDateChange}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  required
                  format="D MMMM Y"
                  onError={this.handleEndDateError}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  renderPlanCategories = () => {
    const { plan, hcpSupportGroups } = this.props;
    return hcpSupportGroups.map((group, index) => {
      return (
        <div key={index}>
          {index === 0 && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">{titleCase(group.name)}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction="column"
                  justify="space-around"
                  alignItems="stretch"
                >
                  {_.map(plan.hcpPlanCategories, (hcpPlanCategory, PCId) => {
                    if (hcpPlanCategory.hcpSupportCategory !== undefined) {
                      if (
                        hcpPlanCategory.hcpSupportCategory === CATEGORY_LEVEL_ID
                      ) {
                        let level = this.state.currentPlanLevel;
                        let SupportIG_level = parseInt(level) + 42;
                        let sup = {};
                        _.map(
                          this.state.hcpSupportItems[CATEGORY_LEVEL_ID],
                          (item) => {
                            if (item.hcpSupportItemGroup === SupportIG_level) {
                              if (item.unit !== "Income&Level") {
                                sup[item.name] = item.price;
                              } else {
                                sup["level"] = item.price;
                              }
                            }
                          }
                        );
                        return (
                          <Grid
                            item
                            container
                            justify="space-evenly"
                            alignItems="center"
                            xs={12}
                            spacing={0}
                            key={"level"}
                          >
                            <Grid container item xs={12} sm={6}>
                              <Grid container item>
                                <Grid
                                  item
                                  xs={12}
                                  sm={9}
                                >
                                  <Typography variant="h6" style={{fontSize:'1.1rem'}}>
                                    HCP-Level
                                  </Typography>
                                  {/* <div></div> */}
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={3}
                                >
                                  <select
                                    style={{ width: "100%" }}
                                    value={this.state.currentPlanLevel}
                                    onChange={(e) => {
                                      this.handleSupClick(
                                        "level",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                  </select>
                                </Grid>
                              </Grid>
                              <Grid container item>
                                <Grid item xs={12} sm={9}>
                                  <div>Daily home care package value</div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                  {this.calculateDailyIncome()}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item key={"sup"} xs={12} sm={6}>
                              {_.map(sup, (supitem, index) => {
                                if (index !== "level") {
                                  return (
                                    <Grid
                                      container
                                      alignItems="center"
                                      key={index}
                                    >
                                      <Grid item xs={12} sm={9}>
                                        <Checkbox
                                          id={"checkbox_1"}
                                          checked={
                                            this.state.levelItemGroup[index] > 0
                                          }
                                          onClick={(e) =>
                                            this.handleSupClick(index, supitem)
                                          }
                                        />
                                        {index}
                                      </Grid>
                                      <Grid item xs={12} sm={3}>
                                        $ <span>{supitem}</span>
                                      </Grid>
                                    </Grid>
                                  );
                                }
                              })}
                            </Grid>
                          </Grid>
                        );
                      }
                    }
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      );
    });
  };

  getPlanLevel = () => {
    const { plan } = this.props;
    let planId = plan.id;
    console.log("in getPlanLevel");
    let hcpPlanCategoryId = 0;
    let defaultLevelItemGroup = {
      currentPlanLevel: 1,
      levelItemGroup: {
        "Dement. / Conginit. / Vet supplement": 0,
        "EACHD Top Up Supplement": 0,
        "Enteral Feeding Supplement": 0,
        "Oxygen Supplement": 0,
        level: "24",
      },
    };
    _.map(plan.hcpPlanCategories, (hcpPlanCategory, PCId) => {
      if (hcpPlanCategory.hcpSupportCategory === CATEGORY_LEVEL_ID) {
        hcpPlanCategoryId = hcpPlanCategory.id;
      }
    });
    if (localStorage.getItem(LocalStorageKeys.ACCESS) != null) {
      api.Hcp_PlanItemGroups.list(planId, hcpPlanCategoryId).then(
        (responsePlanItemGroup) => {
          if (responsePlanItemGroup.data.length !== 0) {
            let hcpPlanItemGroupId = responsePlanItemGroup.data[0].id;
            api.Hcp_PlanItems.list(
              planId,
              hcpPlanCategoryId,
              hcpPlanItemGroupId
            ).then((responsePlanItems) => {
              console.log(responsePlanItems.data);
              if (responsePlanItems.data.length !== 0) {
                let levelItemGroup = {};
                let levelObj = responsePlanItems.data.filter(
                  (item) => item.name === "level"
                )[0];
                //eslint-disable-next-line
                responsePlanItems.data.map((item) => {
                  if (item.name !== "level") {
                    levelItemGroup[item.name] = parseFloat(item.priceActual);
                  }
                });
                levelItemGroup["level"] = this.state.testlevel[
                  parseInt(levelObj.priceActual) || 1
                ];

                if (levelObj !== undefined)
                  this.setState({
                    currentPlanLevel: parseInt(levelObj.priceActual) || 1,
                    levelItemGroup,
                  });
              }
            });
          } else this.setState(defaultLevelItemGroup);
        }
      );
    } else this.setState(defaultLevelItemGroup);
  };

  // when press the save button in Income && level
  handleLevelSave = async (updatePlan) => {
    let levelItemGroup = this.state.levelItemGroup;
    let hcpPlanItems = [];
    let hcpPlanItemsbyname = {};
    let hcpPlanCategoryId = 0;
    let start;
    let end;
    let personalDetailsBody = {};
    const { plan, onLevelSave } = this.props;
    const { planName, planNumber, planDates, hcpPlanCategories } = this.state;
    if (localStorage.getItem(LocalStorageKeys.ACCESS) == null) {
      console.log(this.state.planDates);
      start = localStorage.getItem("startDate");
      end = localStorage.getItem("endDate");
    } else {
      personalDetailsBody = {
        name: planName,
        startDate: dateToYMDString(planDates.startDate),
        endDate: dateToYMDString(planDates.endDate),
        hcpNumber: planNumber,
        hcpPlanCategories: _.values(plan.hcpPlanCategories),
      };
      start = dateToString(planDates.startDate);
      end = dateToString(planDates.endDate);
    }
    _.map(levelItemGroup, (item, index) => {
      hcpPlanItems.push({
        startDate: start,
        endDate: end,
        priceActual: index === "level" ? this.state.currentPlanLevel : item,
        name: index,
        allDay: true,
        frequency: "YEARLY",
      });
      hcpPlanItemsbyname[index] = {
        startDate: start,
        endDate: end,
        priceActual: index === "level" ? this.state.currentPlanLevel : item,
        name: index,
        allDay: true,
        frequency: "YEARLY",
      };
    });
    let hcpPlanItemGroups = {
      hcpSupportItemGroup: Group_LEVEL_ID,
      hcpPlanItems,
      nickname: "Income && level",
      name: "Income && level",
    };
    _.map(hcpPlanCategories, (PC) => {
      if (PC.hcpSupportCategory === CATEGORY_LEVEL_ID) {
        hcpPlanCategoryId = PC.id;
      }
    });
    const hcpPlanItemGroupData = {
      hcpPlanCategory: hcpPlanCategoryId,
      hcpSupportItemGroup: Group_LEVEL_ID,
      name: "Income & Level",
      nickname: "Income & Level",
      schedule_start_date: dateToString(start),
      schedule_end_date: dateToString(end),
    };
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      let planId = plan.id;

      await api.Hcp_PlanItemGroups.list(planId, hcpPlanCategoryId).then(
        (responsePlanItemGroup) => {
          console.log(responsePlanItemGroup.data);
          if (responsePlanItemGroup.data.length !== 0) {
            let hcpPlanItemGroupId = responsePlanItemGroup.data[0].id;
            console.log(
              "planid ",
              planId,
              " hcpPlanCategoryId ",
              hcpPlanCategoryId,
              " hcpPlanItemGroupId ",
              hcpPlanItemGroupId
            );
            api.Hcp_PlanItems.list(
              planId,
              hcpPlanCategoryId,
              hcpPlanItemGroupId
            ).then((responsePlanItems) => {
              console.log(responsePlanItems.data);
              for (let i = 0; i < responsePlanItems.data.length; i++) {
                console.log(
                  hcpPlanItemsbyname[responsePlanItems.data[i].name],
                  "levelsave func"
                );
                console.log("hcpPlanItemsbyname:::", responsePlanItems, hcpPlanItemsbyname)
                api.Hcp_PlanItems.update(
                  planId,
                  hcpPlanCategoryId,
                  hcpPlanItemGroupId,
                  responsePlanItems.data[i].id,
                  hcpPlanItemsbyname[responsePlanItems.data[i].name]
                ).then((response) => {
                  console.log("update item " + responsePlanItems.data[i].id);
                });
              }
            });
          } else {
            api.Hcp_PlanItemGroups.create(
              planId,
              hcpPlanCategoryId,
              hcpPlanItemGroupData
            ).then((response) => {
              let hcpPlanItemGroupId = response.data.id;
              for (let i = 0; i < hcpPlanItems.length; i++) {
                const hcpPlanItemData = {
                  planItemGroup: hcpPlanItemGroupId,
                  name: hcpPlanItems[i].name,
                  priceActual: hcpPlanItems[i].priceActual,
                  startDate: hcpPlanItems[i].startDate,
                  endDate: hcpPlanItems[i].endDate,
                  allDay: hcpPlanItems[i].allDay,
                  frequency: "YEARLY",
                  isPercentage: hcpPlanItems[i] || false,
                };
                console.log("hcp item data", hcpPlanItemData);
                api.Hcp_PlanItems.create(
                  planId,
                  hcpPlanCategoryId,
                  hcpPlanItemGroupId,
                  hcpPlanItemData
                ).then((response) => { });
              }
            });
          }
        }
      );
      if (updatePlan) {
        await api.Hcp_Plans.update(planId, personalDetailsBody).then((res) => { });
      }
    }

    let newPCs = {
      ...hcpPlanCategories[hcpPlanCategoryId],
      hcpPlanItemGroups: [hcpPlanItemGroups],
    };
    let newHcpPlanCategories = {
      ...hcpPlanCategories,
      [hcpPlanCategoryId]: newPCs,
    };
    console.log(newHcpPlanCategories);
    this.setState(newHcpPlanCategories, () => {
      localStorage.setItem(
        "hcpPlanCategories",
        JSON.stringify(newHcpPlanCategories)
      );
      onLevelSave();
    });
  };

  render = () => {
    const { classes, show } = this.props;
    return (
      <Paper className={classes.paper}>
        {show !== 'onlyCategories' && this.renderPersonalDetailsForm()}
        {show !== 'onlyPersonalDetails' && this.renderPlanCategories()}
        <Grid container justify="flex-end" className={classes.buttonMargin}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => this.handleLevelSave(true)}
            style={{ marginRight: "10px" }}
          >
            Save
          </Button>
        </Grid>
      </Paper>
    );
  };
}

export default withStyles(paperStyles)(PlanDetails);