import React, { Component } from "react";
import {
  Grid,
  // Card,
  // CardContent,
  // CardActions, TextField,
  Button,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import {calculatePlanItemCost} from "../budget/dashboard/hcp_BudgetDashboard";
import _ from "lodash";
// import EditIcon from "@material-ui/icons/Edit";
import ChangeInput from "../budget/dashboard/ChangeInput";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {
  Other_CATEGORY_ID,
  Other_Group_ID,
} from "../budget/dashboard/hcp_BudgetDashboard";
import { calculateAllocated } from "../budget/dashboard/hcp_BudgetDashboard";
const styles = {
  panel: { width: "100%" },
};

class HcpBudgetCategoryCard extends Component {
  state = {
    open: false,
    selectedIndex: 0,
    selectedname: null,
    hasNewSub: false,
    checked: [0],
    selectedItemId: 0,
  };

  findItemGroupName(hcpPlanItemGroups, id) {
    for (let i = 0; i < hcpPlanItemGroups.length; i++) {
      if (hcpPlanItemGroups[i].hcpSupportItemGroup === id) {
        return hcpPlanItemGroups[i].name;
      }
    }
    return null;
  }

  addNewItemGroups(supportItems) {
    this.setState({ open: true });
    console.log(supportItems);
  }
  handleClose = () => {
    this.setState({ open: false });
  };

  handleListItemClick(item) {
    const currentIndex = this.state.checked.indexOf(item.id);
    const newChecked = [...this.state.checked];
    console.log("Click Item");
    console.log(item);
    if (currentIndex === -1) {
      newChecked.push(item.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log(newChecked);
    if (newChecked.length > 2) {
      this.setState({
        checked: newChecked,
        selectedItemId: Other_Group_ID,
        selectedname: "New Category",
      });
    } else {
      this.setState({
        checked: newChecked,
        selectedItemId: item.id,
        selectedname: item.name,
      });
    }
  }

  render() {
    const {
      supportcategory,
      plancategory,
      deleteCategory,
      deleteItemGroup,
      eid,
      supportitems,
      saveCategoryName,
      saveItemGroupName,
      // fixed,
      // handleadd,
      handleAddSubCategory,
      handleManagement,
      handleExpanded,
      expanded,
      isEditable,
      handleCategoryExpand,
      categoryExpanded,
    } = this.props;
    const id = supportcategory.id;
    const name = supportcategory.name;
    let toggleccordian = expanded === eid;
    let handletoggleccordian = handleExpanded;
    return (
      <Accordion expanded={toggleccordian} onChange={handletoggleccordian(eid)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="secondary" />}>
          <ChangeInput
            fontSize="0.875rem"
            iconColor="#5f68ea"
            inputWidth="400px"
            showSize="30"
            amount="30"
            type="number"
            cid={plancategory.id}
            ctype="Category"
            handleOk={saveCategoryName}
            hasdelete={plancategory.hcpSupportCategory === Other_CATEGORY_ID}
            deleteItemGroup={deleteItemGroup}
            deleteCategory={deleteCategory}
            value={plancategory ? plancategory.name : name}
            name={name}
            isEditable={isEditable}
            totalamount={calculateAllocated(
              plancategory.hcpPlanItemGroups
            ).toLocaleString()}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            direction="column"
            justify="space-around"
            alignItems="stretch"
          >
            {_.map(
              plancategory.hcpPlanItemGroups,
              (hcpPlanItemGroup, index) => {
                return (
                  <Grid key={index} style={{ padding: "8px" }}>
                    <div>
                      <ChangeInput
                        fontSize="0.875rem"
                        iconColor="#5f68ea"
                        inputWidth="600px"
                        showSize="70"
                        amount="50"
                        type="number"
                        hasdelete={true}
                        deleteCategory={deleteCategory}
                        deleteItemGroup={deleteItemGroup}
                        planId={plancategory.plan}
                        cid={plancategory.id}
                        index={index}
                        itemGroup={hcpPlanItemGroup.id}
                        ctype="ItemGroup"
                        handleOk={saveItemGroupName}
                        value={hcpPlanItemGroup.name}
                        name={name}
                        isEditable={isEditable}
                      />
                    </div>
                  </Grid>
                );
                // }
              }
            )}
            <Grid style={{ marginTop: "5px" }}>
              <Button
                onClick={() => {
                  console.log("get available " + id);
                  this.addNewItemGroups(supportitems[id]);
                }}
              >
                Add New
              </Button>
              <Button
                style={{ float: "right" }}
                onClick={() => this.props.openSupports()}
              >
                View Plans
              </Button>

              <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.open}
              >
                <DialogTitle id="customized-dialog-title">
                  Available Categories
                </DialogTitle>
                <DialogContent>
                  <List>
                    {_.map(supportitems[id], (item) => {
                      const labelId = `checkbox-list-label-${item.id}`;

                      return (
                        <ListItem
                          key={item.id}
                          onClick={() => this.handleListItemClick(item)}
                        >
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={
                                this.state.checked.indexOf(item.id) !== -1
                              }
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={item.name} />
                        </ListItem>
                      );
                    })}
                  </List>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose}>Cancel</Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      if (this.state.checked.length > 1) {
                        handleAddSubCategory(
                          plancategory.id,
                          this.state.checked,
                          this.state.selectedname
                        );
                        this.setState({ open: false });
                      }
                    }}
                  >
                    Add
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default withStyles(styles)(HcpBudgetCategoryCard);
