// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BudgetDashboard_closeButton__3qQcJ {\n  float: right;\n}\n\n.BudgetDashboard_dialogTitle__29XRQ {\n  padding: 0;\n  height: 32px;\n}\n", ""]);
// Exports
exports.locals = {
	"closeButton": "BudgetDashboard_closeButton__3qQcJ",
	"dialogTitle": "BudgetDashboard_dialogTitle__29XRQ"
};
module.exports = exports;
