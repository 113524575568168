import React from "react";
import Accordion from "@material-ui/core/Accordion/index";
import AccordionSummary from "@material-ui/core/AccordionSummary/index";
import AccordionDetails from "@material-ui/core/AccordionDetails/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid/index";
import Paper from "@material-ui/core/Paper/index";
import Typography from "@material-ui/core/Typography/index";
import withStyles from "@material-ui/core/styles/withStyles";
import ValidatedTextField from "../../common/ValidatedTextField";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import api from "../../api";
import Button from "@material-ui/core/Button/index";
import _, { isEmpty } from "lodash";
import { connect } from "react-redux";
import { LocalStorageKeys } from "../../common/constants";
import InputAdornment from "@material-ui/core/InputAdornment/index";
import HcpPlanDetails from "../dashboard/hcp_PlanDetails";
import { Container } from "@material-ui/core";

export const CATEGORY_Care_Manage_ID = 1;
export const CATEGORY_Package_Manage_ID = 2;
export const CATEGORY_LEVEL_ID = 11;
export const Group_LEVEL_ID = 43;
export const Other_CATEGORY_ID = 7;
export const Other_Group_ID = 48;

function dateToString(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}
function getNextYearFromDate(date) {
  const nextYear = new Date(date);
  nextYear.setFullYear(date.getFullYear() + 1);
  return nextYear;
}
function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

const styles = {
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: 16,
  },
  buttons: {
    display: "flex",
    justify: "flex-end",
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  sectionTotalColor: {
    color: "grey",
  },
  addNewButton: {
    marginTop: 16,
  },
  outerPaper: {
    margin: 24,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 16,
  },
  buttonMargin: {
    marginTop: 10,
  },
};

//test
function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
  };
}

const PLAN_CATEGORIES = "hcpPlanCategories";

let moneyRegex = new RegExp(/^-?\d*\.?\d{0,2}$/);
// let postcodeRegex = new RegExp(/^\d{0,4}$/);
let nameRegex = new RegExp(/^[a-zA-Z ]+$/);
let HCPNumberRegex = new RegExp(/^\d{0,9}$/);
// let BirthYearRegex = new RegExp(/^\d{0,4}$/);

const today = new Date();

// return date exactly a year from today's date
function getYearFromToday() {
  const nextYear = new Date();
  nextYear.setFullYear(today.getFullYear() + 1);
  return nextYear;
}

MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth;

class FormPersonalDetails extends React.Component {
  state = {
    hcpSupportGroups: [],
    level: 1,
    levelItemGroup: {},
    name: "",
    startDate: null,
    hcpNumber: "",
    endDate: null,
    hcpPlanCategories: {},
    showErrors: false,
    errors: {},
    planId: null,
    allPlans: [],
    updatePlans: [],
    expanded: false,
  };
  componentDidMount() {
    api.Hcp_SupportGroups.all()
      .then((response) => {
        this.loadState(response.data);
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.name !== prevState.name
      // || this.state.hcpNumber !== prevState.hcpNumber
    ) {
      const errors = this.validate();
      this.setState({ errors: errors });
    }
  }

  loadState = async (hcpSupportGroups) => {
    console.log("in load state");
    this.setState({ hcpSupportGroups: [...hcpSupportGroups] });
    let hcpPlanCategories = {};
    let allPlans = [];
    let updatePlans = [];
    // let levelItemGroup = {};
    let participantId;
    let name;
    let hcpNumber;
    let startDate;
    let endDate;
    const access = localStorage.getItem(LocalStorageKeys.ACCESS);
    if (access != null) {
      await api.Participants.currentUser().then((response) => {
        participantId = response.data.id;
        name = response.data.firstName + " " + response.data.lastName;
      });
      // TODO: refactor into reusable function
      await api.Hcp_Plans.list().then((response) => {
        // if no existing plan
        if (response.data.length === 0) {
          _.map(hcpSupportGroups, (hcpSupportGroup) => {
            _.map(
              hcpSupportGroup.hcpSupportCategories,
              (hcpSupportCategory) => {
                hcpPlanCategories[hcpSupportCategory.id] = {
                  budget: 0,
                };
              }
            );
          });
          startDate = today;
          endDate = getYearFromToday();
        } else {
          const plans = response.data;
          // for (var ind = 0; ind < plans.length; ind++) {
          //   planDates[plans[ind].id] = {
          //     startDate: plans[ind].startDate,
          //     endDate: plans[ind].endDate,
          //   };
          // }

          console.log(plans);
          _.map(plans, (plan, index) => {
            // this.setState({ planId: plan.id });
            // hcpNumber = plan.hcpNumber;
            // startDate = new Date(plan.startDate);
            // endDate = new Date(plan.endDate);
            allPlans[index] = {
              ...plan,
            };
            updatePlans[index] = {
              id: plan.id,
              name: plan.name,
              hcpNumber: plan.hcpNumber,
              startDate: plan.startDate,
              endDate: plan.endDate,
              hcpPlanCategories: plan.hcpPlanCategories,
            };
            // _.map(plan.hcpPlanCategories, (planCategory) => {
            //   hcpPlanCategories[planCategory.hcpSupportCategory] = {
            //     ...planCategory,
            //   };
            // });
          });
        }
      });
    } else {
      const cachedName = localStorage.getItem("name");
      const cachedStartDate = localStorage.getItem("startDate");
      const cachedHCPNumber = localStorage.getItem("hcpNumber");
      const cachedEndDate = localStorage.getItem("endDate");
      let levelItems = [
        "Dement. / Conginit. / Vet supplement",
        "EACHD Top Up Supplement",
        "Enteral Feeding Supplement",
        "Oxygen Supplement",
        "level",
      ];
      let levelPlanItemGroup = {
        hcpSupportItemGroup: Group_LEVEL_ID,
        name: "Income & Level",
        nickname: "Income & Level",
        hcpPlanItems: [],
      };
      levelItems.forEach((item) => {
        levelPlanItemGroup.hcpPlanItems.push({
          allDay: true,
          endDate: cachedEndDate,
          name: item,
          priceActual: item !== "level" ? "0" : "1",
          startDate: cachedStartDate,
        });
      });

      // if (cachedhcpPlanCategories === null) {
      // if(true){
      _.map(hcpSupportGroups, (hcpSupportGroup) => {
        _.map(hcpSupportGroup.hcpSupportCategories, (hcpSupportCategory) => {
          hcpPlanCategories[hcpSupportCategory.id] = {
            id: hcpSupportCategory.id,
            budget: 0,
            hcpPlanItemGroups:
              hcpSupportCategory.id !== CATEGORY_LEVEL_ID
                ? []
                : [
                  {
                    ...levelPlanItemGroup,
                    hcpPlanCategory: hcpSupportCategory.id,
                  },
                ],
            hcpSupportCategory: hcpSupportCategory.id,
            name: hcpSupportCategory.name,
          };
        });
      });
      // } else {
      //   hcpPlanCategories = cachedhcpPlanCategories;
      // }
      name = cachedName ? cachedName : "";
      startDate = cachedStartDate ? new Date(cachedStartDate) : today;
      hcpNumber = cachedHCPNumber ? parseInt(cachedHCPNumber) : "";
      endDate = cachedEndDate ? new Date(cachedEndDate) : getYearFromToday();
    }

    this.setState({
      hcpPlanCategories,
      participantId,
      name,
      hcpNumber,
      startDate,
      endDate,
      allPlans,
      updatePlans,
    });
  };

  onLevelSave = () => this.loadState(this.state.hcpSupportGroups);

  // handle money input
  handleChange = async (
    e,
    hcpSupportCategoryId,
    indexPlan,
    indexPlanCategory
  ) => {
    // check if input string is the correct format for money
    if (e.target.value === "" || moneyRegex.test(e.target.value)) {
      // set new amount
      let new_amount;
      if (e.target.value === "") {
        new_amount = 0;
      } else {
        new_amount = parseFloat(e.target.value);
      }
      const updatePlans = this.state.updatePlans;
      const hcpPlanCategories = this.state.updatePlans[indexPlan]
        .hcpPlanCategories;
      await this.setState({
        updatePlans: {
          ...updatePlans,
          [indexPlan]: {
            ...updatePlans[indexPlan],
            hcpPlanCategories: {
              ...hcpPlanCategories,
              [indexPlanCategory]: {
                ...hcpPlanCategories[indexPlanCategory],
                budget: new_amount,
              },
            },
          },
        },
      });
    }
  };

  // handle offline Name
  handleOfflineNameChange = (input) => (e) => {
    if (e.target.value === "" || nameRegex.test(e.target.value)) {
      this.setState({ [input]: e.target.value });
    }
  };
  // handle offline NDIS NUmber
  handleOfflineHCPNumberChange = (input) => (e) => {
    if (e.target.value === "" || HCPNumberRegex.test(e.target.value)) {
      this.setState({ [input]: e.target.value });
    }
  };
  // handle date change
  handleOfflineStartDateChange = (e) => {
    let date = dateToString(new Date(e));
    let endDate = dateToString(getNextYearFromDate(new Date(e)));
    this.setState({ startDate: date, endDate: endDate });
  };
  handleOfflineEndDateChange = (e) => {
    let date = new Date(e);
    if (date < new Date(this.state.startDate)) return;
    let endDate = dateToString(date);
    this.setState({ endDate: endDate });
  };

  // go to dashboard if online
  handleNext = (event, planId, index) => {
    event.preventDefault();
    // const errors = this.validate();
    // if (Object.keys(errors).length === 0) {
    if (this.props.currentUser != null) {
      if (planId === null) {
        const body = {
          name: this.state.updatePlans[index].name,
          startDate: this.state.updatePlans[index].startDate,
          endDate: this.state.updatePlans[index].endDate,
          hcpNumber: this.state.updatePlans[index].hcpNumber,
        };

        localStorage.setItem(
          "startDate",
          JSON.stringify(this.state.updatePlans[index].startDate)
        );
        localStorage.setItem(
          "endDate",
          JSON.stringify(this.state.updatePlans[index].endDate)
        );

        const categories = _.map(
          this.state.updatePlans[index].hcpPlanCategories,
          (planCategory, hcpSupportCategory) => {
            return {
              ...planCategory,
              hcpSupportCategory: hcpSupportCategory,
              budget: planCategory.budget,
            };
          }
        );
        body.hcpSupportCategories = categories;
        api.Hcp_Plans.create(body).then(() => {
          this.props.history.push({
            pathname: "/hcp/budget/dashboard",
            state: {
              planId: planId,
              // levelItemGroup: this.state.levelItemGroup,
            },
          });
        });
      } else {
        console.log("...id..." + planId);
        localStorage.setItem(
          PLAN_CATEGORIES,
          JSON.stringify(this.state.updatePlans[index].hcpPlanCategories)
        );
        this.props.history.push({
          pathname: "/hcp/budget/dashboard",
          state: {
            planId: planId,
            // levelItemGroup: this.state.levelItemGroup,
          },
        });
      }
    } else {
      localStorage.setItem("name", this.state.name);
      localStorage.setItem("startDate", this.state.startDate);
      localStorage.setItem("endDate", this.state.endDate);
      localStorage.setItem("hcpNumber", this.state.hcpNumber);
      localStorage.setItem(
        PLAN_CATEGORIES,
        JSON.stringify(this.state.hcpPlanCategories)
      );
      this.props.history.push({
        pathname: "/hcp/budget/dashboard",
        state: {
          planId: planId,
          // levelItemGroup: this.state.levelItemGroup,
        },
      });
    }
    // } else {
    //   console.log(errors);
    //   this.setState({ errors: errors });
    //   this.setState({ showErrors: true });
    // }
  };

  // go to dashboard if offline
  handleNextOffline = () => {
    const errors = this.validate();
    if (Object.keys(errors).length === 0) {
      localStorage.setItem("name", this.state.name);
      localStorage.setItem("startDate", this.state.startDate);
      localStorage.setItem("endDate", this.state.endDate);
      localStorage.setItem("hcpNumber", this.state.hcpNumber);
      localStorage.setItem(
        PLAN_CATEGORIES,
        JSON.stringify(this.state.hcpPlanCategories)
      );
      this.props.history.push("/hcp/budget/dashboard");
    } else {
      console.log(errors);
      this.setState({ errors: errors });
      this.setState({ showErrors: true });
    }
  };

  // add a new plan if online
  handleAddNew = () => {
    this.props.history.push("/hcp/budget/add");
  };

  // check errors
  validate = () => {
    let errors = {};

    if (this.state.name === null || isEmpty(this.state.name)) {
      errors.name = "Please Input Name";
    }

    if (
      this.state.hcpNumber === null || this.state.hcpNumber !== undefined
        ? this.state.hcpNumber.toString().length !== 9
        : true
    ) {
      errors.hcpNumber = "Invalid HCP Number";
    }
    return errors;
  };

  // render add new button
  renderAddNew = () => {
    const { classes } = this.props;
    return (
      <div className={classes.addNewButton}>
        <Grid container justify="flex-start">
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.handleAddNew}
          >
            Add New +
          </Button>
        </Grid>
      </div>
    );
  };

  // if click delete under a plan
  handleDeletePlan = (planid) => {
    console.log("Try delete plan " + planid);
    // if()
    api.Hcp_Plans.delete(planid);
    let newPlans = [];
    _.map(this.state.allPlans, (plan) => {
      if (plan.id !== planid) {
        newPlans.push(plan);
      }
    });
    this.setState({
      allPlans: newPlans,
    });
  };

  // render a plan with details
  renderPlan(planId, index) {
    return (
      <HcpPlanDetails
        plan={this.state.updatePlans.filter((plan) => plan.id === planId)[0]}
        hcpSupportGroups={this.state.hcpSupportGroups}
        onLevelSave={() => this.onLevelSave()}
      />
    );
  }

  // render existing plans
  renderAllPlans() {
    // const { allPlans } = this.state;
    const { classes } = this.props;
    return this.state.allPlans.map((plan, index) => {
      return (
        <Paper key={index} style={{marginBottom:10}}>
          <Container style={{ display: 'flex', padding:10 }}>
            <Typography variant="h6" style={{ width: "100%" }}>
              {plan.name}
            </Typography>
            <Grid container justify="flex-end">
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={(event) => this.handleNext(event, plan.id, index)}
              >
                View
              </Button>
            </Grid>
          </Container>
        </Paper>
      );
    });
  }

  // render plan offline
  renderOfflinePersonalDetailsForm = () => {
    const { classes } = this.props;
    const { errors, showErrors } = this.state;
    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Personal Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ValidatedTextField
                className={classes.name}
                required
                label="Name"
                onChange={this.handleOfflineNameChange("name")}
                value={this.state.name}
                type="text"
                error={showErrors}
                errortext={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatedTextField
                className={classes.number}
                required
                label="HCP #"
                onChange={this.handleOfflineHCPNumberChange("hcpNumber")}
                value={this.state.hcpNumber}
                helperText={"Used to determine HCP Number"}
                type="number"
                error={showErrors}
                errortext={errors.hcpNumber}
              />
            </Grid>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid item xs={12}>
                <DatePicker
                  label="Budget Start Date"
                  value={this.state.startDate}
                  onChange={this.handleOfflineStartDateChange}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  required
                  id="startDate"
                  name="startDate"
                  format="D MMMM Y"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  margin="normal"
                  label="Budget End Date"
                  value={this.state.endDate}
                  onChange={this.handleOfflineEndDateChange}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  required
                  format="D MMMM Y"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  renderOfflinePlanCategories = () => {
    // const { planCategories } = this.state;
    const { classes } = this.props;
    return this.state.hcpSupportGroups.map((group, index) => {
      return (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{titleCase(group.name)}</Typography>
            <Typography
              variant="h6"
              className={this.props.sectionTotalColor}
              inline="true"
            >
              {" "}
              {/*&nbsp;|&nbsp;Total: ${this.state[group.name]}*/}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              {group.supportCategories.map((category, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Typography variant="body1">
                      {titleCase(category.name)}
                    </Typography>
                    <ValidatedTextField
                      className={classes.number}
                      onChange={(event) =>
                        this.handleChangeOffline(event, category.id)
                      }
                      value={
                        this.state.planCategories[category.id]
                          ? this.state.planCategories[category.id].budget
                          : ""
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  // render page
  render() {
    const { classes } = this.props;
    const access = localStorage.getItem(LocalStorageKeys.ACCESS);
    if (access === null) {
      console.log(this.state.hcpSupportGroups);
      return (
        <Paper className={classes.outerPaper}>
          {access === null ? this.renderOfflinePersonalDetailsForm() : ""}
          {/*{access === null ? this.renderOfflinehcpPlanCategories() : ""}*/}
          <Grid container justify="flex-end" className={classes.buttonMargin}>
            <Button
              // className={classes.button}
              color="primary"
              variant="contained"
              onClick={this.handleNextOffline}
            >
              View
            </Button>
          </Grid>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.outerPaper}>
          {this.renderAllPlans()}
          {this.renderAddNew()}
        </Paper>
      );
    }
  }
}

export default connect(mapStateToProps)(
  withStyles(styles)(FormPersonalDetails)
);
