import React from "react";
import Accordion from "@material-ui/core/Accordion/index";
import AccordionSummary from "@material-ui/core/AccordionSummary/index";
import AccordionDetails from "@material-ui/core/AccordionDetails/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid/index";
import Paper from "@material-ui/core/Paper/index";
import Typography from "@material-ui/core/Typography/index";
import withStyles from "@material-ui/core/styles/withStyles";
import ValidatedTextField from "../../common/ValidatedTextField";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import api from "../../api";
import Button from "@material-ui/core/Button/index";
import _ from "lodash";
import { connect } from "react-redux";
import {
  CATEGORY_LEVEL_ID,
  Group_LEVEL_ID,
} from "../dashboard/hcp_PlanDetails";
import moment from "moment";

function dateToYMDString(date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}
function dateToString(date) {
  return moment(date).format();
}

const styles = {
  paper: {
    marginTop: 16,
    marginLeft: "auto",
    marginRight: "auto",
    padding: 16,
  },
  buttons: {
    display: "flex",
    justify: "flex-end",
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  sectionTotalColor: {
    color: "grey",
  },
  buttonMargin: {
    marginTop: 10,
  },
};

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
  };
}

// let moneyRegex = new RegExp(/^-?\d*\.?\d{0,2}$/);
let nameRegex = new RegExp(/^[a-zA-Z ]+$/);
let HCPNumberRegex = new RegExp(/^\d{0,9}$/);

const today = new Date();

// return date exactly a year from today's date
function getYearFromToday() {
  const nextYear = new Date();
  nextYear.setFullYear(today.getFullYear() + 1);
  return nextYear;
}
const exceptThisSymbols = ["e", "E", "+", "-", "."];

MomentUtils.prototype.getStartOfMonth = MomentUtils.prototype.startOfMonth;

class BudgetAddForm extends React.Component {
  state = {
    hcpSupportGroups: [],
    hcpSupportCategories: [],
    name: null,
    startDate: null,
    hcpNumber: "",
    endDate: null,
    showErrors: false,
    errors: {},
    flag: false,
    hcpPlanCategories: {},
  };

  componentDidMount() {
    api.Hcp_SupportGroups.all()
      .then((response) => {
        this.loadState(response.data);
      })
      .catch((err) => console.log(err));
  }

  loadState = async (hcpSupportGroups) => {
    this.setState({ hcpSupportGroups: [...hcpSupportGroups] });
    _.map(hcpSupportGroups, (hcpSupportGroup) => {
      _.map(hcpSupportGroup.hcpSupportCategories, (hcpSupportCategory) => {
        this.state.hcpPlanCategories[hcpSupportCategory.id] = {
          hcpSupportCategory: hcpSupportCategory.id,
          budget: 0,
          name: hcpSupportCategory.name,
          hcpPlanItemGroups: [],
        };
      });
    });
    this.setState({
      startDate: today,
      endDate: getYearFromToday(),
    });
  };

  // handle name input
  handleNameChange = (input) => (e) => {
    if (nameRegex.test(e.target.value)) {
      this.setState({ [input]: e.target.value });
    }
  };

  // handle start date input
  handleStartDateChange = (input) => (date) => {
    let start = date.toDate();
    let end = new Date(date);
    end.setFullYear(start.getFullYear() + 1);

    this.setState({
      [input]: start,
      endDate: end,
    });
  };

  // handle date input
  handleEndDateChange = (input) => (date) => {
    this.setState({ [input]: date.toDate() });
  };

  // handle HCPNumber date input
  handleHCPNumberChange = (input) => (e) => {
    if (HCPNumberRegex.test(e.target.value)) {
      this.setState({ [input]: e.target.value });
    }
  };

  // handle add new plan
  handleAdd = () => {
    const errors = this.validate();
    if (Object.keys(errors).length === 0) {
      if (this.props.currentUser != null) {
        const body = {
          name: this.state.name,
          hcpNumber: this.state.hcpNumber,
          startDate: dateToYMDString(this.state.startDate),
          endDate: dateToYMDString(this.state.endDate),
        };
        const hcpCategories = _.map(
          this.state.hcpPlanCategories,
          (hcpPlanCategory, hcpSupportCategory) => {
            return {
              ...hcpPlanCategory,
              hcpSupportCategory: hcpSupportCategory,
              budget: hcpPlanCategory.budget,
              hcpPlanItemGroups: [],
            };
          }
        );
        // body.hcpSupportCategories = hcpCategories;
        body.hcpSupportCategories = hcpCategories;
        console.log(body);
        api.Hcp_Plans.create(body).then((response) => {
          console.log("response", response);
          let planId = response.data.id;
          let levelPlanCategory = response.data.hcpPlanCategories.filter(
            (pc) => pc.hcpSupportCategory === CATEGORY_LEVEL_ID
          )[0];
          let startDate = response.data.startDate;
          let endDate = response.data.endDate;
          let levelItems = [
            "Dement. / Conginit. / Vet supplement",
            "EACHD Top Up Supplement",
            "Enteral Feeding Supplement",
            "Oxygen Supplement",
            "level",
          ];

          const hcpPlanItemGroupData = {
            hcpPlanCategory: levelPlanCategory.id,
            hcpSupportItemGroup: Group_LEVEL_ID,
            name: "Income & Level",
            nickname: "Income & Level",
            scheduleStartDate: dateToString(startDate),
            scheduleEndDate: dateToString(endDate),
          };
          api.Hcp_PlanItemGroups.create(
            planId,
            levelPlanCategory.id,
            hcpPlanItemGroupData
          ).then((response) => {
            let hcpPlanItemGroupId = response.data.id;
            console.log(dateToString(new Date()));
            levelItems.forEach((item) => {
              let hcpPlanItemData = {
                planItemGroup: hcpPlanItemGroupId,
                name: item,
                priceActual: item !== "level" ? "0" : "1",
                startDate: dateToString(startDate),
                endDate: dateToString(endDate),
                allDay: true,
                frequency: "YEARLY",
                isPercentage: false,
              };
              api.Hcp_PlanItems.create(
                planId,
                levelPlanCategory.id,
                hcpPlanItemGroupId,
                hcpPlanItemData
              ).then(() => {});
            });
            window.location.href = "/hcp/budget/edit";
          });
        });
      } else {
        localStorage.setItem("name", this.state.name);
        localStorage.setItem("startDate", this.state.startDate);
        localStorage.setItem("hcpNumber", this.state.hcpNumber);
        localStorage.setItem("endDate", this.state.endDate);
      }
    } else {
      console.log(errors);
      this.setState({ errors: errors });
      this.setState({ showErrors: true });
    }
  };

  //test error
  validate = () => {
    let errors = {};

    if (this.state.name == null) {
      errors.name = "Invalid Name";
    }
    if (
      this.state.hcpNumber == null ||
      this.state.hcpNumber.toString().length !== 9
    ) {
      errors.hcpNumber = "Invalid HCP Number";
    }

    return errors;
  };

  // render a plan
  renderPlanDetailsForm = () => {
    // const { classes } = this.props;
    const { errors, showErrors } = this.state;
    return (
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Plan Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ValidatedTextField
                label="Name"
                required
                fullWidth
                onChange={this.handleNameChange("name")}
                type="text"
                error={showErrors}
                errortext={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <ValidatedTextField
                label="HCP Number"
                required
                fullWidth
                value={this.state.hcpNumber}
                onChange={this.handleHCPNumberChange("hcpNumber")}
                type="number"
                error={showErrors}
                errortext={errors.hcpNumber}
                onKeyDown={(e) =>
                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                }
              />
            </Grid>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid item xs={12}>
                <DatePicker
                  label="Budget Start Date"
                  value={this.state.startDate}
                  onChange={this.handleStartDateChange("startDate")}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  required
                  fullWidth
                  format="D MMMM Y"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  margin="normal"
                  label="Budget End Date"
                  value={this.state.endDate}
                  onChange={this.handleEndDateChange("endDate")}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  required
                  fullWidth
                  format="D MMMM Y"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  // render page
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper}>
        {this.renderPlanDetailsForm()}
        <Grid container justify="flex-end" className={classes.buttonMargin}>
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.handleAdd}
          >
            Add
          </Button>
        </Grid>
      </Paper>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(BudgetAddForm));
