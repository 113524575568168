//UI for plan item addition
//The UI provides 4 fields for users to modify the support item:
// 1. a costomised support item name, meaning users are able to name the item whatever they want
// 2. usage frequency, how often they use the item
// 3. the number of items users consume each time
// 4. the customised price, price for users may differ for varied reasons
// each field will be filled with a default value if users never costomised them before
// an annual cost will be calculated based on the fields
// once saved, a new item will be added into plan items list
import React, { useCallback, useEffect, useState } from "react";
import { DialogContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";
import moment from "moment";
import {
  addMonths,
  addDays,
  differenceInDays,
  setDate,
  isSameMonth,
  addWeeks,
} from "date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import PlanItemCalendarDialog from "./hcp_PlanItemCalendarDialog";
import PlanItemDeleteDialog from "./PlanItemDeleteDialog";
import { PLAN_ITEM_GROUP_EDIT_ALL } from "./SupportItemDialog";
import { usePrevious } from "../../common/customHooks";
import DeleteIcon from "@material-ui/icons/Delete";
import PlanDates from "./hcp_PlanDates";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const WEEK_MONTHLY = "Monthly";
const WEEK_FORTNIGHTLY = "Fortnightly";
const IMMEDIATELY = "Immediately";
export const FORTNIGHTLY = "FORTNIGHTLY";
export const MONTHLY = "MONTHLY";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  blackButton: {
    backgroundColor: "black",
    color: "white",
  },
}));
function dateToStringYMD(date) {
  return moment(date).format("YYYY-MM-DD");
}
export default function ManageEditor(props) {
  const {
    manageItemGroup,
    categoryId,
    total,
    planCategory,
    onDeletePlanItem,
    onDeletePlanItemGroup,
    onEditPlanItem,
    // onPlanDatesChange,
    startDate,
    endDate,
    available,
  } = props;

  // for calendar <START>
  const DELETE_ALL = true;
  const [openPlanItemDialog, setOpenPlanItemDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteMode, setDeleteMode] = useState(-1);
  const [selectedPlanItem, setSelectedPlanItem] = useState(null);
  const [showAlert, setShowAlert] = useState(null);

  function handleSelectEvent(info) {
    setSelectedPlanItem(info.event.extendedProps.hcpPlanItem);
    setOpenPlanItemDialog(true);
  }

  // delete a item or delete all
  const handleDelete = useCallback(() => {
    if (
      deleteMode !== DELETE_ALL &&
      manageItemGroup[0].hcpPlanItems.length > 1
    ) {
      onDeletePlanItem(selectedPlanItem);
    } else {
      onDeletePlanItemGroup(manageItemGroup[0]);
    }
    handleCloseDialog();
    // eslint-disable-next-line
  }, [
    deleteMode,
    // eslint-disable-next-line
    manageItemGroup[0],
    selectedPlanItem,
    onDeletePlanItem,
    onDeletePlanItemGroup,
  ]);

  const handleEdit = useCallback(() => {
    onEditPlanItem({
      editAll: !PLAN_ITEM_GROUP_EDIT_ALL,
      hcpPlanItem: selectedPlanItem,
    });
  }, [selectedPlanItem, onEditPlanItem]);

  function handleCloseDialog() {
    setSelectedPlanItem(null);
    setDeleteMode(-1);
    setOpenPlanItemDialog(false);
    setOpenDeleteDialog(false);
  }
  function handleDeletePlanItem(hcpPlanItem) {
    setDeleteMode(!DELETE_ALL);
    setOpenDeleteDialog(true);
  }
  function handleDeletePlanItemGroup() {
    setDeleteMode(DELETE_ALL);
    setOpenDeleteDialog(true);
  }
  // for calendar <END>

  const itemname =
    planCategory.hcpSupportCategory === 1
      ? "Administration, finance management and governance"
      : "Care Assessment, Planning, Coordination";
  const hcpSupportItem = [];

  const defaultname = itemname;
  const [planDates, setPlanDates] = useState({
    start: new Date(
      manageItemGroup[0]?.scheduleStartDate ||
        startDate ||
        localStorage.getItem("startDate")
    ),
    end: new Date(
      manageItemGroup[0]?.scheduleEndDate ||
        endDate ||
        localStorage.getItem("endDate")
    ),
  });
  const classes = useStyles();

  function onClickBack() {
    props.back();
  }

  function onClickSave(values, categoryId, events) {
    console.log(
      "values",
      values,
      " categroyid ",
      categoryId,
      " events ",
      events
    );
    const hcpPlanItemGroup = createNewPlanItemGroup(values, categoryId, events);
    console.log("hcpPlanItemGroup", hcpPlanItemGroup);
    if (!values.priceActual) {
      setShowAlert("Total Amount Cannot Be Zero");
      return;
    } else if (values.priceActual > available + price) {
      setShowAlert("You do not have Funds available");
      return;
    }
    props.save(hcpPlanItemGroup);
    props.redirectSupports();
  }
  function onAlert() {
    return (
      <Dialog
        open={showAlert}
        onClose={() => setShowAlert(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {showAlert}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAlert(null)} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    const eventsLength = newEvents().length;
    switch (name) {
      case "percent": {
        if (percentage) {
          const price = Math.round(value * total) / 100;
          setValues((values) => ({
            ...values,
            percent: value,
            priceActual: price,
            amount: price / eventsLength,
          }));
        }
        break;
      }

      case "priceActual": {
        const price = Math.round(value * eventsLength);
        setValues((values) => ({
          ...values,
          [name]: price,
          percent: (price * 100) / total,
          amount: value,
        }));
        break;
      }

      case "frequencyPerYear": {
        setValues((values) => ({
          ...values,
          [name]: value,
        }));

        break;
      }
      default:
        break;
    }
  }
  function roundToTwoDecimals(num) {
    return Math.round(num * 100) / 100;
  }
  function createNewPlanItemGroup(values, categoryId, events) {
    let id = 34 + 3 * planCategory.hcpSupportCategory;
    console.log("id", id);
    if (values.frequencyPerYear === IMMEDIATELY) {
      id = id + 2;
    } else if (values.frequencyPerYear === MONTHLY) {
      id = id + 1;
    }
    console.log("id", id);
    const eventsLength = events.length;
    const hcpPlanItems = events.map((event) => {
      return {
        startDate: event.start,
        endDate: event.end,
        priceActual: roundToTwoDecimals(values.priceActual / eventsLength),
        name: values.name,
        allDay: true,
        frequency: values.frequencyPerYear,
        isPercentage: percentage,
      };
    });
    return {
      hcpSupportItemGroup: id,
      hcpPlanItems,
      nickname: values.name,
      name: defaultname,
      scheduleStartDate: planDates.start,
      scheduleEndDate: planDates.end,
    };
  }

  function initialiseValues(hcpSupportItem) {
    let name, frequency, price;
    let amount = 0,
      percent = 0;
    if (defaultname) {
      name = defaultname;
    }
    if (manageItemGroup[0]) {
      let totalPrice = 0;
      _.map(
        manageItemGroup[0].hcpPlanItems,
        (item) => (totalPrice += parseFloat(item.priceActual))
      );
      price = totalPrice;
      frequency = manageItemGroup[0].hcpPlanItems[0].frequency;
      amount = parseInt(manageItemGroup[0].hcpPlanItems[0].priceActual);
      percent = (price * 100) / total;
    } else {
      frequency = FORTNIGHTLY;
      price = 0;
    }
    return { name, frequency, amount, price, percent };
  }

  //enumerate the unit of each support item
  const usageFrequency = "frequencyPerYear";
  const itemPrice = "priceActual";
  const [percentage, isPercentage] = useState(
    manageItemGroup[0]?.hcpPlanItems[0]?.isPercentage || false
  );
  const { name, frequency, amount, price, percent } = initialiseValues(
    hcpSupportItem
  );
  const [values, setValues] = useState({
    name: name,
    priceActual: price,
    amount: amount,
    frequencyPerYear: frequency,
    percent: percent,
  });
  const prevValues = usePrevious(values);
  useEffect(() => {
    if (
      prevValues &&
      prevValues?.frequencyPerYear !== values.frequencyPerYear
    ) {
      setValues((values) => ({
        ...values,
        priceActual: percentage
          ? Math.round(values.percent * total) / 100
          : Math.round(values.amount * newEvents().length),
      }));
    }
    // eslint-disable-next-line
  }, [values]);
  useEffect(() => {
    const eventsLength = newEvents().length;
    console.log(percentage);
    if (percentage) {
      const amount = price / eventsLength;
      setValues({
        ...values,
        amount: amount,
      });
    } else {
      const price = amount * eventsLength;
      console.log(eventsLength);
      setValues({
        ...values,
        priceActual: price,
        percent: (price * 100) / total,
      });
    }
    //eslint-disable-next-line
  }, [planDates]);
  const itemStartDates = [new Date(planDates.start)];

  const newEvents = () => {
    const createEvent = ({ title, date }) => {
      return {
        title,
        start: date,
        end: date,
        allDay: true,
      };
    };
    // if (
    //   manageItemGroup[0]?.hcpPlanItems &&
    //   dateToStringYMD(manageItemGroup[0].scheduleStartDate) ===
    //     dateToStringYMD(planDates.start) &&
    //   dateToStringYMD(manageItemGroup[0].scheduleEndDate) ===
    //     dateToStringYMD(planDates.end)
    // ) {
    //   const newDates = _.map(manageItemGroup[0].hcpPlanItems, (item) => {
    //     return createEvent({ title: item.name, date: item.startDate });
    //   });
    //   return newDates;
    // }

    if (values.frequencyPerYear === IMMEDIATELY) {
      return _.map(itemStartDates, (startDate) => {
        return createEvent({ title: values.name, date: planDates.start });
      });
    } else if (values.frequencyPerYear === MONTHLY) {
      let currentDate = new Date(planDates.start);
      const days = _.map(
        _.groupBy(itemStartDates, (itemStartDate) => itemStartDate.getDate()),
        (value, key) => parseInt(key)
      );
      let eventDates = [];
      while (currentDate < planDates.end) {
        const newDates = [];
        const dateClone = new Date(currentDate);
        let nextMonthDate;
        //eslint-disable-next-line
        _.forEach(days, (day) => {
          const newDate = setDate(dateClone, day);
          if (isSameMonth(dateClone, newDate)) {
            newDates.push(
              createEvent({
                title: values.name,
                date: newDate,
              })
            );
            nextMonthDate = addMonths(currentDate, 1);
          } else {
            newDates.push(
              createEvent({
                title: values.name,
                date: dateClone,
              })
            );
            nextMonthDate = addDays(
              addMonths(currentDate, 1),
              differenceInDays(newDate, dateClone)
            );
          }
        });
        eventDates = eventDates.concat(newDates);
        currentDate = new Date(nextMonthDate);
      }
      return eventDates;
    } else if (values.frequencyPerYear === FORTNIGHTLY) {
      const startDate = new Date(planDates.start);

      const eventDates = [];
      // add first week's days
      let currentDate = addWeeks(new Date(startDate), 2);

      // add the weeks between first and last week of the plan
      const endDate = new Date(planDates.end);
      while (currentDate < endDate) {
        eventDates.push(
          createEvent({
            title: values.name,
            date: new Date(currentDate),
          })
        );
        currentDate = addWeeks(currentDate, 2);
      }

      // clean up dates outside of plan
      _.remove(eventDates, (eventDate) => {
        return eventDate.date < startDate || eventDate.date > endDate;
      });
      return eventDates;
    }
  };

  const calculateCost = () => {
    const numberOfItems = newEvents().length;
    const cost = values.priceActual / numberOfItems;
    const result = (Math.round(cost * 100) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
    });
    return result;
  };

  const renderFrequencySelector = () => {
    return (
      <>
        <Typography variant={"body1"} align={"left"}>
          How often do you pay the management fee?
        </Typography>
        <FormControl margin={"normal"} required>
          <InputLabel htmlFor={usageFrequency}>Payment Frequency</InputLabel>
          <Select
            value={values.frequencyPerYear}
            autoWidth
            onChange={(e) => {
              handleChange(e);
            }}
            inputProps={{
              name: usageFrequency,
              id: usageFrequency,
            }}
          >
            <MenuItem value={FORTNIGHTLY} key={WEEK_FORTNIGHTLY}>
              {WEEK_FORTNIGHTLY}
            </MenuItem>
            ,
            <MenuItem value={MONTHLY} key={WEEK_MONTHLY}>
              {WEEK_MONTHLY}
            </MenuItem>
            ,
          </Select>
          <FormHelperText>
            Please select the frequency from the dropdown box
          </FormHelperText>
        </FormControl>
      </>
    );
  };
  const handleChangePlanDates = (planDates) => {
    setPlanDates({ ...planDates });
    // onPlanDatesChange(planDates);
  };

  return (
    <>
      <DialogContent>
        {console.log(values)}
        {onAlert()}
        <Grid container spacing={4}>
          <Grid item md={5}>
            <form className={classes.form}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl margin={"dense"} required fullWidth>
                    {itemname}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {renderFrequencySelector()}
                </Grid>
                <PlanDates
                  planStartDate={planDates.start}
                  planEndDate={planDates.end}
                  changePlanDates={handleChangePlanDates}
                />

                <Grid item xs={12}>
                  <Typography cvariant={"body1"} align={"left"}>
                    Do you pay as a percentage of current plan's total amount ？
                  </Typography>
                  <RadioGroup
                    value={percentage ? "percentage" : "amount"}
                    aria-label="gender"
                    name="customized-radios"
                    onChange={(e) => isPercentage(!percentage)}
                  >
                    <FormControlLabel
                      value="amount"
                      control={<Radio />}
                      label="No"
                    />
                    <FormControlLabel
                      value="percentage"
                      control={<Radio />}
                      label="Yes"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Typography cvariant={"body1"} align={"left"}>
                    How much does each payment cost?
                  </Typography>
                  {percentage ? (
                    <FormControl margin={"normal"} required>
                      <InputLabel shrink htmlFor={"percent"}>
                        percentage
                      </InputLabel>
                      <Input
                        id={"percent"}
                        name={"percent"}
                        autoFocus
                        value={
                          values.percent ? values.percent.toLocaleString() : ""
                        }
                        onChange={(e) => handleChange(e)}
                        endAdornment={
                          <InputAdornment position="end">
                            % of ${Number(total).toLocaleString()}
                          </InputAdornment>
                        }
                        type="number"
                      />
                    </FormControl>
                  ) : (
                    <FormControl margin={"normal"} required>
                      <InputLabel shrink htmlFor={itemPrice}>
                        Amount
                      </InputLabel>
                      <Input
                        id={itemPrice}
                        name={itemPrice}
                        autoComplete={itemPrice}
                        autoFocus
                        value={
                          values.amount ? values.amount.toLocaleString() : ""
                        }
                        onChange={(e) => handleChange(e)}
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        type="number"
                      />
                    </FormControl>
                  )}
                  {percentage ? (
                    <Typography cvariant={"body1"} align={"left"}>
                      {`Total: $${(
                        Math.round(values.priceActual * 100) / 100
                      ).toLocaleString()} ÷ ${
                        newEvents().length
                      }= $${calculateCost()}`}
                    </Typography>
                  ) : (
                    <Typography cvariant={"body1"} align={"left"}>
                      {`Total: $${values.priceActual.toLocaleString()} ÷ ${
                        newEvents().length
                      }= $${calculateCost()}`}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid container item md={7} alignItems="flex-end">
            <FullCalendar
              initialView="dayGridMonth"
              plugins={[dayGridPlugin]}
              events={newEvents()}
              height={"auto"}
              editable={true}
              selectMirror={true}
              selectable={true}
              fixedWeekCount={false}
              eventClick={handleSelectEvent}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {manageItemGroup[0] && (
          <Button onClick={handleDeletePlanItemGroup}>
            <DeleteIcon />
          </Button>
        )}
        <Button variant={"text"} onClick={onClickBack}>
          Return
        </Button>
        <Button
          variant={"text"}
          onClick={() => onClickSave(values, categoryId, newEvents())}
        >
          Save
        </Button>
      </DialogActions>
      {openPlanItemDialog === true && selectedPlanItem != null && (
        <PlanItemCalendarDialog
          open={openPlanItemDialog && selectedPlanItem != null}
          hcpPlanItem={selectedPlanItem}
          onClose={handleCloseDialog}
          onDelete={handleDeletePlanItem}
          onEdit={handleEdit}
        />
      )}
      {openDeleteDialog === true && deleteMode !== -1 && (
        <PlanItemDeleteDialog
          open={openDeleteDialog === true && deleteMode !== -1}
          onClose={handleCloseDialog}
          onDelete={handleDelete}
        />
      )}
    </>
  );
}
