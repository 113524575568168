import React from "react";
import Accordion from "@material-ui/core/Accordion/index";
import AccordionSummary from "@material-ui/core/AccordionSummary/index";
import AccordionDetails from "@material-ui/core/AccordionDetails/index";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid/index";
import ValidatedTextField from "../../common/ValidatedTextField";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography/index";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
import api from "../../api";
import { LocalStorageKeys } from "../../common/constants";
import moment from "moment";
import Button from "@material-ui/core/Button/index";
import Paper from "@material-ui/core/Paper/index";
import withStyles from "@material-ui/core/styles/withStyles";
import { Box, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

export const CATEGORY_LEVEL_ID = 11;
export const Group_LEVEL_ID = 43;

const paperStyles = {
  paper: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: 8,
  },
  buttonMargin: {
    marginTop: 10,
  },
};

function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}
function dateToString(date) {
  return moment(date).format();
}
function dateToYMDString(date) {
  return moment(date).format("YYYY-MM-DD");
}

function dateToDMYString(date) {
  return moment(date).format("DD/MM/YY");
}

function getNextYearFromDate(date) {
  const nextYear = new Date(date);
  nextYear.setFullYear(date.getFullYear() + 1);
  return nextYear;
}

let nameRegex = new RegExp(/^[a-zA-Z ]+$/);
let HCPNumberRegex = new RegExp(/^\d{0,9}$/);

class PlanDetails extends React.Component {
  state = {
    hcpSupportItems: [],
    currentPlanLevel: 1,
    levelItemGroup: {},
    testlevel: {},
    planName: "",
    planNumber: "",
    planDates: {
      startDate: "",
      endDate: "",
    },
    hcpPlanCategories: {},
    available: 0,
    updateFunds: "",
  };

  async componentDidMount() {
    this.loadState();
    this.getPlanLevel();
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      planDates: nextProps.changePlanDate,
      available: nextProps.available,
    });
  }
  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.plan, prevProps.plan)) this.loadState();
    if (this.props.save) {
      this.handleLevelSave(true);
      this.props.setSave(false);
    }
  }

  loadState = () => {
    const { plan, changePlanDate, available } = this.props;
    let hcpPlanCategories = {};
    api.Hcp_SupportItems.get({
      hcpsupportCategoryID: CATEGORY_LEVEL_ID,
    }).then((response) => {
      //eslint-disable-next-line
      this.state.hcpSupportItems[CATEGORY_LEVEL_ID] = response.data;

      _.map(response.data, (item, index) => {
        if (item.unit === "Income&Level") {
          //eslint-disable-next-line
          this.state.testlevel[item.name.substr(-1)] = parseFloat(
            item.price
          ).toFixed(2);
        }
      });
    });
    if (Array.isArray(plan.hcpPlanCategories)) {
      _.map(plan.hcpPlanCategories, (planCategory) => {
        hcpPlanCategories[planCategory.hcpSupportCategory] = {
          ...planCategory,
        };
      });
    } else hcpPlanCategories = plan.hcpPlanCategories;

    let updateFunds = 0;
    _.map(hcpPlanCategories, (planCategory) => {
      if (
        planCategory.budget !== 0 &&
        Number(planCategory.budget).toString() !== "NaN"
      ) {
        updateFunds += parseFloat(planCategory.budget);
      }
    });

    this.setState({
      planName: plan.name,
      planNumber: plan.hcpNumber,
      planDates: {
        startDate: changePlanDate.startDate,
        endDate: changePlanDate.endDate,
      },
      hcpPlanCategories,
      available,
      updateFunds: updateFunds || "",
    });
  };

  handleNameChange = (e) => {
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      if (e.target.value === "" || nameRegex.test(e.target.value)) {
        let planName = e.target.value;
        this.setState({ planName });
      }
    } else {
      localStorage.setItem("name", e.target.value);
    }
  };
  handleHCPNumberChange = (e) => {
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      if (
        e.target.value === "" ||
        HCPNumberRegex.test(e.target.value) ||
        e.target.value === "e"
      ) {
        let planNumber = e.target.value;
        this.setState({ planNumber });
      }
    } else {
      localStorage.setItem("hcpNumber", e.target.value);
    }
  };

  handleUnspendFunds = (e) => {
    let updateFunds = e.target.value;
    if (e.target.value === "" || Number(updateFunds).toString() !== "NaN") {
      this.setState({ updateFunds });
    }
  };

  // if a supplement is ticked or level changed, store it in levelitemgroup
  handleSupClick = (index, supitem) => {
    let level = this.state.currentPlanLevel;
    let money = supitem;
    let SupportIG_level = parseInt(level) + 42;
    if (index === "level") {
      money = this.state.testlevel[supitem];
      let levelItemGroup = this.state.levelItemGroup;
      _.map(this.state.hcpSupportItems[CATEGORY_LEVEL_ID], (item) => {
        if (item.hcpSupportItemGroup === SupportIG_level) {
          if (item.unit !== "Income&Level") {
            if (levelItemGroup[item.name > 0]) {
              levelItemGroup[item.name] = item.price;
            }
          } else {
            levelItemGroup["level"] = money;
          }
        }
      });
      this.setState({
        levelItemGroup: levelItemGroup,
        currentPlanLevel: supitem,
      });
    } else {
      console.log(index + " " + supitem);
      if (
        this.state.levelItemGroup[index] !== undefined &&
        this.state.levelItemGroup[index] > 0
      ) {
        money = 0;
      }
      this.setState({
        levelItemGroup: {
          ...this.state.levelItemGroup,
          [index]: money,
        },
      });
    }
  };

  // get daily income according to itemGroup level
  calculateDailyIncome() {
    let alldaily = 0;
    _.map(this.state.levelItemGroup, (price, index) => {
      alldaily = alldaily + parseFloat(price);
    });
    return alldaily.toFixed(2);
  }

  renderPersonalDetailsForm = () => {
    const { planName, planNumber, planDates } = this.state;
    let name, number, start, end;
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      name = planName;
      number = planNumber;
      start = planDates.startDate;
      end = planDates.endDate;
    } else {
      name = localStorage.getItem("name");
      number = localStorage.getItem("hcpNumber");
      start = localStorage.getItem("startDate");
      end = localStorage.getItem("endDate");
    }
    return (
      <Table>
        <colgroup>
          <col width="5%" />
          <col width="27%" />
          <col width="20%" />
          <col width="14%" />
          <col width="14%" />
          <col width="5%" />
          <col width="15%" />
        </colgroup>
        <TableBody>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <ValidatedTextField
                required
                label="Name"
                onChange={this.handleNameChange}
                value={name}
                type="text"
              />
            </TableCell>
            <TableCell>
              <ValidatedTextField
                required
                label="HCP #"
                onChange={this.handleHCPNumberChange}
                value={number}
                type="number"
              />
            </TableCell>
            <TableCell>
              <ValidatedTextField
                label="UnspentFunds"
                onChange={this.handleUnspendFunds}
                value={this.state.updateFunds}
                type="number"
                paceholder="0"
                InputLabelProps={{ style: { fontSize: "14px" } }}
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  renderPlanCategories = () => {
    const { plan, hcpSupportGroups } = this.props;
    return hcpSupportGroups.map((group, index) => {
      return (
        <Box key={index}>
          {index === 0 && (
            <Table>
              <colgroup>
                <col width="6%" />
                <col width="27%" />
                <col width="10%" />
                <col width="57%" />
              </colgroup>
              <TableBody>
                {_.map(plan.hcpPlanCategories, (hcpPlanCategory, PCId) => {
                  if (hcpPlanCategory.hcpSupportCategory !== undefined) {
                    if (
                      hcpPlanCategory.hcpSupportCategory === CATEGORY_LEVEL_ID
                    ) {
                      let level = this.state.currentPlanLevel;
                      let SupportIG_level = parseInt(level) + 42;
                      let sup = {};
                      _.map(
                        this.state.hcpSupportItems[CATEGORY_LEVEL_ID],
                        (item) => {
                          if (item.hcpSupportItemGroup === SupportIG_level) {
                            if (item.unit !== "Income&Level") {
                              sup[item.name] = item.price;
                            } else {
                              sup["level"] = item.price;
                            }
                          }
                        }
                      );
                      return (
                        <TableRow key={PCId}>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "1.1rem" }}
                            >
                              HCP-Level
                            </Typography>
                            <div>Daily home care package value</div>
                          </TableCell>
                          <TableCell>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "1.1rem" }}
                            >
                              <select
                                style={{ width: "100%" }}
                                value={this.state.currentPlanLevel}
                                onChange={(e) => {
                                  this.handleSupClick("level", e.target.value);
                                }}
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                              </select>
                            </Typography>
                            <div>{this.calculateDailyIncome()}</div>
                          </TableCell>
                          <TableCell>
                            {_.map(sup, (supitem, index) => {
                              if (index !== "level") {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div>
                                      <Checkbox
                                        id={"checkbox_1"}
                                        checked={
                                          this.state.levelItemGroup[index] > 0
                                        }
                                        onClick={(e) =>
                                          this.handleSupClick(index, supitem)
                                        }
                                      />
                                      {index}
                                    </div>
                                    <div>$ {supitem}</div>
                                  </div>
                                );
                              }
                            })}
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }
                })}
              </TableBody>
            </Table>
          )}
        </Box>
      );
    });
  };

  getPlanLevel = () => {
    const { plan } = this.props;
    let planId = plan.id;
    console.log("in getPlanLevel");
    let hcpPlanCategoryId = 0;
    let defaultLevelItemGroup = {
      currentPlanLevel: 1,
      levelItemGroup: {
        "Dement. / Conginit. / Vet supplement": 0,
        "EACHD Top Up Supplement": 0,
        "Enteral Feeding Supplement": 0,
        "Oxygen Supplement": 0,
        level: "24",
      },
    };
    _.map(plan.hcpPlanCategories, (hcpPlanCategory, PCId) => {
      if (hcpPlanCategory.hcpSupportCategory === CATEGORY_LEVEL_ID) {
        hcpPlanCategoryId = hcpPlanCategory.id;
      }
    });
    if (localStorage.getItem(LocalStorageKeys.ACCESS) != null) {
      api.Hcp_PlanItemGroups.list(planId, hcpPlanCategoryId).then(
        (responsePlanItemGroup) => {
          if (responsePlanItemGroup.data.length !== 0) {
            let hcpPlanItemGroupId = responsePlanItemGroup.data[0].id;
            api.Hcp_PlanItems.list(
              planId,
              hcpPlanCategoryId,
              hcpPlanItemGroupId
            ).then((responsePlanItems) => {
              console.log(responsePlanItems.data);
              if (responsePlanItems.data.length !== 0) {
                let levelItemGroup = {};
                let levelObj = responsePlanItems.data.filter(
                  (item) => item.name === "level"
                )[0];
                //eslint-disable-next-line
                responsePlanItems.data.map((item) => {
                  if (item.name !== "level") {
                    levelItemGroup[item.name] = parseFloat(item.priceActual);
                  }
                });
                api.Hcp_SupportItems.get({
                  hcpsupportCategoryID: CATEGORY_LEVEL_ID,
                }).then((response) => {
                  //eslint-disable-next-line
                  this.state.hcpSupportItems[CATEGORY_LEVEL_ID] = response.data;

                  _.map(response.data, (item, index) => {
                    if (item.unit === "Income&Level") {
                      //eslint-disable-next-line
                      this.state.testlevel[item.name.substr(-1)] = parseFloat(
                        item.price
                      ).toFixed(2);
                    }
                  });
                });
                levelItemGroup["level"] = this.state.testlevel[
                  parseInt(levelObj.priceActual) || 1
                ];

                if (levelObj !== undefined)
                  this.setState({
                    currentPlanLevel: parseInt(levelObj.priceActual) || 1,
                    levelItemGroup,
                  });
              }
            });
          } else this.setState(defaultLevelItemGroup);
        }
      );
    } else {
      console.log("plan", plan);
      let responsePlanItemGroups =
        plan.hcpPlanCategories[CATEGORY_LEVEL_ID].hcpPlanItemGroups;
      if (responsePlanItemGroups.length !== 0) {
        let responsePlanItems = responsePlanItemGroups[0].hcpPlanItems;
        console.log("responsePlanItemGroups::", responsePlanItemGroups);
        let levelItemGroup = {};
        console.log("responsePlanItems::", responsePlanItems);
        let levelObj = responsePlanItems.filter(
          (item) => item.name === "level"
        )[0];
        console.log("levelObj::::::", levelObj);
        responsePlanItems.map((item) => {
          if (item.name !== "level") {
            levelItemGroup[item.name] = parseFloat(item.priceActual);
          }
        });
        api.Hcp_SupportItems.get({
          hcpsupportCategoryID: CATEGORY_LEVEL_ID,
        }).then((response) => {
          //eslint-disable-next-line
          this.state.hcpSupportItems[CATEGORY_LEVEL_ID] = response.data;

          _.map(response.data, (item, index) => {
            if (item.unit === "Income&Level") {
              //eslint-disable-next-line
              this.state.testlevel[item.name.substr(-1)] = parseFloat(
                item.price
              ).toFixed(2);
            }
          });
          levelItemGroup["level"] = this.state.testlevel[
            parseInt(levelObj.priceActual) || 1
          ];
          if (levelObj !== undefined)
            this.setState({
              currentPlanLevel: parseInt(levelObj.priceActual) || 1,
              levelItemGroup,
            });
        });
      } else this.setState(defaultLevelItemGroup);
    }
  };

  // when press the save button in Income && level
  handleLevelSave = async (updatePlan) => {
    let levelItemGroup = this.state.levelItemGroup;
    let hcpPlanItems = [];
    let hcpPlanItemsbyname = {};
    let hcpPlanCategoryId = 0;
    let start;
    let end;
    let personalDetailsBody = {};
    const { plan, onLevelSave, handleBudgetSave } = this.props;
    const { planName, planNumber, planDates, hcpPlanCategories } = this.state;
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      personalDetailsBody = {
        name: planName,
        startDate: dateToYMDString(planDates.startDate),
        endDate: dateToYMDString(planDates.endDate),
        hcpNumber: planNumber,
        hcpPlanCategories: _.values(plan.hcpPlanCategories),
      };
    }
    start = dateToString(planDates.startDate);
    end = dateToString(planDates.endDate);
    _.map(levelItemGroup, (item, index) => {
      hcpPlanItems.push({
        startDate: start,
        endDate: end,
        priceActual: index === "level" ? this.state.currentPlanLevel : item,
        name: index,
        allDay: true,
        frequency: "YEARLY",
      });
      hcpPlanItemsbyname[index] = {
        startDate: start,
        endDate: end,
        priceActual: index === "level" ? this.state.currentPlanLevel : item,
        name: index,
        allDay: true,
        frequency: "YEARLY",
      };
    });
    let hcpPlanItemGroups = {
      hcpSupportItemGroup: Group_LEVEL_ID,
      hcpPlanItems,
      nickname: "Income && level",
      name: "Income && level",
    };
    _.map(hcpPlanCategories, (PC) => {
      if (PC.hcpSupportCategory === CATEGORY_LEVEL_ID) {
        hcpPlanCategoryId = PC.id;
      }
    });
    const hcpPlanItemGroupData = {
      hcpPlanCategory: hcpPlanCategoryId,
      hcpSupportItemGroup: Group_LEVEL_ID,
      name: "Income & Level",
      nickname: "Income & Level",
      schedule_start_date: dateToString(start),
      schedule_end_date: dateToString(end),
    };
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      let planId = plan.id;

      await api.Hcp_PlanItemGroups.list(planId, hcpPlanCategoryId).then(
        (responsePlanItemGroup) => {
          console.log(responsePlanItemGroup.data);
          if (responsePlanItemGroup.data.length !== 0) {
            let hcpPlanItemGroupId = responsePlanItemGroup.data[0].id;
            console.log(
              "planid ",
              planId,
              " hcpPlanCategoryId ",
              hcpPlanCategoryId,
              " hcpPlanItemGroupId ",
              hcpPlanItemGroupId
            );
            api.Hcp_PlanItems.list(
              planId,
              hcpPlanCategoryId,
              hcpPlanItemGroupId
            ).then((responsePlanItems) => {
              console.log(responsePlanItems.data);
              for (let i = 0; i < responsePlanItems.data.length; i++) {
                console.log(
                  hcpPlanItemsbyname[responsePlanItems.data[i].name],
                  "levelsave func"
                );
                console.log(
                  "hcpPlanItemsbyname:::",
                  responsePlanItems,
                  hcpPlanItemsbyname
                );
                api.Hcp_PlanItems.update(
                  planId,
                  hcpPlanCategoryId,
                  hcpPlanItemGroupId,
                  responsePlanItems.data[i].id,
                  hcpPlanItemsbyname[responsePlanItems.data[i].name]
                ).then((response) => {
                  console.log("update item " + responsePlanItems.data[i].id);
                });
              }
            });
          } else {
            api.Hcp_PlanItemGroups.create(
              planId,
              hcpPlanCategoryId,
              hcpPlanItemGroupData
            ).then((response) => {
              let hcpPlanItemGroupId = response.data.id;
              for (let i = 0; i < hcpPlanItems.length; i++) {
                const hcpPlanItemData = {
                  planItemGroup: hcpPlanItemGroupId,
                  name: hcpPlanItems[i].name,
                  priceActual: hcpPlanItems[i].priceActual,
                  startDate: hcpPlanItems[i].startDate,
                  endDate: hcpPlanItems[i].endDate,
                  allDay: hcpPlanItems[i].allDay,
                  frequency: "YEARLY",
                  isPercentage: hcpPlanItems[i] || false,
                };
                console.log("hcp item data", hcpPlanItemData);
                api.Hcp_PlanItems.create(
                  planId,
                  hcpPlanCategoryId,
                  hcpPlanItemGroupId,
                  hcpPlanItemData
                ).then((response) => {});
              }
            });
          }
        }
      );
      if (updatePlan) {
        await api.Hcp_Plans.update(
          planId,
          personalDetailsBody
        ).then((res) => {});
      }
    } else {
      localStorage.setItem("startDate", start);
      localStorage.setItem("endDate", end);
    }

    let newPCs = {
      ...hcpPlanCategories[hcpPlanCategoryId],
      hcpPlanItemGroups: [hcpPlanItemGroups],
    };
    let newHcpPlanCategories = {
      ...hcpPlanCategories,
      [hcpPlanCategoryId]: newPCs,
    };
    if (localStorage.getItem(LocalStorageKeys.ACCESS) !== null) {
      handleBudgetSave(this.state.updateFunds ? this.state.updateFunds : 0);
    } else {
      newHcpPlanCategories[11].budget = Number(
        this.state.updateFunds || 0
      ).toFixed(2);
    }
    console.log(newHcpPlanCategories);
    this.setState(newHcpPlanCategories, () => {
      localStorage.setItem(
        "hcpPlanCategories",
        JSON.stringify(newHcpPlanCategories)
      );
      onLevelSave();
    });
  };

  render = () => {
    const { classes, show } = this.props;
    return (
      <>
        {show !== "onlyCategories" && this.renderPersonalDetailsForm()}
        {show !== "onlyPersonalDetails" && this.renderPlanCategories()}
      </>
    );
  };
}

export default withStyles(paperStyles)(PlanDetails);
