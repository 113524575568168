import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import { NAV_BAR_HEIGHT } from "../common/theme";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  home: {
    height: `calc(100vh - ${NAV_BAR_HEIGHT}px)`,
  },
  color: {
    backgroundColor: theme.palette.primary.dark,
  },
  image: {
    backgroundImage:
      "url(https://media-exp1.licdn.com/dms/image/C4E1BAQGf1GBBAGHQzQ/company-background_10000/0/1549434514892?e=2159024400&v=beta&t=r1T_vNMLf-SrSvlFkT_WDG31JTfL967gViGnaomUgfA)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  planPageSelector: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  budgetPageSelector: {
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  pageSelector: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
}));

export default function Home_index() {
  const classes = useStyles();
  return (
    <Grid container component="main" className={classes.home}>
      <CssBaseline />
      <Grid item xs={12} className={classes.image}>
        <Grid container justify="center">
          <Grid container spacing={4} style={{ margin: 0 }}>
            {/* welcome text */}
            <Grid item xs={12} className={classes.color}>
              <Typography
                variant="h4"
                align="center"
                bold="true"
                style={{ color: "white" }}
              >
                Welcome to Capital Guardian Budget Planner.
                <br />I want to use:
              </Typography>
            </Grid>

            {/*page navigation buttons */}
            <Grid item xs={12} style={{ paddingTop: "120px" }}>
              <Grid
                container
                alignItems="center"
                justify="space-evenly"
                className={classes.cardGrid}
              >
                <Grid item>
                  <Card
                    raised
                    onClick={() => (window.location.href = "/hcp/budget/edit")}
                  >
                    <CardActionArea
                      className={classNames(
                        classes.planPageSelector,
                        classes.pageSelector
                      )}
                    >
                      <CardContent>
                        <Typography variant="h6" align="center" color="inherit">
                          Home Care Package Budget
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>

                <Grid item>
                  <Card
                    raised
                    onClick={() => (window.location.href = "/ndis/home")}
                  >
                    <CardActionArea
                      className={classNames(
                        classes.budgetPageSelector,
                        classes.pageSelector
                      )}
                    >
                      <CardContent>
                        <Typography variant="h6" align="center" color="inherit">
                          NDIS Budget
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
