import React, { Component } from "react";
import {
  Grid,
  // Card,
  // CardContent,
  // CardActions, TextField,
  Button,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Table,
  TableBody,
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import {calculatePlanItemCost} from "../budget/dashboard/hcp_BudgetDashboard";
import _ from "lodash";
// import EditIcon from "@material-ui/icons/Edit";
import ChangeInput from "../budget/dashboard/ChangeInput";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import moment from "moment";
import { calculateTotalCost } from "../budget/dashboard/hcp_BudgetDashboard";
import EditIcon from "@material-ui/icons/Edit";
import {
  Other_CATEGORY_ID,
  Other_Group_ID,
} from "../budget/dashboard/hcp_BudgetDashboard";
import { calculateAllocated } from "../budget/dashboard/hcp_BudgetDashboard";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
const styles = {
  panel: { width: "100%" },
};

function dateToDMYString(date) {
  return moment(date).format("DD/MM/YY");
}

class HcpBudgetCategoryCard extends Component {
  state = {
    open: false,
  };

  render() {
    const {
      supportcategory,
      plancategory,
      deleteCategory,
      deleteItemGroup,
      eid,
      supportitems,
      saveCategoryName,
      saveItemGroupName,
      // fixed,
      // handleadd,
      handleAddSubCategory,
      handleManagement,
      handleExpanded,
      expanded,
      isEditable,
      handleCategoryExpand,
      categoryExpanded,
    } = this.props;
    let frequency = "";
    try {
      frequency = plancategory.hcpPlanItemGroups[0].hcpPlanItems[0].frequency;
    } catch (e) {}
    const id = supportcategory.id;
    const name = supportcategory.name;
    let toggleccordian = expanded === eid;
    let handletoggleccordian = handleExpanded;
    // return null;
    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ padding: "7px" }} size="small">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => this.setState({ open: !this.state.open })}
            >
              {this.state.open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {name}
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right">
            $
            {calculateAllocated(
              plancategory.hcpPlanItemGroups
            ).toLocaleString()}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <Table>
                <colgroup>
                  <col width="5%" />
                  <col width="27%" />
                  <col width="20%" />
                  <col width="14%" />
                  <col width="14%" />
                  <col width="5%" />
                  <col width="15%" />
                </colgroup>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ textOverflow: "ellipsis" }}
                    >
                      {plancategory.hcpSupportCategory === 1
                        ? "Administration, finance management and governance"
                        : "Care Assessment, Planning, Coordination"}
                    </TableCell>
                    <TableCell style={{ textOverflow: "ellipsis" }}>
                      {frequency}
                    </TableCell>
                    <TableCell>
                      {frequency &&
                        dateToDMYString(
                          plancategory.hcpPlanItemGroups[0]?.scheduleStartDate
                        )}
                    </TableCell>
                    <TableCell>
                      {frequency &&
                        dateToDMYString(
                          plancategory.hcpPlanItemGroups[0]?.scheduleEndDate
                        )}
                    </TableCell>
                    <TableCell>
                      <EditIcon
                        fontSize="small"
                        style={{ cursor: "pointer" }}
                        color="secondary"
                        onClick={() => handleManagement(plancategory.id)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      $
                      {calculateAllocated(
                        plancategory.hcpPlanItemGroups
                      ).toLocaleString()}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
}

export default withStyles(styles)(HcpBudgetCategoryCard);
