import React from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { NAV_BAR_HEIGHT } from "./theme";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import connect from "react-redux/es/connect/connect";
import { LocalStorageKeys } from "./constants";

// import { fade } from '@material-ui/core/styles';

const navBarStyles = makeStyles((theme)=>({
  navBar: {
    height: NAV_BAR_HEIGHT,

  },
  grow: {
    flexGrow: 1,
  },
  visuablePart: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
  };
}

function Hcp_NavBar(props) {
  function handleSignOut() {
    localStorage.removeItem(LocalStorageKeys.ACCESS);
    localStorage.removeItem(LocalStorageKeys.REFRESH);

    window.location.replace("/");
  }
  const classes = navBarStyles();
  return (
    <AppBar position="static" >
      <Toolbar className={classes.navBar}>
        <Typography variant="h5" color="inherit" style={{marginRight:"13px"}} className={classes.visuablePart}>

          <div> Capital Guardian</div>
          <div style={{fontSize:15,fontStyle:"italic"}}>Home Care Package Budget</div>

        </Typography>
        <div className={classes.grow}>
          <Button href="/" color="inherit" style={{marginRight:"13px"}}>
            Home
          </Button>
          {props.currentUser && (
            <Button href="/profile" color="inherit" style={{marginRight:"13px"}}>
              Profile
            </Button>
          )}
          {props.currentUser ? (
            <Button onClick={handleSignOut} color="inherit">
              Sign out
            </Button>
          ) : (
            <Button href="/signin" color="inherit">
              Login
            </Button>
          )}
        </div>
        {props.currentUser ? (
          <Typography className={classes.visuablePart}>Welcome, {props.currentUser.firstName}</Typography>
        ) : (
//          <Typography> </Typography>
          <Typography className={classes.visuablePart}>
            Register/Login to information and access from other
            devices
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default connect(mapStateToProps)(Hcp_NavBar);
