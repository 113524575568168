import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  makeStyles,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { DARK_BLUE, LIGHT_BLUE } from "../../common/theme";
import HcpBudgetCategoryRow from "../../DoughnutChart/HcpBudgetCategoryRow";
import moment from "moment";
import styles from "./BudgetDashboard.module.css";
import Paper from "@material-ui/core/Paper/index";
import _ from "lodash";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CloseIcon from "@material-ui/icons/Close";
import { LocalStorageKeys } from "../../common/constants";
import Row from "./hcp_DetailSummaryTblCategoryRow";
import HcpPlanDetails from "./hcp_PlanDetailsTableForm";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  NavigateBefore,
} from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import TwelveMonthCalendar from "./hcp_TwelveMonthCalendar";
import { setMonth } from "date-fns";
import api from "../../api";
import FullCalendar from "@fullcalendar/react";
import EditplanData from "./hcp_PlanDetailsTableForm";
import EquipmentItemsTbl from "./hcp_EquipmentItemsTbl";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import HcpBudgetPackageCareRow from "../../DoughnutChart/HcpBudgetPackageCareRow";

export const Other_CATEGORY_ID = 7;

function dateToDMYString(date) {
  return moment(date).format("DD/MM/YY");
}
function dateToString(date) {
  return moment(date).format();
}

function getNextYearFromDate(date) {
  const nextYear = new Date(date);
  nextYear.setFullYear(date.getFullYear() + 1);
  return nextYear;
}
const formatMoney = (money) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(money);
};
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
    [theme.breakpoints.down("sm")]: {
      "& td": {
        paddingRight: "2px",
        paddingLeft: "2px",
      },
      "& th": {
        paddingRight: "2px",
        paddingLeft: "2px",
      },
      "& input": {
        fontSize: "0.875rem",
      },
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: 450,
      // "& td": {
      //   fontSize: "0.8rem"
      // },
      // "& th": {
      //   fontSize: "0.8rem"
      // },
      // "& input": {
      //   fontSize: "0.8rem"
      // }
    },
  },
  tableContainer: {
    width: "96%",
    padding: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "99%",
      padding: "2px",
    },
  },
  tablecell: {
    padding: "2px",
  },
}));

export default function DetailSummaryTbl(props) {
  const classes = useStyles();
  var {
    categories,
    allocated,
    budgets,
    hcpPlanCategories,
    plan,
    total,
    hcpSupportGroups,
    // handleEditPlanItemGroups,
    level,
    setCategoryExpanded,
    handleManagement,
    setTableNav,
    monthViewDate,
    handleOpenSupports,
    showMonthView,
    onLevelSave,
    eventhandleItem,
    eventeditable,
    eventinitialDate,
    eventinitial,
    eventonclick,
    showmonthview,
    available,
    hcpSupportItems,
    groupExpanded,
    categoryExpanded,
    onceOffItems,
    onAddItem,
    onSaveEqip,
    onChangeItemField,
    onDeleteEqip,
    handleBudgetSave,
  } = props;
  const [showMonthView1, setShowMonthView1] = useState(showMonthView);
  const [oldcategories, setOldcategories] = useState([]);
  const [fullCategories, setFullCategories] = useState([]);
  const [save, setSave] = useState(false);
  const [serviceCategoryMoney, setServiceCategoryMoney] = useState(0);

  const getCategoryDetails = (name) => {
    return _.find(hcpPlanCategories, (item) => item.name === name);
  };


  useEffect(() => {
    let change = false;
    const cat = [];
    for (const i of oldcategories) {
      cat.push(i[0]);
    }
    for (const i of categories) {
      if (!(i in cat)) {
        change = true;
        break;
      }
    }
    if (change) {
      const element = [];
      for (let index = 0; index < categories.length; index++) {
        element.push([categories[index], budgets[index]]);
      }
      setOldcategories(element);
    }
  }, [categories]);

  useEffect(() => {
    let arr = [];
    let tempServiceCategoryMoney = 0;
    for (const i of oldcategories) {
      if (i[0] !== "Equipment / Once Off Items") {
        arr.push(getCategoryDetails(i[0]));
        if (i[0] !== "Package Management" && i[0] !== "Care Management") {
          tempServiceCategoryMoney += i[1];
        }
      }
    }
    setServiceCategoryMoney(formatMoney(tempServiceCategoryMoney));
    setFullCategories(arr);
  }, [oldcategories]);

  const findBudget = (name) => {
    for (const i of oldcategories) {
      if (i[0] === name) {
        return formatMoney(i[1]);
      }
    }
    return formatMoney(0);
  };
  const [open, setOpen] = React.useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);
  const [equipOpen, setEquipOpen] = useState(false);
  const [changePlanDate, setChangePlanDate] = useState({
    startDate: plan.startDate,
    endDate: plan.endDate,
  });
  const renderHcpRow = () => {
    const handleStartDateChange = (e) => {
      let planDates = { ...changePlanDate };
      planDates.startDate = dateToString(new Date(e));
      planDates.endDate = dateToString(getNextYearFromDate(new Date(e)));
      setChangePlanDate(planDates);
    };
    const handleEndDateChange = (e) => {
      let planDates = { ...changePlanDate };
      let date = new Date(e);
      if (date < new Date(planDates.startDate)) {
        console.log("invalid end date");
        return;
      }
      planDates.endDate = dateToString(date);
      setChangePlanDate(planDates);
    };
    return (
      <>
        <TableRow>
          <TableCell style={{ padding: "7px" }} size="small">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            HCP Level
          </TableCell>
          <TableCell>{level}</TableCell>
          <TableCell>
            {!open ? (
              dateToDMYString(changePlanDate.startDate)
            ) : (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="BudgetStart"
                  value={changePlanDate.startDate}
                  onChange={handleStartDateChange}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  id="startDate"
                  name="startDate"
                  format="DD/MM/YY"
                />
              </MuiPickersUtilsProvider>
            )}
          </TableCell>
          <TableCell>
            {!open ? (
              dateToDMYString(changePlanDate.endDate)
            ) : (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="BudgetEnd"
                  value={changePlanDate.endDate}
                  onChange={handleEndDateChange}
                  leftArrowIcon={<ChevronLeft />}
                  rightArrowIcon={<ChevronRight />}
                  id="startDate"
                  name="startDate"
                  format="DD/MM/YY"
                />
              </MuiPickersUtilsProvider>
            )}
          </TableCell>
          <TableCell></TableCell>
          <TableCell align="right">
            {formatMoney(Number(total).toFixed(2))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: "0px" }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <HcpPlanDetails
                plan={props.plan}
                hcpSupportGroups={props.hcpSupportGroups}
                changePlanDate={changePlanDate}
                onLevelSave={() => props.onLevelSave()}
                save={save}
                setSave={setSave}
                available={available}
                handleBudgetSave={handleBudgetSave}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };
  const renderEquipRow = () => {
    let item = getCategoryDetails("Equipment / Once Off Items")
      ?.hcpPlanItemGroups[0];
    let hcpPlanItems = item?.hcpPlanItems
      ? new Array(...item?.hcpPlanItems)
      : [];
    hcpPlanItems.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
    return (
      <>
        <TableRow>
          <TableCell style={{ padding: "7px" }} size="small">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setEquipOpen(!equipOpen)}
            >
              {equipOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            Equipment / Once Off Items
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right">{findBudget(item?.name)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={equipOpen} timeout="auto" unmountOnExit>
              <Table>
                <colgroup>
                  <col width="2%" />
                  <col width="96%" />
                  <col width="2%" />
                </colgroup>
                <TableBody>
                  <TableCell></TableCell>
                  <TableCell>
                    <EquipmentItemsTbl
                      onceOffItems={onceOffItems}
                      onAddItem={onAddItem}
                      onSave={onSaveEqip}
                      onChangeItemField={onChangeItemField}
                      onDelete={onDeleteEqip}
                      available={available}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const renderPackageCareRow = () => {
    return (
      <>
        {_.map(hcpSupportGroups, (hcpSupportGroup, index) => {
          let supportCategories = {};
          _.map(hcpSupportGroup.hcpSupportCategories, (hcpSC) => {
            supportCategories[hcpSC.id] = 1;
          });
          return (
            <>
              {index !== 0 ? (
                hcpSupportGroup.id < 3 ? (
                  <>
                    {_.map(hcpPlanCategories, (hcpPlanCategory, PCId) => {
                      if (
                        supportCategories[
                          hcpPlanCategory.hcpSupportCategory
                        ] !== undefined
                      ) {
                        if (
                          hcpPlanCategory.hcpSupportCategory !==
                            Other_CATEGORY_ID &&
                          hcpSupportGroup.id < 3
                        ) {
                          let hcpSupportCategory = props.findhcpSupportCategoryById(
                            hcpPlanCategory.hcpSupportCategory
                          );
                          return (
                            <HcpBudgetPackageCareRow
                              key={PCId}
                              {...{
                                supportcategory: hcpSupportCategory,
                                plancategory: hcpPlanCategory,
                                totalColor: LIGHT_BLUE,
                                isEditable: false,
                                allocatedColor: DARK_BLUE,
                                supportitems: hcpSupportItems,
                                hcpRegistrationGroup: null,
                                expanded: groupExpanded,
                                eid: hcpSupportCategory.id,
                              }}
                              openSupports={() =>
                                props.handleOpenSupports(hcpPlanCategory.id)
                              }
                              deleteCategory={props.handleDeletePlanCategory}
                              deleteItemGroup={props.handleDeleteItemGroup}
                              handleAddSubCategory={props.handleAddSubCategory}
                              handleExpanded={props.handleGroupExpand}
                              saveCategoryName={props.handleCategoryNameSave}
                              saveItemGroupName={props.handleItemGroupNameSave}
                              handleadd={props.handleAdd}
                              handleManagement={props.handleManagement}
                              handleCategoryExpand={props.handleCategoryExpand}
                              categoryExpanded={props.categoryExpanded}
                              findBudget={findBudget}
                              planDates={plan}
                              handleOpenSupports={handleOpenSupports}
                              setTableNav={setTableNav}
                              getCategoryDetails={getCategoryDetails}
                            />
                          );
                        }
                      }
                    })}
                  </>
                ) : null
              ) : null}
            </>
          );
        })}
      </>
    );
  };
  const renderServiceRow = () => {
    return (
      <>
        <TableRow>
          <TableCell style={{ padding: "7px" }} size="small">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setServiceOpen(!serviceOpen)}
            >
              {serviceOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            Service Category
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="right">{serviceCategoryMoney}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={serviceOpen} timeout="auto" unmountOnExit>
              <Table>
                <colgroup>
                  <col width="2%" />
                  <col width="96%" />
                  <col width="2%" />
                </colgroup>
                <TableBody>
                  {/* {fullCategories.map((item, index) => {
                    return item && item.hcpSupportCategory >= 3 ? (
                      <Row
                        key={index}
                        item={item}
                        findBudget={findBudget}
                        planDates={plan}
                        // handleEditPlanItemGroups={handleEditPlanItemGroups}
                        // save={save}
                        // setSave={setSave}
                        handleManagement={handleManagement}
                        handleOpenSupports={handleOpenSupports}
                        setTableNav={setTableNav}
                      />
                    ) : null;
                  })} */}
                  <TableCell></TableCell>
                  <TableCell>
                    {_.map(hcpSupportGroups, (hcpSupportGroup, index) => {
                      let supportCategories = {};
                      _.map(hcpSupportGroup.hcpSupportCategories, (hcpSC) => {
                        supportCategories[hcpSC.id] = 1;
                      });
                      return (
                        <>
                          {index !== 0 ? (
                            hcpSupportGroup.id === 3 ? (
                              <>
                                {_.map(
                                  hcpPlanCategories,
                                  (hcpPlanCategory, PCId) => {
                                    if (
                                      supportCategories[
                                        hcpPlanCategory.hcpSupportCategory
                                      ] !== undefined
                                    ) {
                                      let hcpSupportCategory = props.findhcpSupportCategoryById(
                                        hcpPlanCategory.hcpSupportCategory
                                      );
                                      return (
                                        <HcpBudgetCategoryRow
                                          key={PCId}
                                          {...{
                                            supportcategory: hcpSupportCategory,
                                            plancategory: hcpPlanCategory,
                                            totalColor: LIGHT_BLUE,
                                            isEditable: false,
                                            allocatedColor: DARK_BLUE,
                                            supportitems: hcpSupportItems,
                                            hcpRegistrationGroup: null,
                                            expanded: groupExpanded,
                                            eid: hcpSupportCategory.id,
                                          }}
                                          openSupports={() =>
                                            props.handleOpenSupports(
                                              hcpPlanCategory.id
                                            )
                                          }
                                          deleteCategory={
                                            props.handleDeletePlanCategory
                                          }
                                          deleteItemGroup={
                                            props.handleDeleteItemGroup
                                          }
                                          handleAddSubCategory={
                                            props.handleAddSubCategory
                                          }
                                          handleExpanded={
                                            props.handleGroupExpand
                                          }
                                          saveCategoryName={
                                            props.handleCategoryNameSave
                                          }
                                          saveItemGroupName={
                                            props.handleItemGroupNameSave
                                          }
                                          handleadd={props.handleAdd}
                                          handleManagement={
                                            props.handleManagement
                                          }
                                          findBudget={findBudget}
                                          planDates={plan}
                                          handleOpenSupports={
                                            handleOpenSupports
                                          }
                                          setTableNav={setTableNav}
                                          getCategoryDetails={
                                            getCategoryDetails
                                          }
                                        />
                                      );
                                    }
                                  }
                                )}{" "}
                              </>
                            ) : null
                          ) : null}
                        </>
                      );
                    })}
                  </TableCell>
                  <TableCell></TableCell>
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const rendercalendar = () => {
    return (
      <>
        {showMonthView1 === true && (
          <Dialog
            open={showMonthView1 === true}
            onClose={() => setShowMonthView1(false)}
          >
            <DialogTitle classes={{ root: styles.dialogTitle }}>
              <IconButton
                aria-label="close"
                className={styles.closeButton}
                onClick={() => setShowMonthView1(false)}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                eventDrop={eventhandleItem}
                editable={eventeditable}
                dayMaxEventRows={2}
                initialDate={eventinitialDate}
                fixedWeekCount={false}
                height={"auto"}
                initialEvents={eventinitial}
                eventClick={eventonclick}
              />
            </DialogContent>
          </Dialog>
        )}
        <TwelveMonthCalendar
          hcpSupportGroups={hcpSupportGroups}
          hcpPlanCategories={hcpPlanCategories}
          onClick={showmonthview}
          startDate={plan.startDate}
        />
      </>
    );
  };

  return (
    <>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table}>
          <colgroup>
            <col width="5%" />
            <col width="27%" />
            <col width="20%" />
            <col width="14%" />
            <col width="14%" />
            <col width="5%" />
            <col width="15%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell></TableCell>
              <TableCell align="right">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderHcpRow()}
            {/* {fullCategories.map((item, index) => {
              return item && item.hcpSupportCategory < 3 ? (
                <Row
                  key={index}
                  item={item}
                  findBudget={findBudget}
                  planDates={plan}
                  handleManagement={handleManagement}
                  handleOpenSupports={handleOpenSupports}
                  setTableNav={setTableNav}
                />
              ) : null;
            })} */}
            {renderPackageCareRow()}
            {renderServiceRow()}
            {renderEquipRow()}
            {rendercalendar()}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="flex-end" style={{ marginTop: "10px" }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            setSave(true);
          }}
        >
          Save
        </Button>
      </Grid>
    </>
  );
}
