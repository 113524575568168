import { useEffect, useRef } from "react";

//  Custom Hook to store previous value of a state variable.
export function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
