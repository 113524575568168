import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Fab from "@material-ui/core/Fab";
import api from "../../api";
import { DialogContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import _ from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { DARK_BLUE, LIGHT_BLUE } from "../../common/theme";
import PlanItemGroupCalendarView from "./hcp_PlanItemGroupCalendarView";
import PlanAddEditor from "./hcp_PlanAddEditor";
import ManageEditor from "./hcp_ManageEditor";
import classNames from "classnames";
import { calculateTotalCost } from "./hcp_BudgetDashboard";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import PlanItemEditView from "./hcp_PlanItemEditView";
import { getHours, getMinutes, setHours, setMinutes } from "date-fns";
import moment from "moment";
import { LocalStorageKeys } from "../../common/constants";

export const PLAN_ITEM_GROUPS_VIEW = 0;
export const SUPPORTS_SELECTION = 1;
export const PLAN_ITEM_GROUP_CALENDAR_VIEW = 2;
export const SUPPORT_ITEMS_VIEW = 3;
export const REGISTRATION_GROUPS_VIEW = 4;
export const PLAN_ITEM_EDIT_VIEW = 5;
export const MANAGEMENT_EDIT = 6;

export const PLAN_ITEM_GROUP_EDIT_ALL = true;

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: DARK_BLUE,
    color: "white",
  },
  dialogContent: {
    minHeight: 400,
  },
  buttonText: {
    textTransform: "none",
  },
  supportButtonLight: {
    backgroundColor: LIGHT_BLUE,
    color: "white",
    width: "100%",
    maxHeight: "72px",
    minHeight: "72px",
  },
  supportButtonDark: {
    backgroundColor: DARK_BLUE,
    color: "white",
    width: "100%",
    maxHeight: "72px",
    minHeight: "72px",
  },
  icon: {
    height: "150",
  },
  list: {
    padding: 8,
  },
  blackButton: {
    backgroundColor: "black",
    color: "white",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  main: {
    width: "auto",
    display: "block",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  planItemText: {
    textAlign: "left",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    position: "relative",
  },
}));

function dateToString(date) {
  return moment(date).format();
}
function dateToYMDString(date) {
  return moment(date).format("YYYY-MM-DD");
}

export default function SupportItemDialog(props) {
  const {
    hcpPlanCategory,
    hcpSupportCategory,
    onEditHcpPlanItemGroups,
    onhandleItemDrag,
    activeCategory,
    page,
    planid,
    planStartDate,
    planEndDate,
    setPage,
    editedItems,
    itemName,
    total,
    available,
    onhandleDeleteItemGroup,
    planName,
    planNumber,
    planHcpCategories,
    tablenav,
    setTableNav,
  } = props;
  useEffect(() => {
    console.log("total:::", total);
    console.log("available:::", available);
  }, [total]);
  const [indexOfItems, setindexOfItems] = useState(0);
  // React Hooks
  // list of all support hcpItems for this group
  const [hcpSupportItems, setHcpSupportItems] = useState([]);
  // id of registration group
  // const [hcpRegistrationGroupId] = useState(null);
  // 0: large screen; 1: small screen (mobile)
  // number representing current page
  // 0: supports list; 1: supports selection; 2: edit support; 3: add support
  // const [page, setPage] = useState(
  //   openAddSupports === 0 ? SUPPORTS_LIST : SUPPORTS_SELECTION
  // );
  // set of support returned from search
  // const [searchResults, setSearchResults] = useState([]);
  // text typed into search bar
  // const [searchText, setSearchText] = useState("");
  // item that is being edited
  // const [editedItemtest, setEditedItem] = useState(0);
  const [selectedHcpPlanItemGroup, setHcpSelectedPlanItemGroup] = useState(0);
  const [editHcpPlanItemGroupOptions, setEditPlanItemGroupOptions] = useState({
    editAll: false,
    hcpPlanItem: null,
  });

  const classes = useStyles();

  useEffect(() => {
    if (tablenav && tablenav.nav) {
      handleEditSupportItem(tablenav.id, tablenav.item);
      setTableNav();
    }
  }, [tablenav]);

  // api call to load support hcpItems
  useEffect(() => {
    // load single category
    console.log("SIDialog try get support item ln 198");
    api.Hcp_SupportItems.get({
      hcpsupportCategoryID: hcpSupportCategory.id,
    }).then((response) => {
      console.log("response.data");
      console.log(response.data);
      const hcpItems = response.data.map((hcpSupportItem) => {
        return {
          ...hcpSupportItem,
          label: hcpSupportItem.name,
        };
      });

      setHcpSupportItems(hcpItems);
    });
  }, [hcpSupportCategory]);

  // allow render PLAN_ITEM_GROUPS_VIEW
  function goToSupportsList() {
    setPage(PLAN_ITEM_GROUPS_VIEW);
  }

  // allow render SUPPORTS_SELECTION
  function goToSupportSelection() {
    setPage(SUPPORTS_SELECTION);
  }

  // allow render PLAN_ITEM_GROUP_CALENDAR_VIEW
  function goToPlanItemGroupCalendarView() {
    setPage(PLAN_ITEM_GROUP_CALENDAR_VIEW);
  }

  function goToManagementEditView() {
    setPage(MANAGEMENT_EDIT);
  }

  function goToAddSupport() {
    setPage(SUPPORT_ITEMS_VIEW);
  }

  // allow render PLAN_ITEM_EDIT_VIEW
  function goToEditPlanItem(editOptions) {
    setEditPlanItemGroupOptions(editOptions);
    setPage(PLAN_ITEM_EDIT_VIEW);
  }

  // close supportdialog
  function handleClose() {
    editedItems.splice(0);
    props.onClose();
  }

  // add a planitemgroup with content after click add new and fill all chosen subcategory
  function handleAddPlanItemGroup(hcpPlanItemGroup) {
    const { hcpPlanItemGroups } = hcpPlanCategory;
    // TODO: handle registered users
    let hcpPlanItemGroupId = 0;
    if (
      localStorage.getItem(LocalStorageKeys.ACCESS) != null &&
      (hcpPlanCategory.hcpPlan !== undefined ||
        hcpPlanCategory.id !== undefined)
    ) {
      let planId = hcpPlanCategory.plan;
      let hcpPlanCategoryId = hcpPlanCategory.id;
      const hcpPlanItemGroupData = {
        hcpPlanCategory: hcpPlanCategory.id,
        hcpSupportItemGroup: hcpPlanItemGroup.hcpSupportItemGroup,
        name: hcpPlanItemGroup.name,
        nickname: hcpPlanItemGroup.nickname,
        scheduleStartDate: hcpPlanItemGroup.scheduleStartDate,
        scheduleEndDate: hcpPlanItemGroup.scheduleEndDate,
      };
      console.log("SIDialog 尝试create  Hcp_PlanItemGroups： ln 290");
      console.log({ planId, hcpPlanCategoryId, hcpPlanItemGroupData });
      console.log(hcpPlanItemGroup.hcpPlanItems);
      api.Hcp_PlanItemGroups.list(planId, hcpPlanCategoryId).then(
        (responsePlanItemGroup) => {
          console.log(responsePlanItemGroup.data);
          let existingSimilarPlans = responsePlanItemGroup.data.filter(
            (item) =>
              item.hcpSupportItemGroup ===
                hcpPlanItemGroupData.hcpSupportItemGroup &&
              item.name === hcpPlanItemGroupData.name
          );
          console.log(existingSimilarPlans, "existing");

          if (existingSimilarPlans.length !== 0) {
            _.map(existingSimilarPlans, (PlanItemGroup) => {
              api.Hcp_PlanItemGroups.delete(
                planId,
                hcpPlanCategoryId,
                PlanItemGroup.id
              ).then((res) => {});
            });
          }
          api.Hcp_PlanItemGroups.create(
            planId,
            hcpPlanCategoryId,
            hcpPlanItemGroupData
          ).then((response) => {
            hcpPlanItemGroupId = response.data.id;
            for (let i = 0; i < hcpPlanItemGroup.hcpPlanItems.length; i++) {
              const hcpPlanItemData = {
                planItemGroup: hcpPlanItemGroupId,
                name: hcpPlanItemGroup.hcpPlanItems[i].name,
                priceActual: hcpPlanItemGroup.hcpPlanItems[i].priceActual,
                startDate: dateToString(
                  hcpPlanItemGroup.hcpPlanItems[i].startDate
                ),
                endDate: dateToString(hcpPlanItemGroup.hcpPlanItems[i].endDate),
                allDay: hcpPlanItemGroup.hcpPlanItems[i].allDay,
                frequency: hcpPlanItemGroup.hcpPlanItems[i].frequency,
                isPercentage:
                  hcpPlanItemGroup.hcpPlanItems[i].isPercentage || false,
              };
              hcpPlanItemGroup["id"] = hcpPlanItemGroupId;
              console.log("SIDialog 尝试create  Hcp_PlanItems： ln 304");
              console.log(hcpPlanItemData);
              api.Hcp_PlanItems.create(
                planId,
                hcpPlanCategoryId,
                hcpPlanItemGroupId,
                hcpPlanItemData
              ).then((response) => {});
            }
            editedItems.splice(0);
            onEditHcpPlanItemGroups(activeCategory, [hcpPlanItemGroup]);
          });
        }
      );
    } else {
      let existingSimilarPlans = hcpPlanItemGroups.filter(
        (item) =>
          item.hcpSupportItemGroup === hcpPlanItemGroup.hcpSupportItemGroup &&
          item.name === hcpPlanItemGroup.name
      );
      if (existingSimilarPlans.length !== 0) {
        _.remove(hcpPlanItemGroups, (item) =>
          _.isEqual(item, existingSimilarPlans[0])
        );
      }
      let index = hcpPlanItemGroups.length + 1;
      let Iindex = 1;
      hcpPlanItemGroup["id"] = "PC_" + activeCategory + "_PIG_" + index;
      _.map(hcpPlanItemGroup.hcpPlanItems, (item) => {
        item["id"] = hcpPlanItemGroup["id"] + "_PI_" + Iindex;
        Iindex = Iindex + 1;
      });
      onEditHcpPlanItemGroups(activeCategory, [
        ...hcpPlanItemGroups,
        hcpPlanItemGroup,
      ]);
    }
  }

  // allow render PLAN_ITEM_GROUP_EDIT_ALL
  function handleEditSupportItem(hcpSupportCategory, hcpPlanItemGroup) {
    console.log(hcpSupportCategory, "hcpSupportCategory", hcpPlanItemGroup);
    setHcpSelectedPlanItemGroup(hcpPlanItemGroup);
    setEditPlanItemGroupOptions({
      editAll: PLAN_ITEM_GROUP_EDIT_ALL,
      hcpPlanItem: hcpPlanItemGroup.hcpPlanItems[0],
    });
    if (hcpSupportCategory < 3) goToManagementEditView();
    else goToAddSupport();
  }

  // delete a PlanItemGroup
  function handleDeletePlanItemGroup(hcpPlanItemGroup) {
    goToSupportsList();
    onhandleDeleteItemGroup(hcpPlanCategory.id, hcpPlanItemGroup.id);
  }

  // delete a planitem
  function handleDeletePlanItem(hcpPlanItem) {
    console.log(hcpPlanItem);
    const editedHcpPlanItemGroup = {
      ...selectedHcpPlanItemGroup,
      hcpPlanItems: _.difference(selectedHcpPlanItemGroup.hcpPlanItems, [
        hcpPlanItem,
      ]),
    };
    if (localStorage.getItem(LocalStorageKeys.ACCESS) != null) {
      api.Hcp_PlanItems.delete(
        planid,
        hcpPlanCategory.id,
        hcpPlanItem.planItemGroup,
        hcpPlanItem.id
      );
    }
    onEditHcpPlanItemGroups(
      hcpPlanCategory.id,
      hcpPlanCategory.hcpPlanItemGroups.map((pIG) => {
        if (selectedHcpPlanItemGroup === pIG) {
          return editedHcpPlanItemGroup;
        } else {
          return pIG;
        }
      })
    );
    setHcpSelectedPlanItemGroup(editedHcpPlanItemGroup);
  }

  // edit a planitem
  function handleEditPlanItem(hcpPlanItem) {
    let editedHcpPlanItemGroup = {};
    console.log(editHcpPlanItemGroupOptions.editAll);
    if (editHcpPlanItemGroupOptions.editAll === true) {
      const { nickname, name, priceActual } = hcpPlanItem;
      const startDate = new Date(hcpPlanItem.startDate);
      const endDate = new Date(hcpPlanItem.endDate);
      editedHcpPlanItemGroup = {
        ...selectedHcpPlanItemGroup,
        hcpPlanItems: selectedHcpPlanItemGroup.hcpPlanItems.map((pI) => {
          return {
            ...pI,
            name,
            priceActual,
            startDate: setMinutes(
              setHours(new Date(pI.startDate), getHours(startDate)),
              getMinutes(startDate)
            ),
            endDate: setMinutes(
              setHours(new Date(pI.startDate), getHours(endDate)),
              getMinutes(endDate)
            ),
          };
        }),
        nickname: nickname,
      };
    } else {
      console.log(selectedHcpPlanItemGroup);
      editedHcpPlanItemGroup = {
        ...selectedHcpPlanItemGroup,
        hcpPlanItems: selectedHcpPlanItemGroup.hcpPlanItems.map((pI) => {
          if (pI === editHcpPlanItemGroupOptions.hcpPlanItem) {
            return hcpPlanItem;
          } else {
            return pI;
          }
        }),
      };
    }

    onEditHcpPlanItemGroups(
      hcpPlanCategory.id,
      hcpPlanCategory.hcpPlanItemGroups.map((pIG) => {
        if (selectedHcpPlanItemGroup === pIG) {
          return editedHcpPlanItemGroup;
        } else {
          return pIG;
        }
      })
    );
    setHcpSelectedPlanItemGroup(editedHcpPlanItemGroup);
    setEditPlanItemGroupOptions({
      editAll: !PLAN_ITEM_GROUP_EDIT_ALL,
      hcpPlanItem: null,
    });
    goToPlanItemGroupCalendarView();
  }

  // return previous subcategory when add new
  function handleBack() {
    if (indexOfItems === 0 || page === MANAGEMENT_EDIT) {
      props.onClose();
    } else {
      console.log(
        "indexOfItems: " +
          (indexOfItems - 1) +
          " maxIndex: " +
          editedItems.length
      );
      setindexOfItems(indexOfItems - 1);
    }
  }

  // go to next subcategory when add new
  function handleNext() {
    if (indexOfItems === editedItems.length - 1) {
      console.log("Finish queue");
    } else {
      console.log(
        "indexOfItems: " +
          (indexOfItems + 1) +
          " maxIndex: " +
          editedItems.length
      );
      setindexOfItems(indexOfItems + 1);
    }
  }

  // render a itemgroup when view plan
  function renderPlanItemsByGroup(hcpPlanItemGroup) {
    let hcpSupportItem;
    if (page === PLAN_ITEM_GROUPS_VIEW) {
      hcpSupportItem = _.find(hcpSupportItems, (hcpSupportItem) => {
        return (
          hcpSupportItem.hcpSupportItemGroup ===
          hcpPlanItemGroup.hcpSupportItemGroup
        );
      });
      if (!hcpSupportItem) {
        hcpSupportItem = {
          description: hcpPlanItemGroup.name,
          name: hcpPlanItemGroup.name,
        };
      }
    } else if (page === SUPPORTS_SELECTION) {
      hcpSupportItem = _.find(hcpSupportItems, (hcpSupportItem) => {
        return hcpSupportItem.id === hcpPlanItemGroup.id;
      });
    }
    let hcpPlanItems = hcpPlanItemGroup.hcpPlanItems;
    if (hcpPlanItems && hcpPlanItems.length > 0) {
      return (
        <Grid item>
          <Grid container>
            <Fab
              className={
                page === SUPPORTS_SELECTION
                  ? classes.supportButtonLight
                  : classes.supportButtonDark
              }
              variant="extended"
              onClick={() => {
                if (page === PLAN_ITEM_GROUPS_VIEW) {
                  handleEditSupportItem(
                    hcpSupportCategory.id,
                    hcpPlanItemGroup
                  );
                }
              }}
            >
              <ListItemIcon>
                <Tooltip
                  disableTouchListener
                  title={hcpSupportItem?.description || "No description"}
                >
                  <InfoIcon />
                </Tooltip>
              </ListItemIcon>
              <Grid container alignContent="space-between">
                <Grid item xs={10}>
                  <Typography
                    className={classNames(
                      classes.buttonText,
                      classes.planItemText
                    )}
                  >
                    {hcpPlanItemGroup.name}
                  </Typography>
                </Grid>
                {page === PLAN_ITEM_GROUPS_VIEW && (
                  <Grid item>
                    ${calculateTotalCost(hcpPlanItemGroup).toLocaleString()}
                  </Grid>
                )}
              </Grid>
            </Fab>
          </Grid>
        </Grid>
      );
    }
  }

  // render itemgroups when view plan
  function renderSupportItemList(list) {
    return (
      <Grid container>
        <Grid container item xs={12} md={8} lg={7} alignItems="flex-start">
          {list.length === 0 ? (
            <div> No plan </div>
          ) : (
            list.map((hcpPlanItemGroup, index) => (
              <Grid item key={index} xs={12} className={classes.list}>
                {renderPlanItemsByGroup(hcpPlanItemGroup)}
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    );
  }

  // try render all itemsgroups when viewplan
  function renderPlanContent() {
    return (
      <>
        <DialogContent className={classes.dialogContent}>
          {renderSupportItemList(hcpPlanCategory.hcpPlanItemGroups)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </>
    );
  }

  // if click a itemgroup when viewplan, create a dialogpage
  function renderPlanItemGroupCalendarView() {
    return (
      editHcpPlanItemGroupOptions.hcpPlanItem != null && (
        <Grid container>
          <PlanItemGroupCalendarView
            hcpPlanItemGroup={selectedHcpPlanItemGroup}
            onDeletePlanItemGroup={handleDeletePlanItemGroup}
            onDeletePlanItem={handleDeletePlanItem}
            back={goToSupportsList}
            onEditPlanItem={goToEditPlanItem}
            onhandleItemDrag={onhandleItemDrag}
            hcpPlanItem={editHcpPlanItemGroupOptions.hcpPlanItem}
            onSave={handleEditPlanItem}
          />
        </Grid>
      )
    );
  }

  // render add page for chosen subcategories
  function renderAdditionPage() {
    if (editedItems.length > 0) {
      return (
        indexOfItems >= 0 && (
          <PlanAddEditor
            hcpItems={editedItems}
            IGname={itemName}
            index={indexOfItems}
            maxIndex={editedItems.length - 1}
            startDate={planStartDate}
            endDate={planEndDate}
            redirectSelectionPage={goToSupportSelection}
            redirectSupports={goToSupportsList}
            next={handleNext}
            back={handleBack}
            save={handleAddPlanItemGroup}
            onDeletePlanItemGroup={handleDeletePlanItemGroup}
            available={available}
            // onPlanDatesChange={handlePlanDatesChange}
          />
        )
      );
    } else {
      const planItemNames = selectedHcpPlanItemGroup.name.split(" | ");
      let items = [];
      //eslint-disable-next-line
      planItemNames.map((name) => {
        let filteredArray = hcpSupportItems.filter(
          (item) => item.name === name
        );
        if (filteredArray.length) items.push(filteredArray[0]);
      });
      return (
        <PlanAddEditor
          hcpItems={items}
          selectedHcpPlanItemGroup={selectedHcpPlanItemGroup}
          startDate={planStartDate}
          endDate={planEndDate}
          redirectSelectionPage={goToSupportSelection}
          redirectSupports={goToSupportsList}
          next={handleNext}
          back={handleBack}
          save={handleAddPlanItemGroup}
          onDeletePlanItemGroup={handleDeletePlanItemGroup}
          available={available}
          // onPlanDatesChange={handlePlanDatesChange}
        />
      );
    }
  }

  // when save management edit
  function handleManageSave(hcpPlanItemGroup) {
    console.log("handle save manage");
    console.log(hcpPlanItemGroup);
    console.log(hcpPlanCategory);
    if (
      hcpPlanCategory.hcpPlan !== undefined ||
      hcpPlanCategory.id !== undefined
    ) {
      console.log("test if add");
      let planId = hcpPlanCategory.plan;
      let hcpPlanCategoryId = hcpPlanCategory.id;
      const hcpPlanItemGroupData = {
        hcpPlanCategory: hcpPlanCategory.id,
        hcpSupportItemGroup: hcpPlanItemGroup.hcpSupportItemGroup,
        name: hcpPlanItemGroup.name,
        nickname: hcpPlanItemGroup.nickname,
        scheduleStartDate: hcpPlanItemGroup.scheduleStartDate,
        scheduleEndDate: hcpPlanItemGroup.scheduleEndDate,
      };
      api.Hcp_PlanItemGroups.list(planId, hcpPlanCategoryId).then(
        (responsePlanItemGroup) => {
          console.log(responsePlanItemGroup.data);
          if (responsePlanItemGroup.data.length !== 0) {
            _.map(responsePlanItemGroup.data, (PlanItemGroup) => {
              api.Hcp_PlanItemGroups.delete(
                planId,
                hcpPlanCategoryId,
                PlanItemGroup.id
              );
            });
          }
          console.log("尝试create pIG for management");
          console.log(hcpPlanItemGroupData);
          api.Hcp_PlanItemGroups.create(
            planId,
            hcpPlanCategoryId,
            hcpPlanItemGroupData
          ).then((response) => {
            let hcpPlanItemGroupId = response.data.id;
            for (let i = 0; i < hcpPlanItemGroup.hcpPlanItems.length; i++) {
              const hcpPlanItemData = {
                planItemGroup: hcpPlanItemGroupId,
                name: hcpPlanItemGroup.name,
                priceActual: hcpPlanItemGroup.hcpPlanItems[i].priceActual,
                startDate: dateToString(
                  hcpPlanItemGroup.hcpPlanItems[i].startDate
                ),
                endDate: dateToString(hcpPlanItemGroup.hcpPlanItems[i].endDate),
                allDay: hcpPlanItemGroup.hcpPlanItems[i].allDay,
                frequency: hcpPlanItemGroup.hcpPlanItems[i].frequency,
                isPercentage:
                  hcpPlanItemGroup.hcpPlanItems[i].isPercentage || false,
              };
              console.log("尝试create pI for management");
              console.log(hcpPlanItemData);
              api.Hcp_PlanItems.create(
                planId,
                hcpPlanCategoryId,
                hcpPlanItemGroupId,
                hcpPlanItemData
              ).then((response) => {});
            }
          });
        }
      );

      onEditHcpPlanItemGroups(activeCategory, [hcpPlanItemGroup]);
    }
  }

  // eslint-disable-next-line
  const handlePlanDatesChange = async (planDates) => {
    if (localStorage.getItem(LocalStorageKeys.ACCESS) != null) {
      const reqBody = {
        name: planName,
        hcpNumber: planNumber,
        startDate: dateToYMDString(planDates.start),
        endDate: dateToYMDString(planDates.end),
        hcpPlanCategories: _.values(planHcpCategories),
      };
      await api.Hcp_Plans.update(planid, reqBody).then(() => {});
    } else {
      localStorage.setItem("startDate", planDates.start);
      localStorage.setItem("endDate", planDates.end);
    }
  };
  // render add page for chosen chosen management
  function renderManagePage() {
    return (
      <ManageEditor
        hcpSupportItems={hcpSupportItems}
        manageItemGroup={hcpPlanCategory.hcpPlanItemGroups}
        redirectSelectionPage={goToSupportSelection}
        redirectSupports={goToSupportsList}
        planCategory={hcpPlanCategory}
        categoryId={activeCategory}
        back={handleBack}
        total={total}
        available={available}
        save={handleManageSave}
        startDate={planStartDate}
        endDate={planEndDate}
        onDeletePlanItemGroup={handleDeletePlanItemGroup}
        onDeletePlanItem={handleDeletePlanItem}
        onEditPlanItem={goToEditPlanItem}
        // onPlanDatesChange={handlePlanDatesChange}
      />
    );
  }

  // render a item edit view for shosen item
  function renderPlanItemEditView() {
    return (
      editHcpPlanItemGroupOptions.hcpPlanItem != null && (
        <PlanItemEditView
          back={goToSupportsList}
          hcpPlanItem={editHcpPlanItemGroupOptions.hcpPlanItem}
          onSave={handleEditPlanItem}
        />
      )
    );
  }

  let content;
  if (page === PLAN_ITEM_GROUPS_VIEW) {
    content = renderPlanContent();
  } else if (page === SUPPORTS_SELECTION) {
  } else if (page === PLAN_ITEM_GROUP_CALENDAR_VIEW) {
    content = renderPlanItemGroupCalendarView();
  } else if (page === SUPPORT_ITEMS_VIEW) {
    content = renderAdditionPage();
  } else if (page === PLAN_ITEM_EDIT_VIEW) {
    content = renderPlanItemEditView();
  } else if (page === MANAGEMENT_EDIT) {
    content = renderManagePage();
  } else {
  }

  return (
    <Dialog fullScreen open={props.open} onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle}>
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {hcpSupportCategory.name}
          </Typography>
        </Toolbar>
      </DialogTitle>
      {content}
    </Dialog>
  );
}
