import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import DetailedSummary from "./hcp_DetailedSummary";
import styles from "./BudgetDashboard.module.css";
import CloseIcon from "@material-ui/icons/Close";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
const PrintDetailedSummary = (props) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <Dialog
        fullScreen
        fullWidth={true}
        open={props.showDetailedSummary === true}
        onClose={() => props.setShowDetailSummary(false)}
      >
        <DialogTitle classes={{ root: styles.dialogTitle }}>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={() => props.setShowDetailSummary(false)}
          >
            <CloseIcon />
          </IconButton>
          <Button
            color="primary"
            variant="contained"
            onClick={handlePrint}
            style={{ marginTop: "5px", float: "right" }}
          >
            Print
          </Button>
        </DialogTitle>
        <DialogContent>
          <DetailedSummary {...props} ref={componentRef} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrintDetailedSummary;
