import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Typography,
} from "@material-ui/core";
import ValidatedTextField from "../../common/ValidatedTextField";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Paper from "@material-ui/core/Paper/index";
import DeleteIcon from "@material-ui/icons/Delete";

export default function EquipmentItemsTbl(props) {
  const {
    onceOffItems,
    onAddItem,
    onSave,
    onChangeItemField,
    onDelete,
    available,
  } = props;
  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [oldTotal, setOldTotal] = useState(0);

  const validate = () => {
    let errors = {};
    let newTotal = 0;
    onceOffItems.map((item, index) => {
      let name, value, description;
      if (item.name === "") name = "Name is required";

      if (item.value === "") value = "$ Value is required";
      newTotal += Number(item.value);

      if (item.description === "") description = "Description is required";

      if (name || value || description) {
        errors = {
          ...errors,
          [index]: { name, value, description },
        };
      }
    });
    if (newTotal > available+oldTotal) {
      onceOffItems.forEach((element, index) => {
        let newError = errors[index];
        if(!newError) newError = {}
        errors = {
          ...errors,
          [index] : {...newError, value:"Fund Not Available"}
        }
      });
    } 
    return errors;
  };

  useEffect(() => {
    if (onceOffItems.length) {
      setOldTotal(onceOffItems.reduce((sum, item) => (sum += Number(item.value)), 0));
    }
  }, [onceOffItems])
  
  const save = () => {
    const errors = validate();
    if (Object.values(errors).length) {
      setErrors(errors);
      setShowErrors(true);
    } else {
      setErrors({});
      setShowErrors(false);
      onSave();
    }
  };

  const handleDelete = (itemId) => {
    let newTotal = onceOffItems.reduce((sum, item) => (sum += Number(item.value)), 0);
    let newErrors = errors
    if (newTotal <= available+oldTotal) {
      onceOffItems.forEach((element, index) => {
        let newError = errors[index];
        if(!newError) newError = {}
        delete newErrors[index]?.value
      });
    }
    setErrors(newErrors)
    onDelete(itemId)
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <colgroup>
            <col width="22.5%" />
            <col width="22.5%" />
            <col width="22.5%" />
            <col width="22.5%" />
            <col width="10%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>$ Value</TableCell>
              <TableCell>Description</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {onceOffItems.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>
                    <ValidatedTextField
                      required
                      onChange={(e) => onChangeItemField(e, "name", index)}
                      value={item.name}
                      type="text"
                      inputProps={{ maxLength: 24 }}
                      error={!!(showErrors && errors[index])}
                      errortext={errors[index] ? errors[index].name : ""}
                    />
                  </TableCell>
                  <TableCell>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        value={item.date}
                        onChange={(e) => onChangeItemField(e, "date", index)}
                        leftArrowIcon={<ChevronLeft />}
                        rightArrowIcon={<ChevronRight />}
                        required
                        id="startDate"
                        name="startDate"
                        format="DD/MM/YY"
                        disablePast
                      />
                    </MuiPickersUtilsProvider>
                  </TableCell>
                  <TableCell>
                    <ValidatedTextField
                      required
                      onChange={(e) => onChangeItemField(e, "value", index)}
                      value={item.value}
                      type="number"
                      error={!!(showErrors && errors[index])}
                      errortext={errors[index] ? errors[index].value : ""}
                    />
                  </TableCell>
                  <TableCell>
                    <ValidatedTextField
                      onChange={(e) =>
                        onChangeItemField(e, "description", index)
                      }
                      value={item.description}
                      type="text"
                      inputProps={{ maxLength: 240 }}
                      error={!!(showErrors && errors[index])}
                      errortext={errors[index] ? errors[index].description : ""}
                    />
                  </TableCell>
                  <TableCell size="small">
                    <Button onClick={() => handleDelete(item.id)}>
                      <DeleteIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justify="space-between" style={{ marginTop: "10px" }}>
        <Button onClick={() => onAddItem()}>+ New Item</Button>
        {/* <Typography variant="subtitle1" gutterBottom component="div">
          {`Total: $${onceOffItems
            .reduce((sum, item) => (sum += Number(item.value)), 0)
            .toLocaleString()}`}
        </Typography> */}
        <Button
          color="primary"
          style={{
            maxWidth: "55px",
            maxHeight: "30px",
            minWidth: "55px",
            minHeight: "30px",
          }}
          variant="contained"
          onClick={() => save()}
        >
          Save
        </Button>
      </Grid>
    </>
  );
}
