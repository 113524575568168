import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  isSameMonth,
  addWeeks,
  addYears,
  addDays,
  differenceInMinutes,
  addMinutes,
  isSameDay,
  isSameWeek,
} from "date-fns";
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Collapse,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { calculateTotalCost } from "./hcp_BudgetDashboard";
import { LocalStorageKeys } from "../../common/constants";
import api from "../../api";

export const DAY_UNITS = ["H", "D", "EA"];

export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const FORTNIGHTLY = "FORTNIGHTLY";
export const MONTHLY = "MONTHLY";
export const YEARLY = "YEARLY";

function dateToDMYString(date) {
  return moment(date).format("DD/MM/YY");
}
function dateToString(date) {
  return moment(date).format();
}
const formatMoney = (money) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(money);
};

function ItemRow(props) {
  //item -> hcpPlanItemGroup
  const {
    item,
    hcpPlanCategory,
    // save,
    // setSave,
    // handleEditPlanItemGroups,
    handleManagement,
    handleOpenSupports,
    setTableNav,
  } = props;
  const activeCategory = hcpPlanCategory.id;
  let frequency = item.hcpPlanItems ? item.hcpPlanItems[0].frequency : "";
  const planDates = {
    start: new Date(item.scheduleStartDate),
    end: new Date(item.scheduleEndDate),
  };

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell
        component="th"
        scope="row"
        style={{ textOverflow: "ellipsis" }}
      >
        {item.name}
      </TableCell>
      <TableCell style={{ textOverflow: "ellipsis" }}>{frequency}</TableCell>
      <TableCell>{dateToDMYString(item.hcpPlanItems[0].startDate)}</TableCell>
      <TableCell>{dateToDMYString(planDates.end)}</TableCell>
      <TableCell>
        <EditIcon
          fontSize="small"
          style={{ cursor: "pointer" }}
          color="secondary"
          onClick={(event) => {
            activeCategory < 2
              ? handleManagement(activeCategory)
              : handleOpenSupports(activeCategory);

            setTimeout(
              () =>
                setTableNav({
                  id: hcpPlanCategory.hcpSupportCategory,
                  item: item,
                }),
              300
            );
            // setIsEditable(!isEditable)
            event.stopPropagation();
          }}
        />
      </TableCell>
      <TableCell align="right">
        {formatMoney(calculateTotalCost(item))}
      </TableCell>
    </TableRow>
  );
}

export default function Row(props) {
  const {
    item,
    findBudget,
    // save,
    // setSave,
    // handleEditPlanItemGroups,
    planDates,
    handleManagement,
    handleOpenSupports,
    setTableNav,
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ padding: "7px" }} size="small">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {item.name}
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell align="right">{findBudget(item.name)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <colgroup>
                <col width="5%" />
                <col width="27%" />
                <col width="20%" />
                <col width="14%" />
                <col width="14%" />
                <col width="5%" />
                <col width="15%" />
              </colgroup>
              <TableBody>
                {item.hcpPlanItemGroups.map((i, index) => {
                  return (
                    <ItemRow
                      key={index}
                      item={i}
                      hcpPlanCategory={item}
                      planDates={planDates}
                      handleManagement={handleManagement}
                      handleOpenSupports={handleOpenSupports}
                      setTableNav={setTableNav}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
